import { ChangeDetectorRef, Component, Input, OnChanges, SimpleChanges, ViewChild } from "@angular/core";
import { cloneDeep } from "lodash";
import { DxTreeListComponent } from "devextreme-angular";
import { ProductInventoryCommandModel } from "src/app/core/models/product-model/product-base-model/product-inventory";
import { SpecialServiceInventoryViewModel } from "src/app/core/models/product-model/specialservice-model/specialservice-inventory-view.model";
import { VehicleConfigurationInventoryModel } from "src/app/core/models/vehicle-model/vehicle-configuration/vehicle-configuration-inventory";
import { SpecialServiceService } from "src/app/core/services/product-services";
import { LoadingSpinnerService } from "src/app/shared/layout/loading-spinner";
import { FocusingDirective } from "src/app/shared/ui/forms/inputs/focusing.directive";
import { ServiceRequestInventoryMapperService } from "./servicerequest-inventory-mapper.service";
import { ServiceRequestInventoryView } from "./servicerequest-inventory.view";

@Component({
    selector: 'op-vehicle-configuration-servicerequest-inventory',
    templateUrl: './servicerequest-inventory.component.html',
    providers: [ServiceRequestInventoryMapperService]
})
export class VehicleConfigurationServiceRequestInventoryComponent implements OnChanges {

    private readonly ATTRIBUTECHOICECODE_YES = "YES";
    private readonly rootLevel = 0;
    private readonly groupLevel = 1;
    @Input() id: string;
    @Input() copy: boolean = false;
    @Input() serviceRequestProductIdSelected: string[] = new Array();
    @Input() vehicleConfigurationInventories: VehicleConfigurationInventoryModel[];
    @ViewChild(FocusingDirective) focusingDirective: FocusingDirective;
    @ViewChild(DxTreeListComponent) treeList: DxTreeListComponent;
    focusing: boolean = false;
    srInventoryViews: ServiceRequestInventoryView[] = new Array();
    srNewInventoryViews: ServiceRequestInventoryView[] = new Array();
    invProductId: string[] = new Array();
    selectedItem: ServiceRequestInventoryView;
    private copyItem: ServiceRequestInventoryView;

    constructor(private serviceRequestInventoryMapperService: ServiceRequestInventoryMapperService,
        private specialServiceService: SpecialServiceService,
        private spinnerService: LoadingSpinnerService,
        private changeDetectorRef: ChangeDetectorRef,) {
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes['serviceRequestProductIdSelected']) {
            this.getNewProductInventory();
        }

        if (changes['vehicleConfigurationInventories']) {
            this.displayExistProductInventory();
        }
    }

    private getNewProductInventory() {
        this.removeProductUnSelected();
        let productId = this.findNewProductSelected();
        if (productId) {
            this.spinnerService.show();
            this.specialServiceService.getInventory(productId)
                .subscribe(
                    (responses: SpecialServiceInventoryViewModel[]) => {
                        this.srInventoryViews = this.serviceRequestInventoryMapperService.newInventoryToViews(responses, this.srInventoryViews, productId);
                        this.srNewInventoryViews = this.serviceRequestInventoryMapperService.newInventoryToViews(responses, this.srNewInventoryViews, productId);
                        this.saveProductSelectedToInventory();
                        this.expandAll();
                        this.changeDetectorRef.detectChanges();
                        this.spinnerService.hide();
                    }
                )
        }
    }

    private displayExistProductInventory() {
        if (this.vehicleConfigurationInventories) {
            this.srInventoryViews = this.serviceRequestInventoryMapperService.existInventoryToViews(this.vehicleConfigurationInventories);
            if (this.copy) {
                this.srNewInventoryViews = this.serviceRequestInventoryMapperService.existInventoryToViews(this.vehicleConfigurationInventories);
            }
            this.saveProductExistToInventory();
            this.expandAll();
            this.changeDetectorRef.detectChanges();
        }
    }


    private expandAll() {
        for (let key of this.srInventoryViews) {
            if (!this.treeList.instance.isRowExpanded(key.productId)) {
                this.treeList.instance.expandRow(key.productId);
            }
        }
    }

    private saveProductSelectedToInventory() {
        this.invProductId = this.serviceRequestProductIdSelected;
    }

    private saveProductExistToInventory() {
        this.invProductId = this.serviceRequestInventoryMapperService.getRootProductId(this.vehicleConfigurationInventories);
    }

    private findNewProductSelected(): string {
        if (this.serviceRequestProductIdSelected) {
            for (let productId of this.serviceRequestProductIdSelected) {
                let filter = this.invProductId.filter(x => x == productId);
                if (!filter?.length) {
                    return productId;
                }
            }
        }
        return null;
    }

    private removeProductUnSelected() {
        if (!this.serviceRequestProductIdSelected?.length) {
            this.srInventoryViews = [];
            this.srNewInventoryViews = [];
            this.invProductId = [];
        } else {
            this.removeProductFromInventory();
        }
    }

    private removeProductFromInventory() {
        let srInventoryNewViews: ServiceRequestInventoryView[] = new Array();
        let srNewInventoryNewViews: ServiceRequestInventoryView[] = new Array();
        let invProductIdNew: string[] = new Array();
        for (let inv of this.serviceRequestProductIdSelected) {
            for (let invView of this.srInventoryViews.filter(x => x.rootProductId == inv)) {
                srInventoryNewViews.push(invView);
                if(!invView.parentProductId){
                    invProductIdNew.push(invView.productId);
                }
            }

            for (let newInvView of this.srNewInventoryViews.filter(x => x.rootProductId == inv)) {
                srNewInventoryNewViews.push(newInvView);
            }
        }
        this.srInventoryViews = srInventoryNewViews;
        this.srNewInventoryViews = srNewInventoryNewViews;
        this.invProductId = invProductIdNew;
    }

    getValue(): ProductInventoryCommandModel[] {
        return this.serviceRequestInventoryMapperService.inventoryViewToModels(this.srInventoryViews);
    }

    onRowSelected(event) {
        this.selectedItem = event.data;
    }

    copyClick() {
        if (this.checkEditable(this.selectedItem) && this.selectedItem) {
            this.copyItem = this.selectedItem;
        }
    }

    pasteClick() {
        if (this.copyItem && this.checkEditable(this.selectedItem)) {
            this.selectedItem.requestCapacity = this.copyItem.requestCapacity
            this.selectedItem.sellCapacity = this.copyItem.sellCapacity;
        }
    }

    deleteClick() {
        if (this.selectedItem && this.checkEditable(this.selectedItem)) {
            this.selectedItem.requestCapacity = 0;
            this.selectedItem.sellCapacity = 0;
        }
    }

    refreshClick() {
        this.srInventoryViews = [];
        if (this.vehicleConfigurationInventories) {
            this.srInventoryViews = this.serviceRequestInventoryMapperService.existInventoryToViews(this.vehicleConfigurationInventories);
        }
        let newInvs = cloneDeep(this.srNewInventoryViews);
        this.srInventoryViews = this.serviceRequestInventoryMapperService.combineInventoryToViews(this.srInventoryViews, newInvs);
    }

    private checkEditable(item: ServiceRequestInventoryView) : boolean {
        if ((!item.parentProductId || this.isGroupLevel(item.productId)) && !this.isInvGroupControl(item)) {
            return false;
        }
        return true;
    }

    private isGroupLevel(productId : string) : boolean {
        let invRootLevels = this.srInventoryViews.filter(x => !x.parentProductId);
        let product = this.srInventoryViews.find(x => x.productId == productId);
        if (!product.parentProductId) {
            return false;
        }
        let filters = invRootLevels.filter(x => x.productId == product.parentProductId);
        if (filters.length) {
            return true;
        }
        return false;
    }

    private isInvGroupControl(item: ServiceRequestInventoryView) : boolean {
        return item.inventoryGroupControl == this.ATTRIBUTECHOICECODE_YES;
    }

    public onRowPrepared(e) {
        if (e.rowType != 'header') {         
            if (!this.isInvGroupControl(e.data)  && (e.level == this.rootLevel || e.level == this.groupLevel)) {
                e.cells[1].cellElement?.addClass("dx-cell-disabled");
                e.cells[2].cellElement?.addClass("dx-cell-disabled");
            } else {
                e.cells[1].cellElement?.addClass("dx-cell-editable");
                e.cells[2].cellElement?.addClass("dx-cell-editable");
            }
        } 
    }

    public onEditingStart(e) {
        if (!this.checkEditable(e.data)) {
            e.cancel = true;
        }
    }
}