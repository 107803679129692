import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";

import { MediaView } from "./media.view";
import {
    MediaCommandModel,
    MediaViewModel,
    MediaSearchCommandModel,
    MediaSearchViewModel
} from 'src/app/core/models/media-model';
import { MediaContentCommandModel, MediaContentViewModel } from 'src/app/core/models/media-model/media-content';
import { MediaFileAssociationCommandModel } from 'src/app/core/models/media-model/media-fileassociation';
import {
    MediaMediaHashTagCommandModel,
    MediaMediaHashTagViewModel
} from 'src/app/core/models/media-model/media-media-hashtag';
import { MediaUseCommandModel } from 'src/app/core/models/media-model/media-use';

import { Select2Data } from "src/app/shared/ui/forms/inputs/oops-select2";
import { DateConverterService } from "src/app/core/utils/date-converter.service";

import {
    LanguageReferenceModel,
    MediaUseReferenceModel,
    MediaTypeReferenceModel,
    MediaTypeFileTypeModel,
    MediaHashTagModel
} from "src/app/core/models/reference-model/reference-media-model";
import { MediaFileAssociationInMediaViewModel } from "src/app/core/models/media-model/media-fileassociation/media-fileassociation-in-media-view.model";
import { MediaFilePreviewService } from "src/app/core/services/media-services/media-file-preview-service";
import { MediaLibraryView } from "./media-library.view";
import { MediaLibraryCommandModel } from "src/app/core/models/media-model/media-library-command.model";

@Injectable()
export class MediaMapperService {

    constructor(private dateConverterService: DateConverterService,
        private mediaFilePreviewService: MediaFilePreviewService) {
    }

    public mediaToMediaModels(mediaModels: MediaCommandModel[], medias: MediaView[], entityName: string, associationId: string): MediaCommandModel[] {
        if (medias) {
            if (!mediaModels) {
                mediaModels = [];
            }
            for (let media of medias) {
                mediaModels.push(this.mediaToMediaModel(media, entityName, associationId));
            }
        }
        return mediaModels;
    }

    private mediaToMediaModel(media: MediaView, entityName: string, associationId: string): MediaCommandModel {
        let model = new MediaCommandModel();
        if (media.mediaId) {
            model.mediaId = media.mediaId;
        }
        model.mediaTypeCode = media.mediaTypeCode;
        model.mediaName = media.mediaName;
        model.validFromDateTime = media.validFrom;
        model.validToDateTime = media.validTo;
        model.mediaContents = this.mediaToMediaContentModels(media);
        model.mediaFileAssociation = this.mediaToMediaFileAssociationModel(media, entityName, associationId);
        model.mediaMediaHashTags = this.mediaToMediaMediaHashTagModels(media);
        model.mediaUses = this.mediaToMediaUseModels(media);
        if (media.mediaTranslationId) {
            model.mediaTranslationId = media.mediaTranslationId;
        }
        return model;
    }

    private mediaToMediaContentModels(media: MediaView): MediaContentCommandModel[] {
        let models: MediaContentCommandModel[] = new Array();
        if (media) {
            let model = new MediaContentCommandModel();
            if (media.mediaContentId) {
                model.mediaContentId = media.mediaContentId;
            }
            model.languageCode = media.languageCode;
            model.mediaFileBase64 = media.mediaFileBase64;
            model.primaryLanguageFlag = true;
            models.push(model);
        }
        return models;
    }

    private mediaToMediaFileAssociationModel(media: MediaView, entityName: string, associationId: string): MediaFileAssociationCommandModel {
        let model = new MediaFileAssociationCommandModel();
        if (media) {
            if (media.mediaFileAssociationId) {
                model.mediaFileAssociationId = media.mediaFileAssociationId;
            }
            model.entityName = entityName;
            if (associationId) {
                model.associationId = associationId;
            }
            model.mediaUseCode = media.mediaUseCode;
            model.mediaId = media.mediaId;
        }
        return model;
    }

    private mediaToMediaMediaHashTagModels(media: MediaView): MediaMediaHashTagCommandModel[] {
        let models: MediaMediaHashTagCommandModel[] = new Array();
        if (media.mediaHashTagIds) {
            let i = 0;
            for (let mediaHashTagId of media.mediaHashTagIds) {
                let model = new MediaMediaHashTagCommandModel();
                i++;
                model.mediaMediaHashTagId = this.findMediaMediaHashTagId(media, mediaHashTagId);
                model.mediaHashtagId = mediaHashTagId;
                model.displaySequence = i;
                models.push(model);
            }
        }
        return models;
    }

    private findMediaMediaHashTagId(media: MediaView, mediaHashTagId: string): string {
        if (media.mediaMediaHashTagIds) {
            var filter = media.mediaMediaHashTagIds.filter(x => x.id == mediaHashTagId);
            if (filter.length > 0) {
                return filter[0].text;
            }
        }
    }

    private mediaToMediaUseModels(media: MediaView): MediaUseCommandModel[] {
        let models: MediaUseCommandModel[] = new Array();
        if (media) {
            let model = new MediaUseCommandModel();
            if (media.mediaMediaUseId) {
                model.mediaMediaUseId = media.mediaMediaUseId;
            }
            model.mediaUseCode = media.mediaUseCode;
            models.push(model);
        }
        return models;
    }

    public mediaToTextViews(models: MediaViewModel[]): MediaView[] {
        let views: MediaView[] = new Array();
        if (models) {
            let i = 0;
            for (let model of models) {
                var modelContents = this.findMediaContentPrimaryLanguage(model);
                if (!modelContents?.mediaText) {
                    ++i;
                    views.push(this.mediaToTextView(i, model));
                }
            }
        }
        return views;
    }

    private mediaToTextView(i: number, model: MediaViewModel): MediaView {
        let view = new MediaView();
        view.no = i;
        view.mediaId = model.mediaId;
        view.mediaName = model.mediaName;
        this.assignValidFromDate(model.validFromDateTime, view);
        this.assignValidToDate(model.validToDateTime, view);
        view.mediaHashTagIds = this.mediaMediaHashTagToView(model.mediaMediaHashTags);
        view.mediaMediaHashTagIds = this.getMediaMediaHashTagId(model.mediaMediaHashTags);
        view.mediaHashTagName = this.getMediaHashTagName(model.mediaMediaHashTags);
        view.mediaTypeCode = model.mediaTypeCode;
        view.mediaTypeName = model.mediaTypeName;
        view.mediaMediaUseId = model.mediaUses[0]?.mediaMediaUseId;
        this.assignToMediaContent(model, view);
        this.assignToMediaFileAssociation(model, view);
        return view;
    }

    private assignValidFromDate(validFromDateTime: Date, view: MediaView) {
        if (validFromDateTime) {
            view.validFrom = this.dateConverterService.toDateFormat(validFromDateTime);
        }
    }

    private assignValidToDate(validToDateTime: Date, view: MediaView) {
        if (validToDateTime) {
            view.validTo = this.dateConverterService.toDateFormat(validToDateTime);
        }
    }

    private assignToMediaContent(model: MediaViewModel, view: MediaView) {
        var mediaContentPrimary = this.findMediaContentPrimaryLanguage(model);
        if (mediaContentPrimary) {
            view.languageCode = mediaContentPrimary.languageCode;
            view.languageName = mediaContentPrimary.languageName;
            view.mediaContentId = mediaContentPrimary.mediaContentId;
        }
    }

    private assignToMediaFileAssociation(model: MediaViewModel, view: MediaView) {
        if (model.mediaFileAssociations) {
            view.mediaUseCode = model.mediaFileAssociations[0].mediaUseCode;
            view.mediaUseName = model.mediaFileAssociations[0].mediaUseName;
            view.mediaFileAssociationId = model.mediaFileAssociations[0].mediaFileAssociationId;
        }
    }

    private mediaMediaHashTagToView(mediaMediaHashTags: MediaMediaHashTagViewModel[]): string[] {
        let mediaMediaHashTagIds: string[] = new Array();
        if (mediaMediaHashTags) {
            for (let mediaMediaHashTag of mediaMediaHashTags) {
                mediaMediaHashTagIds.push(mediaMediaHashTag.mediaHashtagId);
            }
        }
        return mediaMediaHashTagIds;
    }

    private getMediaMediaHashTagId(mediaMediaHashTags: MediaMediaHashTagViewModel[]): Select2Data[] {
        let mediaMediaHashTagIds: Select2Data[] = new Array();
        if (mediaMediaHashTags) {
            for (let mediaMediaHashTag of mediaMediaHashTags) {
                let mediaMediaHashTagId = new Select2Data();
                mediaMediaHashTagId.id = mediaMediaHashTag.mediaHashtagId;
                mediaMediaHashTagId.text = mediaMediaHashTag.mediaMediaHashTagId;
                mediaMediaHashTagIds.push(mediaMediaHashTagId);
            }
        }
        return mediaMediaHashTagIds;
    }

    private getMediaHashTagName(mediaMediaHashTags: MediaMediaHashTagViewModel[]): string {
        let name: string = "";
        if (mediaMediaHashTags) {
            for (let mediaMediaHashTag of mediaMediaHashTags) {
                if (name != "") {
                    name += "," + mediaMediaHashTag.mediaHashtagName;
                }
                else {
                    name += mediaMediaHashTag.mediaHashtagName;
                }
            }
        }
        return name;
    }

    public mediaFileToSearchCommand(model: MediaView): MediaSearchCommandModel {
        let mediaSearchCommandModel = new MediaSearchCommandModel();
        if (model) {
            mediaSearchCommandModel.mediaName = model.mediaName;
            mediaSearchCommandModel.mediaTypeCode = model.mediaTypeCode;
            mediaSearchCommandModel.mediaUseCode = model.mediaUseCode;
            mediaSearchCommandModel.languageCode = model.languageCode;
            mediaSearchCommandModel.mediaHashtagIds = model.mediaHashTagIds;
            mediaSearchCommandModel.isMediaText = false;
        }
        return mediaSearchCommandModel;
    }

    public getHashTagName(mediaHashTagIds: string[], mediaHashTagSelect2Data: Select2Data[]): string {
        var hashTagName: string = "";
        if (mediaHashTagIds && mediaHashTagSelect2Data) {
            for (let mediaMediaHashTag of mediaHashTagIds) {
                var filter = mediaHashTagSelect2Data.filter(x => x.id == mediaMediaHashTag);
                if (filter.length > 0) {
                    if (hashTagName != "") {
                        hashTagName += "," + filter[0].text;
                    }
                    else {
                        hashTagName += filter[0].text;
                    }
                }
            }
        }
        return hashTagName;
    }

    public getMediaUseName(mediaUseCode: string, mediaUseReference$: BehaviorSubject<MediaUseReferenceModel[]>): string {
        if (mediaUseCode && mediaUseReference$.value) {
            var filter = mediaUseReference$.value.filter(x => x.mediaUseCode == mediaUseCode);
            if (filter.length > 0) {
                return filter[0].mediaUseName;
            }
        }
    }

    public getMediaTypeName(mediaTypeCode: string, mediaTypeReference$: BehaviorSubject<MediaTypeReferenceModel[]>): string {
        if (mediaTypeCode && mediaTypeReference$.value) {
            var filter = mediaTypeReference$.value.filter(x => x.mediaTypeCode == mediaTypeCode);
            if (filter.length > 0) {
                return filter[0].mediaTypeName;
            }
        }
    }

    public getLanguageName(languageCode: string, languageReference$: BehaviorSubject<LanguageReferenceModel[]>): string {
        if (languageCode && languageReference$.value) {
            var filter = languageReference$.value.filter(x => x.languageCode == languageCode);
            if (filter.length > 0) {
                return filter[0].languageName;
            }
        }
    }

    public mediaFileLibraryToMediaTextViews(mediaLibraryViews: MediaLibraryView[]): MediaView[] {
        var models: MediaView[] = new Array();
        if (mediaLibraryViews) {
            for (let mediaLibraryView of mediaLibraryViews) {
                models.push(this.mediaFileLibraryToMediaTextView(mediaLibraryView));
            }
        }
        return models;
    }

    private mediaFileLibraryToMediaTextView(model: MediaLibraryView): MediaView {
        let view = new MediaView();
        view.no = 0;
        view.mediaId = model.mediaId;
        view.mediaName = model.mediaName;
        if (model.validFrom) {
            view.validFrom = model.validFrom;
        }
        if (model.validTo) {
            view.validTo = model.validTo;
        }
        view.mediaContentId = model.mediaContentId;
        view.mediaMediaHashTagIds = model.mediaMediaHashTagIds;
        view.mediaHashTagIds = model.mediaHashTagIds;
        view.mediaHashTagName = model.mediaHashTagName;
        view.mediaTypeCode = model.mediaTypeCode;
        view.mediaTypeName = model.mediaHashTagName;
        view.languageCode = model.languageCode;
        view.languageName = model.languageName;
        view.mediaMediaUseId = model.mediaMediaUseId;
        view.mediaUseCode = model.mediaUseCode;
        view.mediaUseName = model.mediaUseName;
        view.selectedFromLibrary = true;
        view.mediaFile = model.mediaFile;
        view.mediaFileBase64 = model.mediaFileBase64;
        view.mediaFilePreview = model.mediaFilePreview;
        view.mediaFilePreviewBase64 = model.mediaFilePreviewBase64;

        return view;
    }

    private findMediaContentPrimaryLanguage(model: MediaViewModel): MediaContentViewModel {
        if (model.mediaContents) {
            var filter = model.mediaContents.filter(x => x.primaryLanguageFlag == true);
            if (filter.length > 0) {
                return filter[0];
            }
        }
    }

    public updateMediaId(mediaId: string, views: MediaFileAssociationInMediaViewModel[]): MediaFileAssociationInMediaViewModel[] {
        if (views) {
            for (let view of views) {
                view.mediaId = mediaId;
            }
        }
        return views;
    }

    public setDefaultPrimaryLanguage(languageReference$: BehaviorSubject<LanguageReferenceModel[]>, model: MediaView) {
        if (languageReference$.value && !model.languageCode) {
            var filter = languageReference$.value.filter(x => x.primaryFlag == true);
            if (filter.length > 0) {
                model.languageCode = filter[0].languageCode;
                model.languageName = filter[0].languageName;
            }
        }
    }

    public getfileName(organisationId: string, contentId: string, contentType: string): string {
        return organisationId + "_" + contentId + '.' + this.getFileContentExt(contentType);
    }

    public getFileContentExt(contentType: string): string {
        if (contentType) {
            let s = contentType.split('/');
            if (s.length > 0) {
                return this.getFileExtFromContentType(s[s.length - 1]);
            }
        }
        return contentType;
    }

    private getFileExtFromContentType(type: string): string {
        var returnValue: string;
        switch (type.toLowerCase()) {
            case 'vnd.openxmlformats-officedocument.wordprocessingml.document':
                returnValue = 'docx'
                break;
            case 'msword':
                returnValue = 'doc'
                break;
            case 'vnd.ms-excel':
                returnValue = 'xls'
                break;
            case 'vnd.openxmlformats-officedocument.spreadsheetml.sheet':
                returnValue = 'xlsx'
                break;
            case 'vnd.ms-powerpoint':
                returnValue = 'ppt'
                break;
            case 'vnd.openxmlformats-officedocument.presentationml.presentation':
                returnValue = 'pptx'
                break;
            case 'x-zip-compressed':
                returnValue = 'zip'
                break;
            case 'x-7z-compressed':
                returnValue = '7z'
                break;
            default:
                returnValue = type;
                break;
        }
        return returnValue;
    }

    public getFileExt(fileName: string): string {
        if (fileName) {
            let s = fileName.split('.');
            if (s.length > 0) {
                return s[s.length - 1];
            }
        }
        return fileName;
    }

    public isImage(contentType: string) {
        const allowTypes = ['jpg', 'jpeg', 'png', 'bmp', 'gif'];
        return this.isFile(allowTypes, contentType);
    }

    public isVideo(contentType: string) {
        const allowTypes = ['aif', 'avi', 'dif', 'm3u', 'm4a', 'm4u', 'm4v',
            'mid', 'mov', 'movie', 'mp3', 'mp4', 'mpe', 'ra', 'snd', 'wav', 'mpeg'];
        return this.isFile(allowTypes, contentType);
    }

    public isPdf(contentType: string): boolean {
        const allowTypes = ['pdf'];
        return this.isFile(allowTypes, contentType);
    }

    public isPowerPoint(contentType: string): boolean {
        const allowTypes = ['ppt', 'pptx'];
        return this.isFile(allowTypes, contentType);
    }

    public isXLS(contentType: string): boolean {
        const allowTypes = ['xls'];
        return this.isFile(allowTypes, contentType);
    }

    public isDoc(contentType: string): boolean {
        const allowTypes = ['csv', 'doc', 'docx', 'pdf', 'ppt', 'pptx', 'xls', 'xlsx', 'txt'];
        return this.isFile(allowTypes, contentType);
    }

    private isFile(allowTypes: string[], contentType: string): boolean {
        var fileExt = this.getFileContentExt(contentType);
        var filter = allowTypes.filter(x => x == fileExt);
        if (filter.length > 0) {
            return true;
        }
        else {
            return false;
        }
    }

    public getPicPreview(contentType: string): string {
        var fileName = '';
        if (this.isVideo(contentType)) {
            fileName = 'video-preview.jpg';
        } else {
            fileName = this.getPicDocPreview(contentType);
        }
        return fileName;
    }

    private getPicDocPreview(contentType: string): string {
        var fileName = 'general-preview.jpg';
        var fileExt = this.getFileContentExt(contentType);
        switch (fileExt.toLowerCase()) {
            case 'pdf':
                fileName = 'pdf-preview.jpg'
                break;
            case 'docx':
                fileName = 'doc-preview.jpg'
                break;
            case 'doc':
                fileName = 'doc-preview.jpg'
                break;
            case 'xls':
                fileName = 'excel-preview.jpg'
                break
            case 'xlsx':
                fileName = 'excel-preview.jpg'
                break;
            case 'csv':
                fileName = 'excel-preview.jpg'
                break;
            case 'ppt':
                fileName = 'ppt-preview.jpg'
                break;
            case 'pptx':
                fileName = 'ppt-preview.jpg'
                break;
            case 'zip':
                fileName = 'zip-preview.jpg'
                break;
            case '7z':
                fileName = 'zip-preview.jpg'
                break;
            default:
                break;
        }
        return fileName;
    }

    public formatBytes(bytes, decimals = 2) {
        if (bytes === 0) return '0 Bytes';

        const k = 1024;
        const dm = decimals < 0 ? 0 : decimals;
        const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

        const i = Math.floor(Math.log(bytes) / Math.log(k));

        return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
    }

    public convertFilesToMediaViews(mediaFiles: MediaView[], addedFiles: File[],
        languageReference$: BehaviorSubject<LanguageReferenceModel[]>,
        mediaTypeFileType$: BehaviorSubject<MediaTypeFileTypeModel[]>,
        mediaTypeReference$: BehaviorSubject<MediaTypeReferenceModel[]>,
        mediaUseReference$: BehaviorSubject<MediaUseReferenceModel[]>): MediaView[] {
        let i = mediaFiles.length;
        for (let f of addedFiles) {
            ++i;
            mediaFiles.push(this.convertFileToMediaView(i, f,
                languageReference$,
                mediaTypeFileType$,
                mediaTypeReference$,
                mediaUseReference$));
        }
        return mediaFiles;
    }

    public convertFileToMediaView(i: number, f: File,
        languageReference$: BehaviorSubject<LanguageReferenceModel[]>,
        mediaTypeFileType$: BehaviorSubject<MediaTypeFileTypeModel[]>,
        mediaTypeReference$: BehaviorSubject<MediaTypeReferenceModel[]>,
        mediaUseReference$: BehaviorSubject<MediaUseReferenceModel[]>): MediaView {
        let mediaFile = new MediaView();
        mediaFile.no = i;
        const reader = new FileReader();
        reader.readAsDataURL(f);
        reader.onload = () => {
            mediaFile.mediaFileBase64 = reader.result;
        };
        mediaFile.mediaFile = f;
        mediaFile.fileChange = true;
        this.setDefaultPrimaryLanguage(languageReference$, mediaFile);
        this.setMediaTypeMediaUse(mediaFile, mediaTypeFileType$, mediaTypeReference$, mediaUseReference$);
        return mediaFile;
    }

    private setMediaTypeMediaUse(mediaFile: MediaView,
        mediaTypeFileType$: BehaviorSubject<MediaTypeFileTypeModel[]>,
        mediaTypeReference$: BehaviorSubject<MediaTypeReferenceModel[]>,
        mediaUseReference$: BehaviorSubject<MediaUseReferenceModel[]>) {
        if (!mediaTypeFileType$.value || !mediaTypeReference$.value) {
            return;
        }
        var fileExt = this.getFileExt(mediaFile.mediaFile.name);
        var filterFileTypeValid = mediaTypeFileType$.value.filter(x => x.fileTypeCode.toLowerCase() == fileExt.toLowerCase());
        if (filterFileTypeValid?.length) {
            mediaFile.mediaTypeCode = filterFileTypeValid[0].mediaTypeCode;
            mediaFile.mediaTypeName = this.getMediaTypeName(mediaFile.mediaTypeCode, mediaTypeReference$);
            var filterMediaType = mediaTypeReference$.value.filter(x => x.mediaTypeCode == mediaFile.mediaTypeCode);
            if (filterMediaType?.length) {
                mediaFile.mediaUseCode = filterMediaType[0].mediaUseCode;
                mediaFile.mediaUseName = this.getMediaUseName(mediaFile.mediaUseCode, mediaUseReference$);
            }
        }
    }

    public mediaSearchToMediaFileLibraryViews(models: MediaSearchViewModel[]): MediaLibraryView[] {
        let views: MediaLibraryView[] = new Array();
        if (models) {
            let i = 0;
            for (let model of models) {
                ++i;
                views.push(this.mediaSearchToMediaFileLibraryView(i, model));
            }
        }
        return views;
    }

    private mediaSearchToMediaFileLibraryView(i: number, model: MediaSearchViewModel): MediaLibraryView {
        let view = new MediaLibraryView();
        view.no = i;
        view.mediaId = model.mediaId;
        view.mediaName = model.mediaName;
        if (model.validFromDateTime) {
            view.validFrom = this.dateConverterService.toDateFormat(model.validFromDateTime);
        }
        if (model.validToDateTime) {
            view.validTo = this.dateConverterService.toDateFormat(model.validToDateTime);
        }
        view.mediaHashTagIds = this.mediaMediaHashTagToView(model.mediaMediaHashTags);
        view.mediaMediaHashTagIds = this.getMediaMediaHashTagId(model.mediaMediaHashTags);
        view.mediaHashTagName = this.getMediaHashTagName(model.mediaMediaHashTags);
        view.mediaTypeCode = model.mediaTypeCode;
        view.mediaTypeName = model.mediaTypeName;
        view.languageCode = model.mediaContents[0].languageCode;
        view.languageName = model.mediaContents[0].languageName;
        view.mediaContentId = model.mediaContents[0].mediaContentId;
        view.mediaMediaUseId = model.mediaUses[0].mediaMediaUseId;
        if (model.mediaUses) {
            view.mediaUseCode = model.mediaUses[0].mediaUseCode;
            view.mediaUseName = model.mediaUses[0].mediaUseName;
        }

        view.totalUsedCount = model.totalUsedCount;
        return view;
    }

    public mediaViewToMediaLibraryCommand(media: MediaView): MediaLibraryCommandModel {
        let model = new MediaLibraryCommandModel();
        let mediaCommand: MediaCommandModel[] = new Array();
        mediaCommand.push(this.mediaViewToCommandModel(media));
        model.mediaCommand = mediaCommand;
        return model;
    }

    private mediaViewToCommandModel(media: MediaView): MediaCommandModel {
        let model = new MediaCommandModel();
        model.mediaTypeCode = media.mediaTypeCode;
        model.mediaName = media.mediaName;
        model.validFromDateTime = media.validFrom;
        model.validToDateTime = media.validTo;
        model.mediaContents = this.mediaToMediaContentModels(media);
        model.mediaMediaHashTags = this.mediaToMediaMediaHashTagModels(media);
        model.mediaUses = this.mediaToMediaUseModels(media);
        return model;
    }

    public mediaViewModelToMediaView(view: MediaViewModel, model: MediaView, languageReference$: BehaviorSubject<LanguageReferenceModel[]>): MediaView {
        model.mediaId = view.mediaId;
        model.mediaHashTagIds = this.mediaMediaHashTagToView(view.mediaMediaHashTags);
        model.mediaMediaHashTagIds = this.getMediaMediaHashTagId(view.mediaMediaHashTags);
        model.mediaMediaUseId = view.mediaUses[0].mediaMediaUseId;
        this.assignToMediaContent(view, model);
        this.assignToMediaFileAssociation(view, model);
        model.languageName = this.getLanguageName(model.languageCode, languageReference$);
        return model;
    }

    public mediaHashTagToSelect2Data(mediaHashTags: MediaHashTagModel[]): Select2Data[] {
        let select2Data: Select2Data[] = new Array();
        for (let mediaHash of mediaHashTags) {
            let data = new Select2Data();
            data.id = mediaHash.mediaHashTagId
            data.text = mediaHash.mediaHashTagName;
            select2Data.push(data);
        }
        return select2Data;
    }    
}
