import { EventEmitter, Input, OnDestroy, Output, QueryList, ViewChildren, OnInit, Component } from '@angular/core';
import { NgForm } from '@angular/forms';
import { BehaviorSubject, combineLatest, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { DomainAttributeModel } from 'src/app/core/models/reference-model/reference-general-model';
import { AttributeChoiceModel } from 'src/app/core/models/reference-model/reference-general-model/attribute-choice.model';
import {
    ProductGroupReferenceModel,
    ProductTypeGroupModel
} from 'src/app/core/models/reference-model/reference-product-model';

import {
    DomainAttributeService,
    ProductTypeGroupService
} from 'src/app/core/services/airline-services';
import { ProductService } from 'src/app/core/services/product-services/product.service';
import { PricingDetailConstant } from '../../../shared/pricing-detail.constant';
import { Select2OptionsService } from '../../product-eligible-restricted/shared/select2-options.service';
import { 
    PriceRuleAttributeAndRuleRowView,
    PriceRuleAttributeView, 
    PriceRuleProductView 
} from '../../product-eligible-restricted/shared/views';
import { AttributeAndRuleBase } from '../../shared/attribute-and-rule.base';
import { Select2Option } from '../../shared/attribute-and-rule/views/select2.option';

@Component({
    template: ''
})
export abstract class AttributeBase extends AttributeAndRuleBase implements OnInit, OnDestroy {
    abstract title: string;
    abstract priceRuleAttributeGroupCode: string;
    abstract priceRuleTypeCode: string;
    abstract focusing: boolean;

    public select2AttributeOption = new Select2Option("<Type>");
    public select2ChoiceOption = new Select2Option();
    public select2ProductNameOption = new Select2Option('<Product Name>');

    public rows$ = new BehaviorSubject<PriceRuleAttributeAndRuleRowView[]>(null);
    public domainAttributes$ = new BehaviorSubject<DomainAttributeModel[]>(null);
    public productTypeReferences$ = new BehaviorSubject<ProductTypeGroupModel[]>(null);
    public products$ = new BehaviorSubject([]);

    public productGroupReferences: ProductGroupReferenceModel[];

    @Input() searchMode: boolean = true;
    @Input() priceRuleId: string;
    @Input() set data(val: PriceRuleAttributeAndRuleRowView[]) {
        if (val?.length) {
            this.rows$.next(val);
            this.setPreSelectedItems();
        }
    }
    @Input() disabled: boolean = false;
    @Output() dataChange = new EventEmitter();
    @ViewChildren(NgForm) forms: QueryList<NgForm>;

    private unsubscribe$ = new Subject();

    get domainAttributes(): DomainAttributeModel[][] {
        let result = new Array<Array<DomainAttributeModel>>();
        let rows = this.rows$.value;
        if (rows?.length) {
            for (let row of rows) {
                let otherAttributeTypeCodes = rows.map(r => r.attribute?.attributeTypeCode)
                    .filter(r => r != row.attribute?.attributeTypeCode);
                let domainAttrs = this.domainAttributes$.value?.filter(da => otherAttributeTypeCodes.includes(da.attributeTypeCode) == false);
                result.push(domainAttrs);
            }
        }
        return result;
    }

    constructor(
        private domainAttributeService: DomainAttributeService,
        private select2OptionsService: Select2OptionsService,
        private productTypeGroupService: ProductTypeGroupService,
        private productService: ProductService) {
        super();
    }

    ngOnDestroy(): void {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }


    ngOnInit(): void {
        this.select2ChoiceOption = this.select2OptionsService.getSelect2ConditionOption();
        this.getDomainAttributes();
        this.getProductTypeReferences();
    }

    add() {
        if (this.validForms() && this.noDuplicatedRowFound()) {
            this.addErrorFlag = false;
            let rows = this.rows$.value ?? [];
            rows.push(this.createNewRow());
            this.rows$.next(rows);
        } else {
            this.addErrorFlag = true;
        }
        this.togglePanelCollapseStatus(false);
    }

    private createNewRow(): PriceRuleAttributeAndRuleRowView {
        let row = new PriceRuleAttributeAndRuleRowView();
        row.attribute = new PriceRuleAttributeView();
        return row;
    }

    private getDomainAttributes() {
        this.domainAttributeService
            .getPriceRuleAttributes(this.priceRuleTypeCode ?? '', this.priceRuleAttributeGroupCode, '')
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe(
                res => {
                    let domainAttributes = res;
                    if (this.searchMode) {
                        domainAttributes = domainAttributes.filter(da => da.searchFlag == true);
                    }
                    this.domainAttributes$.next(domainAttributes);
                    this.addAttributesOnNew();
                }
            )
    }

    private getDomainAttributeByCode(attributeTypeCode: string): DomainAttributeModel {
        return this.domainAttributes$.value.find(attr => attr.attributeTypeCode == attributeTypeCode);
    }

    private addAttributesOnNew() {
        let rows = this.rows$.value ?? [];
        if (this.searchMode) {
            this.rows$.next([]);
        } else if (!rows?.length && !this.priceRuleId) {
            for (let attrType of this.domainAttributes$.value) {
                if (attrType.showOnNewFlag == true && !this.priceRuleId) {
                    let row = this.createNewRow();
                    row.attribute.attributeTypeCode = attrType.attributeTypeCode;
                    this.onAttributeChange(row, attrType.attributeTypeCode);
                    rows.push(row);
                }
            }
            this.rows$.next(rows)
        }
        this.togglePanelCollapseStatus(false);
    }

    public getChoices(attributeTypeCode: string) {
        if (!this.domainAttributes$.value || !attributeTypeCode) {
            return [];
        }
        let priceRuleAttributeType = this.getDomainAttribute(attributeTypeCode);
        return priceRuleAttributeType ? priceRuleAttributeType.attributeChoices : [];
    }

    private getDomainAttribute(attributeTypeCode: string) {
        return this.domainAttributes$.value.find(at => at.attributeTypeCode == attributeTypeCode);
    }

    public onAttributeChange(row: PriceRuleAttributeAndRuleRowView, event) {
        row.attribute.attributeChoiceId = null;
        let attribute = this.domainAttributes$.value
            .filter(at => at.attributeTypeCode == event)[0];
        this.setDefaultChoice(attribute, row);
        row.attribute.attributeTypeCode = event;
    }

    private setDefaultChoice(attribute: DomainAttributeModel, row: PriceRuleAttributeAndRuleRowView) {
        if (attribute) {
            let choices = attribute.attributeChoices;
            if (choices?.length) {
                let defaultChoice = choices.filter(c => c.defaultFlag == true)[0];
                if (defaultChoice) {
                    row.attribute.attributeChoiceId = defaultChoice.attributeChoiceId;
                } else {
                    row.attribute.attributeChoiceId = choices[0].attributeChoiceId;
                }

            }
        }
    }

    public onChoiceChange(row: PriceRuleAttributeAndRuleRowView, event) {
        row.attribute.attributeChoiceId = event;
    }

    private createPriceRuleProduct(attributeTypeCode: string): PriceRuleProductView {
        let priceRuleProduct = new PriceRuleProductView();
        priceRuleProduct.productTypeCode = this.getProductTypeCode(attributeTypeCode);
        priceRuleProduct.productGroupCode = this.getProductGroupCode(attributeTypeCode);
        priceRuleProduct.productCategoryCode = PricingDetailConstant.ATTRIBUTE_PRODUCT_CATEGORY_CODE;
        priceRuleProduct.excludeFlag = false;
        return priceRuleProduct;
    }

    public isProductIncluded(attributeTypeCode: string, attributeChoiceId: string): boolean {
        if (this.domainAttributes$.value?.length) {
            let domainAttribute = this.getDomainAttribute(attributeTypeCode);
            if (domainAttribute) {
                let choice = this.getAttributeChoice(domainAttribute, attributeChoiceId);
                if (choice) {
                    return this.isChoiceHaveProductIncluded(domainAttribute.attributeTypeCode, choice.attributeChoiceCode);
                }
            }
        }
        return false;
    }

    private isChoiceHaveProductIncluded(attributeTypeCode: string, priceRuleAttributeChoiceCode): boolean {
        return PricingDetailConstant.PRICE_RULE_ATTRIBUTE_TYPE_CODES_PRODUCT_INCLUDED
            .find(attr => attr.attributeTypeCode == attributeTypeCode) &&
            PricingDetailConstant.PRICE_RULE_ATTRIBUTE_CHOICE_CODES_TO_SHOW_PRODUCT.includes(priceRuleAttributeChoiceCode);
    }

    private getAttributeChoice(domainAttribute: DomainAttributeModel, attributeChoiceId: string): AttributeChoiceModel {
        return domainAttribute.attributeChoices.find(c => c.attributeChoiceId == attributeChoiceId);
    }

    public attemptToSubmit() {
        if (this.validForms() && this.noDuplicatedRowFound()) {
            this.addErrorFlag = false;
            this.dataChange.emit(this.rows$.value);
            return true;
        } else {
            this.addErrorFlag = true;
            return false;
        }
    }

    private setPreSelectedItems() {
        this.productTypeReferences$.pipe(takeUntil(this.unsubscribe$)).subscribe(
            (values) => {
                if (values?.length) {
                    for (let row of this.rows$.value) {
                        if (row.attribute?.products?.length) {
                            this.getProducts(row.attribute.attributeTypeCode,
                                this.getProductGroupCode(row.attribute.attributeTypeCode),
                                this.getProductTypeCode(row.attribute.attributeTypeCode))
                        }
                    }
                }
            }
        )
    }

    private getProducts(attributeTypeCode: string, productGroupCode: string, productTypeCode: string) {
        this.productService.getBy(productGroupCode, productTypeCode, 'DATA')
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe(
                res => {
                    if (res?.length) {
                        let products = this.products$.value;
                        products[attributeTypeCode] = res;
                        this.products$.next(products)
                    }
                }
            )
    }

    private getProductTypeCode(attributeTypeCode: string) {
        return this.getProductTypeGroupByPriceRuleAttributeTypeId(attributeTypeCode)?.productTypeCode;
    }

    private getProductGroupCode(attributeTypeCode: string) {
        return this.getProductTypeGroupByPriceRuleAttributeTypeId(attributeTypeCode)?.productGroupCode;
    }

    private getProductTypeGroupByPriceRuleAttributeTypeId(attributeTypeCode: string): ProductTypeGroupModel {
        var priceRuleAttributeType = this.getDomainAttributeByCode(attributeTypeCode)
        var priceRuleAttributeTypeProductIncluded = this.getPriceRuleAttributeTypeProductIncluded(priceRuleAttributeType);
        return this.getProductTypeGroup(this.productTypeReferences$.value, priceRuleAttributeTypeProductIncluded.productGroupCode, priceRuleAttributeTypeProductIncluded.productTypeCode);
    }

    private getPriceRuleAttributeTypeProductIncluded(domainAttribute: DomainAttributeModel) {
        return PricingDetailConstant.PRICE_RULE_ATTRIBUTE_TYPE_CODES_PRODUCT_INCLUDED
            .find(prat => prat.attributeTypeCode == domainAttribute.attributeTypeCode)
    }

    private getProductTypeReferences() {
        combineLatest(
            PricingDetailConstant.PRICE_RULE_ATTRIBUTE_TYPE_CODES_PRODUCT_INCLUDED.map(
                item => this.productTypeGroupService.getProductTypeGroupByProductGroupCode(item.productGroupCode)
            )
        )
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe(
                (responses: ProductTypeGroupModel[][]) => {
                    this.productTypeReferences$.next(this.extractProductTypeReferences(responses));
                }
            )
    }

    private extractProductTypeReferences(responses: ProductTypeGroupModel[][]) {
        let productTypeReferences = [];
        for (let item of PricingDetailConstant.PRICE_RULE_ATTRIBUTE_TYPE_CODES_PRODUCT_INCLUDED) {
            for (let res of responses) {
                let productTypeReference = this.getProductTypeGroup(res, item.productGroupCode, item.productTypeCode);
                if (productTypeReference) {
                    productTypeReferences.push(productTypeReference);
                    break;
                }
            }
        }
        return productTypeReferences;
    }

    private getProductTypeGroup(productTypeGroups: ProductTypeGroupModel[], productGroupCode: string, productTypeCode: string): ProductTypeGroupModel {
        return productTypeGroups.find(pt => pt.productGroupCode == productGroupCode && pt.productTypeCode == productTypeCode);
    }
}