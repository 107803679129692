import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgxDropzoneModule } from 'ngx-dropzone';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { ButtonsModule } from 'ngx-bootstrap/buttons';
import { CollapseModule } from 'ngx-bootstrap/collapse';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { DxDataGridModule } from 'devextreme-angular';
import { SweetAlert2Module } from '@sweetalert2/ngx-sweetalert2';

import { PanelsModule } from 'src/app/shared/panels/panels.module';
import { DialogsModule } from 'src/app/shared/dialogs/dialogs.module';
import { FocusingModule } from 'src/app/shared/ui/forms/inputs/focusing.module';
import { DaterangepickerModule } from 'src/app/shared/ui/forms/inputs/oops-daterangepicker/daterangepicker.module';
import { Select2Module } from 'src/app/shared/ui/forms/inputs/oops-select2/select2.module';
import { UtilsModule } from 'src/app/shared/utils/utils.module';
import { DialogsService } from 'src/app/shared/dialogs/dialogs.service';
import { Helper } from 'src/app/shared/helper/app.helper';

import { MediaComponent } from './media.component';
import { MediaTableComponent } from './media-table/media-table.component';
import { MediaDetailComponent } from './media-detail/media-detail.component';
import { MediaDetailDirective } from './media-detail/media-detail.directive';
import { MediaPreviewComponent } from './media-preview/media-preview.component';
import { MediaLibraryTableComponent } from './media-library-table/media-library-table.component';
import { MediaLibraryTableDirective } from './media-library-table/media-library-table.directive';

@NgModule({
    declarations: [
        MediaComponent,
        MediaTableComponent,
        MediaDetailComponent,
        MediaDetailDirective,
        MediaPreviewComponent,
        MediaLibraryTableComponent,
        MediaLibraryTableDirective
    ],
    exports: [
        MediaComponent, MediaTableComponent, MediaDetailComponent, MediaDetailDirective,
        MediaPreviewComponent, MediaLibraryTableComponent, MediaLibraryTableDirective
    ],
    imports: [
        CommonModule,
        PanelsModule,
        ReactiveFormsModule,
        FocusingModule,
        FormsModule,
        DialogsModule,
        MatIconModule,
        NgbModule,
        Select2Module,
        CollapseModule,
        DaterangepickerModule,
        NgxDropzoneModule,
        ButtonsModule,
        BsDropdownModule,
        UtilsModule,
        TooltipModule,
        DxDataGridModule,
        SweetAlert2Module
    ],
    providers: [
        DialogsService, Helper
    ]
})
export class MediaModule {
}