import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { GeneralComponent } from './general/general.component';
import { TreeComponent } from './tree/tree.component';
import { ScheduleInformationComponent } from './schedule-information/schedule-information.component';  
import { ScheduleFlightInformationComponent } from './schedule-flight-information/schedule-flight-information.component';
import { InventoryAttributeComponent } from './inventory-attribute/inventory-attribute.component';
import { TransportDetailComponent } from './transport-detail.component';
import { RouteComponent } from './route/route.component';
import { SalesBucketComponent } from './salesbucket/salesbucket.component';
import { ServiceCategoryComponent } from './servicecategory/servicecategory.component';
import { DxDataGridModule, DxTreeListModule } from 'devextreme-angular';
import { FocusingModule } from 'src/app/shared/ui/forms/inputs/focusing.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ItemsFoundModule } from 'src/app/core/pipes/items-found/items-found.module';
import { MatIconModule } from '@angular/material/icon';
import { PanelsModule } from 'src/app/shared/panels/panels.module';
import { Select2Module } from 'src/app/shared/ui/forms/inputs/oops-select2/select2.module';
import { StatusColorDropdownModule } from 'src/app/shared/ui/forms/inputs/status-color-dropdown/status-color-dropdown.module';
import { UiModule } from 'src/app/shared/ui/ui.module';
import { TransportAttributeAndRuleModule } from '../shared/attribute-and-rule/attribute-and-rule.module';
import { LoadingSpinnerModule } from 'src/app/shared/layout/loading-spinner';
import { TreeModule } from "primeng/tree";
import { NgbNavModule } from '@ng-bootstrap/ng-bootstrap';
import { AlertBarModule } from 'src/app/shared/layout/alertbar';
import { FreezingTabModule } from "src/app/shared/ui/forms/inputs/freezing-tab/freezing-tab.module";
import { NavigationIndexModule } from 'src/app/shared/layout/navigation-index';
import { TextMediaModule } from 'src/app/core/components/text-media/text-media.module';
import { TranslationNameModule } from 'src/app/core/components/translation-name/translation-name.module';
import { TranslationMediaModule } from 'src/app/core/components/translation-media/translation-media.module';
import { TranslationTextModule } from 'src/app/core/components/translation-text/translation-text.module';
import { OperatingProductComponent } from './operating-product/operating-product.component';
import { OperatingProductListViewComponent } from './operating-product/listview/operating-product-listview.component';
import { TooltipModule } from "ngx-bootstrap/tooltip"
import { OperatingProductCalendarViewComponent } from './operating-product/calendar/operating-product-calendar.component';
import { NgbPaginationModule } from '@ng-bootstrap/ng-bootstrap';
import { FullCalendarModule  } from '@fullcalendar/angular';
import { DxCheckBoxModule } from 'devextreme-angular';

import { VerifyResultComponent } from './verify-result/verify-result.component';
import { StatusColorModule } from 'src/app/shared/ui/forms/inputs/status-color/status-color.module';
import { ActivityDomainModule } from 'src/app/core/components/activity-domain/activity-domain.module';
import { ValidationComponent } from './validation/validation.component';

@NgModule({
    declarations: [
        GeneralComponent,
        InventoryAttributeComponent,
        OperatingProductComponent,
        OperatingProductCalendarViewComponent,
        OperatingProductListViewComponent,
        RouteComponent,
        TreeComponent,
        TransportDetailComponent,
        SalesBucketComponent,
        ScheduleFlightInformationComponent,
        ScheduleInformationComponent,
        ServiceCategoryComponent,
        ValidationComponent,
        VerifyResultComponent
    ],
    imports: [
        ActivityDomainModule,							 
        AlertBarModule,
        CommonModule,
        DxCheckBoxModule,
        DxDataGridModule,
        DxTreeListModule,
        FocusingModule,
        FormsModule,
        FreezingTabModule,
        FullCalendarModule,
        ItemsFoundModule,
        LoadingSpinnerModule,
        MatIconModule,
        NavigationIndexModule,
        NgbNavModule,
        NgbPaginationModule,
        PanelsModule,
        ReactiveFormsModule,
        Select2Module,
        StatusColorDropdownModule,
        StatusColorModule,
        TextMediaModule,
        TooltipModule,
        TransportAttributeAndRuleModule,
        TranslationNameModule,
        TranslationMediaModule,
        TranslationTextModule,
        TreeModule,
        UiModule
    ],
    exports: [
        GeneralComponent,
        TransportDetailComponent,
        TreeComponent
    ]
})
export class TransportDetailModule { }