export const OrdersConstant = {
    HomeTitle: "Order Management",
    ShoppingTitle: "Shopping",
    OrderTitle: "Order"
}
export const OverviewTabConstant = {
    TabCategory: "categories",
    TabMyOrders: "myorders",
    TabSearch: "search",
    TabDashboard: "dashboard"
}
export const ShoppingTabConstant = {
    TabCollect: "collect",
    TabShop: "shop",
    TabIndividual: "individual",
    TabContact: "contact",
    TabPayment: "payment",
    TabSummary: "summary",
    TabCartHidden: "cart",
    TabSeat: "seat",
    TabService: "service",
    TabAncillarie: "ancillarie"
}
export const DetailTabConstant = {
    TabHeader: "header",
    TabIndividual: "individual",
    TabProduct: "product",
    TabIndividualDocument: "document",
    TabIndividualAccountableDocument: "accuntableDocument",
    TabOrderDetailService: "orderDetailService",
    TabOrderDetailPayment: "orderDetailPayment",
    TabOrderDetailComment: "orderDetailComment",
    TabOrderDetailProductHistory: "orderDetailProductHistory",
}
export const ProductGroupCodeConstant = {
    Air: "AIR",
    Seat: "SEAT",
    Refund: "REFUND",
    MealAndBeverage: "MEALANDBEVERAGE",
    Bag: "BAG",
    Wheelchair: "WCHR",
    Disabled: "DISB",
    Pet: "PET",
    Medical: "MEDICAL",
    Comfort: "COMFORT",
    Others: "OTHERS",
    Individual: "INDIVIDUAL",
    Payment: "PAYMENT",
    Sales: "SALES",
    Service: "SERVICE"
}
export const ProductCategoryConstant = {
    Transport: "TRANSPORT",
    Service: "SERVICE",
    Ancillary: "ANCILLARY",
    Fee: "FEE",
    Voucher: "VOUCHER"
}
export const OrderTypeCode = {
    Commercial: "COMMERCIAL",
    Group: "GROUP",
    NonCommercial: "NONCOMMERCIAL",
    Staff: "NONREV",
    Staff_Confirm: "NONREV_CONFIRM",
    Subload: "SUBLOAD"
}
export const AgeGroupCodeFull = {
    Adult: "ADULT",
    Child: "CHILD",
    Infant: "INFANT"
}
export const AgeGroupCode = {
    Adult: "ADT",
    Child: "CHD",
    Infant: "INF"
}
export const ShoppingTransportType = {
    RoundTrip: "RoundTrip",
    OneWay: "OneWay"
}
export const ShoppingTreeNodeType = {
    passenger: "PASSENGER",
    ssr: "SSR",
    ssrFlight: "SSRFLIGHT",
    seat: "SEAT",
    seatFlight: "SEATFLIGHT"
}

export const PaymentConstant = {
    cc: "CC",
    cr: "CR",
    dr: "DR",
    pending: "PENDING",
    paid: "PAID",
    paymentAmount: 100,
    cash: "CASH",
    cancelled: "CANCELLED",
    issued: "ISSUED",
    creditAccount: "CREDITACCOUNT",
    voucher: "VOUCHER",
    sales: "SALES"
}

export const CommunicationOtherTypeCodeConstant = {
    email: 'EMAIL'
}

export const EmployeeAttributeCodeConstant = {
    priorityNumber: 'PRIORITYNBR',
    regradeCabin: 'REGRADECABIN',
    regradePriority: 'REGRADEPRIORITY',
    dateOfJoin: 'DOJ',
    employeeNumber: 'EMPLOYEENBR',
    employerCode: 'EMPLOYERCODE',
    staffPassenger: 'STAFF',
    tripPurpose: 'TRIPPURPOSE'
}

export const HeightPanelShopping = {
    specialServiceHeightPanel: 31.5
}

export const select2Contact = {
    placeholder: "",
    allowClear: true,
    width: 'auto',
    dropdownAutoWidth: true,
    minimumResultsForSearch: -1
}

export const ShoppingLabelTreeView = {
    specialServices: "Special Services",
    ancillaries: "Ancillaries",
    seats: "Seats",
}

export const ShoppingContactConstant = {
    contactCollectManual: "CONTACTCOLLECTMANUAL",
    contact: "CONTACT",
    collect: "COLLECT"
}

export const OrderMode = {
    detail: "DETAIL",
    delete: "DELETE",
    refund: "REFUND"
}

export const DeletableOrderSalesStatuses = [
    "CONFIRMED",
    "LISTED",
    "WAITLISTED",
    "PENDING"
]

export const DeletableOrderDeliveryStatuses = [
    "OPEN",
    "PENDING"
]

export const UndeletableOrderSalesStatuses = [
    "CANCELLED",
    "EXEMPTION"
]

export const UndeletableOrderDeliveryStatuses = [
    "USED",
    "EXPIRED"
]

export const FormOfPaymentType = {
    Voucher: "VOUCHER",
    Document: "DOCUMENT",
    Cash: "CASH",
    CreditCard: "CC",
    Cheque: "CHEQUE",
    CreditAccount: "CREDITACCOUNT",
    ElectronicFundTransfer: "EFT",
    MobileBanking: "MOBILE",
    Paypal: "PAYPAL"
}

export const FormOfPayment = {
    Invoice: "INVOICE",
    Voucher: "VOUCHER",
    Sales: "SALES",
    Cash: "CASH",
    MasterCard: "CA",
    AmericanExpress: "AX",
    CreditNote: "CREDITNOTE",
    Dankort: "DK",
    Discover: "DS",
    JCB: "JC",
    Maestro: "MA",
    Refund: "REFUND",
    TopUp: "TOPUP",
    ChinaUnionPay: "UP",
    Visa: "VI"
}

export const PriceRuleTypeNames = {
    "AIRPORTTAX": "Airport Tax",
    "CARBONOFFSET": "Carbon Offset",
    "EARLYBOOKDISCOUNT": "Early Book Discount",
    "FEE": "Fee",
    "FUELSURCHARGE": "Fuel Surcharge",
    "GOVERNMENTTAX": "Government Tax",
    "GST": "GST",
    "HOTELTAX": "Hotel Tax",
    "OPTIONALSERVICE": "Optional Service",
    "PRICE": "Price",
    "PROMOCODE": "Promo Code",
    "SALESTAX": "Sales Tax",
    "SEASONALDISCOUNT": "Seasonal Discount",
    "SERVICETAX": "Service Tax",
    "SHIPPINGDISCOUNT": "Shipping Discount",
    "SHIPPINGSURCHARGE": "Shipping Surcharge",
    "TOURISMTAX": "Tourism Tax",
    "VAT": "VAT"
}

export enum OrderCancelItemType {
    Flight,
    Individual,
    Product,
    Total
}

export enum OrderPaymentItemType {
    Flight,
    Individual,
    Product,
    Total
}

export const OrderPaymentSecurityCode = {
    Pay: "ORDERPAYMENTPAY",
    Refund: "ORDERPAYMENTREFUND",
    Void: "ORDERPAYMENTVOID",
    Cancel: "ORDERPAYMENTCANCEL"
}