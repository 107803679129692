import { 
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component, 
    EventEmitter, 
    Input, 
    Output, 
    ViewChild 
} from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { PointOfSalesMapperService } from 'src/app/core/components/rules-config/shared/mappers/pointofsales-mapper.service';
import { RestrictionMapperService } from 'src/app/core/components/rules-config/shared/mappers/restriction-mapper.service';
import { ValidityMapperService } from 'src/app/core/components/rules-config/shared/mappers/validity-mapper.service';
import { PointOfSalesModel, ValidityModel } from 'src/app/core/models/merchandizing-config';
import { OrganisationModel } from 'src/app/core/models/organisation-model';
import { ProductAttributeCommandModel, ProductAttributeViewModel } from 'src/app/core/models/product-model/product-base-model/product-attribute';
import { ProductHashTagViewModel } from 'src/app/core/models/product-model/product-base-model/product-hashtag';
import { ProductNumberViewModel } from 'src/app/core/models/product-model/product-base-model/product-number';
import { ProductRestrictionLocationViewModel, ProductRestrictionOrganisationViewModel, ProductRestrictionProductNumberViewModel, ProductRestrictionProductViewModel, ProductRestrictionRouteViewModel, ProductRestrictionVehicleViewModel } from 'src/app/core/models/product-model/product-base-model/product-restriction';
import { ProductValidityViewModel } from 'src/app/core/models/product-model/product-base-model/product-validity';
import { SpecialServiceAddCommandModel, SpecialServiceViewModel } from 'src/app/core/models/product-model/specialservice-model';
import { 
    CalendarValidityReferenceModel, 
    ConditionReferenceModel, 
    DateTimeDimensionReferenceModel, 
    DomainAttributeModel, 
    HashTagReferenceModel,
    VehicleCompositionModel,
    VehicleGroupReferenceModel,
    VehicleTypeReferenceModel} from 'src/app/core/models/reference-model/reference-general-model';
import { SeasonReferenceModel } from 'src/app/core/models/reference-model/reference-general-model/season-reference.model';
import { 
    ProductCategoryReferenceModel, 
    ProductGroupReferenceModel, 
    ProductLocationPointReferenceModel, 
    ProductLocationTypeReferenceModel, 
    ProductNumberTypeReferenceModel, 
    ProductTypeGroupModel 
} from 'src/app/core/models/reference-model/reference-product-model';
import { Select2Data } from 'src/app/shared/ui/forms/inputs/oops-select2';
import { AttributeMapperService } from '../../../shared/mapper/attribute-mapper.service';
import { HashTagMapperService } from '../../../shared/mapper/hashtag-mapper.service';
import { NumberMapperService } from '../../../shared/mapper/number-mapper.service';
import { ProductHashTagView } from '../../../shared/views/product-hashtag.view';
import { AttributeComponent } from './attribute/attribute.component';
import { HashtagComponent } from './hashtag/hashtag.component';
import { PointOfSalesComponent } from './point-of-sales/point-of-sales.component';
import { ProductNumberComponent } from './product-number/product-number.component';
import { RestrictionComponent } from './restriction/restriction.component';
import { SeasonComponent } from './season/season.component';
import { ValidityComponent } from './validity/validity.component';

@Component({
    selector: 'op-ancillary-service-attribute-and-rule',
    templateUrl: './ancillary-service-attribute.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [
        HashTagMapperService,
        PointOfSalesMapperService,
        RestrictionMapperService,
        ValidityMapperService,
        AttributeMapperService,
        NumberMapperService
    ]
})
export class AncillaryServiceAttributeComponent {
    @Input() productHashtag$ = new BehaviorSubject<ProductHashTagView[]>(null);
    @Input() provider$ = new BehaviorSubject<OrganisationModel[]>(null);
    @Input() supplier$ = new BehaviorSubject<OrganisationModel[]>(null);
    @Input() productTypeCode$ = new BehaviorSubject<string>(null);
    @Input() productGroupCode$ = new BehaviorSubject<string>(null);
    @Input() productNumberTypeReference$ = new BehaviorSubject<ProductNumberTypeReferenceModel[]>(null);
    @Input() productId: string;
    @Input() parentId: string;
    @Input() rootLevel: boolean = false;
    @Input() newProduct: boolean = false;
    @Input() newFromParent = false;
    @Input() searchMode: boolean = false;
    @Input() domainAttributeSearch$ = new BehaviorSubject<DomainAttributeModel[]>(null);
    @Input() domainAttributeService$ = new BehaviorSubject<DomainAttributeModel[]>(null);
    @Input() domainAttributeINV$ = new BehaviorSubject<DomainAttributeModel[]>(null);
    @Input() seasonReference$ = new BehaviorSubject<SeasonReferenceModel[]>(null);
    @Input() conditionReference$ = new BehaviorSubject<ConditionReferenceModel[]>(null);
    @Input() productLocationPointReference$ = new BehaviorSubject<ProductLocationPointReferenceModel[]>(null);
    @Input() productLocationTypeReference$ = new BehaviorSubject<ProductLocationTypeReferenceModel[]>(null);
    @Input() countryReferenceSelect2Data: Select2Data[];
    @Input() locationGroupSelect2Data: Select2Data[];
    @Input() regionReferenceSelect2Data: Select2Data[];
    @Input() productCategoryReferences$ = new BehaviorSubject<ProductCategoryReferenceModel[]>(null);
    @Input() productGroupReferences$ = new BehaviorSubject<ProductGroupReferenceModel[]>(null);
    @Input() serviceProductCategoryReferences$ = new BehaviorSubject<ProductCategoryReferenceModel[]>(null);
    @Input() serviceProductGroupReference$ = new BehaviorSubject<ProductGroupReferenceModel[]>(null);
    @Input() productTypeGroup$ = new BehaviorSubject<ProductTypeGroupModel[]>(null);
    @Input() calendarValidityReference$ = new BehaviorSubject<CalendarValidityReferenceModel[]>(null);
    @Input() dateTimeDimensionReference$ = new BehaviorSubject<DateTimeDimensionReferenceModel[]>(null);
    @Input() organisationSelect2Data: Select2Data[];
    @Input() organisationTypeSelect2Data: Select2Data[];
    @Input() organisationGroupSelect2Data: Select2Data[];
    @Input() organisationRoleSelect2Data: Select2Data[];
    @Input() serviceCateSelected: boolean = false;
    @Input() specialServiceView: SpecialServiceViewModel;
    @Input() validityConfig: ValidityModel;
    @Input() pointOfSalesConfig: PointOfSalesModel;
    @Input() hashTagReference$ = new BehaviorSubject<HashTagReferenceModel[]>(null);
    @Input() vehicleGroupReferences$ = new BehaviorSubject<VehicleGroupReferenceModel[]>(null);
    @Input() vehicleTypeReferences$ = new BehaviorSubject<VehicleTypeReferenceModel[]>(null);
    @Input() vehicleCompositions$ = new BehaviorSubject<VehicleCompositionModel[]>(null);
    @Output() hashTagValueReturn = new EventEmitter<ProductHashTagView[]>();

    @ViewChild(HashtagComponent) hashtagComponent: HashtagComponent;
    @ViewChild(ProductNumberComponent) productNumberComponent: ProductNumberComponent;
    @ViewChild(AttributeComponent) attributeComponent: AttributeComponent;
    @ViewChild(SeasonComponent) seasonComponent: SeasonComponent;
    @ViewChild(RestrictionComponent) restrictionComponent: RestrictionComponent;
    @ViewChild(ValidityComponent) validityComponent: ValidityComponent;
    @ViewChild(PointOfSalesComponent) pointOfSalesComponent: PointOfSalesComponent;

    public productHashtagValueViews: ProductHashTagView[];

    constructor(private hashtagMapperService: HashTagMapperService,
        private restrictionMapperService: RestrictionMapperService,
        private validityMapperService: ValidityMapperService,
        private pointOfSalesMapperService: PointOfSalesMapperService,
        private attributeMapperService: AttributeMapperService,
        private numberMapperService: NumberMapperService,
        private changeDetectorRef: ChangeDetectorRef) { }

    public hashtagValueReturn(value) {
        let productHashTag = this.hashtagMapperService.productHashTagValueToViews(value, this.productHashtag$.value);
        this.productHashtagValueViews = productHashTag;
        this.hashTagValueReturn.emit(productHashTag);
    }

    fillToAdvanceSearchCondition(response: SpecialServiceViewModel) {
        this.setProductHashTagValue(response.productHashtags);
        this.fillModelToProductNumber(response.productNumbers);
        this.fillModelToProductAttribute(response.productAttributes, response.draftFlag)
        this.fillModelToSeason(response.seasonCode);
        this.fillModelToProductRestriction(response.productRestrictionProducts,
            response.productRestrictionProductNumbers,
            response.productRestrictionRoutes,
            response.productRestrictionLocations,
            response.productRestrictionVehicles
        );
        this.fillModelToProductValidity(response.productValidities);
        this.fillModelToPointOfSales(response.productRestrictionOrganisations, response.productRestrictionLocations);
        this.changeDetectorRef.detectChanges();
    }

    private setProductHashTagValue(value: ProductHashTagViewModel[]) {
        var productHashtagViews = this.hashtagMapperService.productHashTagViews(this.hashTagReference$.value,
            value, false);
        this.productHashtag$.next(productHashtagViews);
        this.productHashtagValueViews = productHashtagViews.filter(x => x.productHashTagId != null);
    }

    private fillModelToProductNumber(productNumbers: ProductNumberViewModel[]) {
        this.productNumberComponent.addViewToFormGroup(productNumbers);
    }

    private fillModelToProductAttribute(productAttributes: ProductAttributeViewModel[], draftFlag: boolean) {
       this.attributeComponent.productAttributes = productAttributes.filter(x => x.inheritAttribute == false);
       this.attributeComponent.draftFlag = draftFlag;
    }

    private fillModelToProductRestriction(productRestrictionProducts: ProductRestrictionProductViewModel[],
        productRestrictionProductNumbers: ProductRestrictionProductNumberViewModel[],
        productRestrictionRoutes: ProductRestrictionRouteViewModel[],
        productRestrictionLocations: ProductRestrictionLocationViewModel[],
        productRestrictionVehicles: ProductRestrictionVehicleViewModel[]) {
        
        let productRestrictionViews = this.restrictionMapperService.productRestrictionModelsToViews(productRestrictionProducts,
            productRestrictionProductNumbers,
            productRestrictionRoutes,
            productRestrictionLocations,
            productRestrictionVehicles, false);

        let productRestrictionInheritViews = this.restrictionMapperService.productRestrictionModelsToViews(productRestrictionProducts,
            productRestrictionProductNumbers,
            productRestrictionRoutes,
            productRestrictionLocations,
            productRestrictionVehicles, true);
        
        this.restrictionComponent.ruleComponent.addRestrictionViewToFormGroup(productRestrictionViews);
        this.restrictionComponent.ruleComponent.addRestrictionInheritToFormGroup(productRestrictionInheritViews);
        
    }

    private fillModelToProductValidity(productValidities: ProductValidityViewModel[]) {
        var productValiditiesProduct = this.validityMapperService.productValiditiesToForm(productValidities, false);
        var productValiditiesInherit = this.validityMapperService.productValiditiesToForm(productValidities, true);
        this.validityComponent.ruleComponent?.addValidityToFormGroup(productValiditiesProduct);
        this.validityComponent.ruleComponent?.addValidityInheritToFormGroup(productValiditiesInherit);
    }

    private fillModelToPointOfSales(productRestrictionOrganisations: ProductRestrictionOrganisationViewModel[], productRestrictionLocations: ProductRestrictionLocationViewModel[]) {
        let pointOfSalesViews = this.pointOfSalesMapperService.pointOfSalesModelsToViews(productRestrictionLocations, productRestrictionOrganisations, false);
        let pointOfSalesInheritViews = this.pointOfSalesMapperService.pointOfSalesModelsToViews(productRestrictionLocations, productRestrictionOrganisations, true);
        this.pointOfSalesComponent.ruleComponent?.addPointOfSalesViewToFormGroup(pointOfSalesViews);
        this.pointOfSalesComponent.ruleComponent?.addPointOfSalesInheritToFormGroup(pointOfSalesInheritViews);
    }

    private fillModelToSeason(seasonCode: string) {
        if (seasonCode) {
            this.seasonComponent.fillData(seasonCode);
        }
    }

    getAdvanceSearchValue(): SpecialServiceAddCommandModel {
        let productHashTags = this.hashtagMapperService.productHashTagModels(
            this.productHashtagValueViews);

        let productNumbers = this.numberMapperService.productNumberFormToModels(
            this.productNumberComponent?.forms,
            this.provider$);

        let productAttributes : ProductAttributeCommandModel[] = new Array();
        productAttributes = this.attributeMapperService.productAttributeFormToModels(
            this.attributeComponent?.forms, productAttributes);

        let productRestrictionViews = this.restrictionMapperService.productRestrictionFormToCommandViews(
            this.restrictionComponent?.ruleComponent?.forms);
        let productRestrictionProducts = this.restrictionMapperService.productRestrictionProductCommandModels(productRestrictionViews);
        let productRestrictionProductNumbers = this.restrictionMapperService.productRestrictionProductNumberCommandModels(productRestrictionViews);
        let productRestrictionRoutes = this.restrictionMapperService.productRestrictionRouteCommandModels(productRestrictionViews);
        let productRestrictionLocations = this.restrictionMapperService.productRestrictionLocationCommandModels(productRestrictionViews);

        let productValidities = this.validityMapperService.productValidityFormToModels(
            this.validityComponent?.ruleComponent?.forms);

        let pointofSalesCommand = this.pointOfSalesMapperService.productPointOfSalesFormToCommandViews(
            this.pointOfSalesComponent?.ruleComponent?.forms);
        let productRestrictionLocationPointOfSales = this.pointOfSalesMapperService.productRestrictionLocationPointOfSalesCommandModels(pointofSalesCommand);
        let productRestrictionOrganisations = this.pointOfSalesMapperService.productRestrictionOrganisationCommandModels(pointofSalesCommand);
        productRestrictionLocations = this.pointOfSalesMapperService.MergePointOfSalesToProductLocationRestriction(productRestrictionLocations, productRestrictionLocationPointOfSales);

        let addCommand: SpecialServiceAddCommandModel = {
            productCategoryCode: null,
            productGroupCode: null,
            productTypeCode: null,
            providerId: null,
            parentProductId: this.parentId,
            productName: null,
            productDescription: null,
            supplierId: null,
            displayCode: null,
            draftFlag: null,
            searchName: null,
            searchUsageTypeCode: null,
            searchStatusCode: null,
            usageTypeCode: null,
            isOwnerFilter: null,
            filterUserAccountId: null,
            statusCode: null,
            productHashTags: productHashTags,
            productNumbers: productNumbers,
            productRestrictionProducts: productRestrictionProducts,
            productRestrictionProductNumbers: productRestrictionProductNumbers,
            productRestrictionRoutes: productRestrictionRoutes,
            productRestrictionLocations: productRestrictionLocations,
            productValidities: productValidities,
            productAttributes: productAttributes,
            productInventoryDimensions: null,
            productRestrictionOrganisations: productRestrictionOrganisations,
            medias: [],
            seasonCode: <string>this.seasonComponent.seasonCode,
            finalFlag: null,
            languageTranslationSet: null,
            productRestrictionVehicles: null //work here
        };
        return addCommand;
    }

    clearCondition() {
        this.hashtagComponent.delete();
        this.productNumberComponent.clearForm();
        this.attributeComponent.clearForms();
        this.seasonComponent.delete();
    }
}
