import { Component, ChangeDetectionStrategy, Output, EventEmitter, Input, OnInit, SimpleChanges, OnChanges, ViewChild, ChangeDetectorRef } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { NgForm } from '@angular/forms';

import { ConfigEditor, select2TextType, validDateFromOption, validDateToOption, select2HashtagOption } from '../shared/text-configuration';
import {
    LanguageReferenceModel,
    MediaTypeReferenceModel,
    MediaUseReferenceModel
} from 'src/app/core/models/reference-model/reference-media-model';

import { Select2Data } from 'src/app/shared/ui/forms/inputs/oops-select2';
import { TextView } from '../shared/text.view';
import { MediaSearchCommandModel } from 'src/app/core/models/media-model';
import { TextMapperService } from '../shared/text-mapper.service';
import { TextLibraryTableComponent } from '../text-library-table/text-library-table.component';
import { DialogsService } from 'src/app/shared/dialogs/dialogs.service';
import { MediaFilePreviewService } from 'src/app/core/services/media-services/media-file-preview-service';
import { FocusingDirective } from 'src/app/shared/ui/forms/inputs/focusing.directive';

declare var $;
let thisContext;

@Component({
    selector: 'op-text-detail',
    templateUrl: './text-detail.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [TextMapperService, MediaFilePreviewService]
})
export class TextDetailComponent implements OnInit, OnChanges {

    @Input() model = new TextView();
    @Input() languageReference$ = new BehaviorSubject<LanguageReferenceModel[]>(null);
    @Input() mediaTypeReference$ = new BehaviorSubject<MediaTypeReferenceModel[]>(null);
    @Input() mediaUseReference$ = new BehaviorSubject<MediaUseReferenceModel[]>(null);
    @Input() mediaHashTagSelect2Data: Select2Data[];
    @Input() singleRecord: boolean = true;
    @Input() showBrowse: boolean = true;
    @Input() disabled: boolean = false;

    @Output() onSave = new EventEmitter<TextView>();
    @Output() onCancel = new EventEmitter();
    @Output() onSaveMediaLibrary = new EventEmitter<TextView[]>();
    @Output() notCopyTextTranslation = new EventEmitter<string>();
    @Output() onClickMediaLibrary = new EventEmitter<MediaSearchCommandModel>();
    @Output() onClickImageUpload = new EventEmitter();
    @Output() onClickImageSelectLibrary = new EventEmitter<MediaSearchCommandModel>();
    @Output() onClickVideoUpload = new EventEmitter();
    @Output() onClickVideoSelectLibrary = new EventEmitter<MediaSearchCommandModel>();
    @Output() onBrowseEnabledChanged = new EventEmitter<boolean>();

    @ViewChild(TextLibraryTableComponent) textLibraryTableComponent: TextLibraryTableComponent;
    @ViewChild('textForm') textForm: NgForm;

    @ViewChild(FocusingDirective) focusingDirective: FocusingDirective;

    public action =
        {
            submitted: false,
            browsed: false,
            mediaHashTag: false
        };

    private readonly TAG_INSERT_IMAGE: string = '<div class="hidden">[image/]</div>';
    private readonly TAG_INSERT_VIDEO: string = '<div class="hidden">[video/]</div>';
    public textTypeOption: any;
    public configEditorOption: any;
    public validDateFromOption: any;
    public validDateToOption: any;
    public mediaHashTagOption: any;
    public htmlChange: boolean = false;
    public browseEnabled: boolean = false;
    public browseLibrary: boolean = false;
    public mediaSearchCommand = new MediaSearchCommandModel();
    public mediaTypeReferenceFilter$ = new BehaviorSubject<MediaTypeReferenceModel[]>(null);
    public textDetailModel: TextView;

    constructor(private textMapperService: TextMapperService,
        private dialogService: DialogsService,
        private mediaFilePreviewService: MediaFilePreviewService,
        private changeDectionRef: ChangeDetectorRef) {
        this.textTypeOption = select2TextType;
        this.validDateFromOption = validDateFromOption;
        this.validDateToOption = validDateToOption;
        this.mediaHashTagOption = select2HashtagOption;
        this.configEditorOption = ConfigEditor;
        this.configEditorOption.callbacks = { onKeydown: () => this.htmlEditorChange() }
        this.configEditorOption.buttons.imagecustom = this.imageBtn;
        this.configEditorOption.buttons.videocustom = this.videoBtn;
    }

    ngOnInit(): void {
        thisContext = this;
        this.filterMediaType(this.model.mediaUseCode);
        this.textMapperService.setDefaultPrimaryLanguage(this.languageReference$, this.model);
    }

    ngOnChanges(changes: SimpleChanges): void {
        this.action.submitted = false;
        this.action.mediaHashTag = false;
        this.filterMediaType(this.model.mediaUseCode);
        if (this.model.text == undefined) {
            // fix: re-select media-type but previous html still appear.
            this.model.text = null;
        }
        this.textMapperService.setDefaultPrimaryLanguage(this.languageReference$, this.model);
        this.setupDisabled();
    }

    private setupDisabled() {
        if (this.disabled == true) {
            this.textForm?.form?.disable();
            return;
        }
        this.textForm?.form?.enable();
    }

    public save() {
        this._save(this.textForm);
    }

    private _save(form: NgForm) {
        this.action.submitted = true;
        if (!this._validateForm(form)) {
            return;
        }

        let clone = this.model.clone();
        clone.mediaHashTagName = this.textMapperService.getHashTagName(clone.mediaHashTagIds, this.mediaHashTagSelect2Data);
        clone.mediaUseName = this.textMapperService.getMediaUseName(clone.mediaUseCode, this.mediaUseReference$);
        clone.mediaTypeName = this.textMapperService.getMediaTypeName(clone.mediaTypeCode, this.mediaTypeReference$);
        clone.languageName = this.textMapperService.getLanguageName(clone.languageCode, this.languageReference$);

        this.onSave.emit(clone);
    }

    public saveAs() {
        this._saveAs(this.textForm);
    }

    private _saveAs(form: NgForm) {
        this.action.submitted = true;
        if (!this._validateForm(form)) {
            return;
        }
        if (this.model.mediaId) {
            let mediaId = this.model.mediaId;
            delete this.model.mediaId;
            delete this.model.mediaMediaUseId;
            delete this.model.mediaContentId;
            this.copyNewTranslation(mediaId);
        }

        this._save(form);
    }

    public validateForm(): boolean {
        this.action.submitted = true;
        return this._validateForm(this.textForm);
    }

    private _validateForm(form: NgForm): boolean {
        if (!form.valid || !this.model.mediaHashTagIds?.length) {
            this.changeDectionRef.detectChanges();
            return false;
        }
        return true;
    }

    private copyNewTranslation(mediaId: string) {
        if (this.model.mediaTranslationId) {
            this.dialogService.confirm({
                title: '',
                message: '<h4>Copy the translations?</h4>',
                buttons: {
                    confirm: {
                        label: 'Yes',
                        className: 'btn-danger shadow-0'
                    },
                    cancel: {
                        label: '',
                        className: ''
                    },
                    no: {
                        label: 'No',
                        className: 'btn-default'
                    },
                    close: {
                        label: '',
                        className: ''
                    }
                }
            }, "modal-sm", false).subscribe(
                (response: boolean) => {
                    this.model.mediaTranslationId = mediaId;
                    if (response != true) {
                        this.notCopyTextTranslation.emit(mediaId);
                    }
                }
            )
        }
    }

    public cancel(form: NgForm) {
        this.action.submitted = false;
        this.htmlChange = false;
        this.onCancel.emit();
        this.model = new TextView();
        this.textMapperService.setDefaultPrimaryLanguage(this.languageReference$, this.model);
        this.mediaTypeReferenceFilter$ = new BehaviorSubject<MediaTypeReferenceModel[]>(null);
        this.showBrowse = true;
        form.resetForm(this.model);
    }

    public isSaveDisable(form: NgForm): boolean {
        if (this.model.selectedFromLibrary) {
            return false;
        }
        if (this.htmlChange) {
            return false;
        }
        if (!this.formTouched(form)) {
            return true;
        }
        else {
            return false;
        }
    }

    public isCancelDisable(form: NgForm): boolean {
        if (this.singleRecord && !this.formTouched(form)) {
            return true;
        }
        return false;
    }

    private formTouched(form: NgForm): boolean {
        return (form.touched || this.action.mediaHashTag);
    }

    public mediaHashTagSelect() {
        this.action.mediaHashTag = true;
    }

    public validFromChange(value) {
        this.model.validFrom = value;
    }

    public validToChange(value) {
        this.model.validTo = value;
    }

    public isTextOnly() {
        if (this.mediaTypeReference$.value) {
            var filter = this.mediaTypeReference$.value.filter(x => x.mediaTypeCode == this.model.mediaTypeCode);
            if (filter.length > 0) {
                return !filter[0].textOnlyFlag;
            }
        }
    }

    public browseLibraryClick() {
        if (this.validateClickBrowse()) {
            this.action.browsed = false;
            this.mediaSearchCommand = this.textMapperService.mediaTextToSearchCommand(this.model);
            this.browseLibrary = !this.browseLibrary;
            this.onClickMediaLibrary.emit(this.mediaSearchCommand);
        }
        else {
            this.action.browsed = true;
        }
    }

    private validateClickBrowse(): boolean {
        return !!(this.model.mediaUseCode);
    }

    public cancelBrowse() {
        this.browseLibrary = false;
    }

    public saveMediaBrowse() {
        this.browseLibrary = false;
        let mediaTextLibrarySelected = this.textLibraryTableComponent.mediaTextLibrarys.filter(x => x.selected == true);
        let viewTexts = this.textMapperService.mediaTextLibraryToMediaTextViews(mediaTextLibrarySelected);
        this.onSaveMediaLibrary.emit(viewTexts);
    }

    htmlEditorChange() {
        this.htmlChange = true;
    }

    editMode(): boolean {
        if (this.model.mediaId) {
            return true;
        }
        return false;
    }

    public mediaUseChange(value) {
        this.filterMediaType(value);
    }

    private filterMediaType(mediaUseCode) {
        if (this.mediaTypeReference$.value) {
            this.mediaTypeReferenceFilter$ = new BehaviorSubject<MediaTypeReferenceModel[]>(null);
            if (mediaUseCode) {
                var filter = this.mediaTypeReference$.value.filter(x => x.mediaUseCode == mediaUseCode);
                this.mediaTypeReferenceFilter$.next(filter);
                if (filter.length != 0) {
                    this.setBrowseEnabled(true);
                    this.model.mediaTypeCode = filter[0].mediaTypeCode;
                }
                else {
                    this.clearMediaType();
                }
            }
            else {
                this.clearMediaType();
                this.setBrowseEnabled(false);
            }
        }
    }

    private setBrowseEnabled(enabled: boolean) {
        this.browseEnabled = enabled;
        this.onBrowseEnabledChanged.emit(enabled);
    }

    private clearMediaType() {
        this.model.mediaTypeCode = null;
        this.model.mediaTypeName = null;
    }

    private imageBtn(context) {
        var ui = $.summernote.ui;
        var btnList = ui.buttonGroup([
            ui.button({
                className: 'dropdown-toggle',
                contents: '<span class="note-icon-picture"></span> <span class="caret"></span>',
                tooltip: "Image",
                container: '.note-editor',
                data: {
                    toggle: 'dropdown'
                }
            }),
            ui.dropdown({
                className: 'dropdown-style',
                contents: `<li style="list-style-type: none;cursor: pointer;"><a id = "imgUploload" class="note-dropdown-item" data-value="p" role="listitem" aria-label="p"><p>Upload from computer</p></a></li>
                               <li style="list-style-type: none;cursor: pointer;"><a id = "imgSelectLibrary" class="note-dropdown-item"  data-value="p" role="listitem" aria-label="p"><p>Select from library</p></a></li>`,
                callback: function ($dropdown) {
                    $dropdown.find('a').each(function () {
                        $(this).click(function () {
                            context.invoke('editor.restoreRange');
                            context.invoke('editor.pasteHTML', thisContext.TAG_INSERT_IMAGE);
                            if (this.id == "imgUploload") {
                                thisContext.imageUploadFromComputerClick();
                            }
                            else {
                                thisContext.imageSelectFromLibraryClick();
                            }
                        });
                    });
                }
            })
        ]);
        return btnList.render();
    }

    private videoBtn(context) {
        var ui = $.summernote.ui;
        var btnList = ui.buttonGroup([
            ui.button({
                className: 'dropdown-toggle',
                contents: '<span class="note-icon-video"></span> <span class="caret"></span>',
                tooltip: "Video",
                container: '.note-editor',
                data: {
                    toggle: 'dropdown'
                }
            }),
            ui.dropdown({
                className: 'dropdown-style',
                contents: `<li style="list-style-type: none;cursor: pointer;"><a id = "videoUploload" class="note-dropdown-item video" data-value="p" role="listitem" aria-label="p"><p>Upload from computer</p></a></li>
                               <li style="list-style-type: none;cursor: pointer;"><a id = "videoSelectLibrary" class="note-dropdown-item"  data-value="p" role="listitem" aria-label="p"><p>Select from library</p></a></li>`,
                callback: function ($dropdown) {
                    $dropdown.find('a').each(function () {
                        $(this).click(function () {
                            context.invoke('editor.restoreRange');
                            context.invoke('editor.pasteHTML', thisContext.TAG_INSERT_VIDEO);
                            if (this.id == "videoUploload") {
                                thisContext.videoUploadFromComputerClick();
                            }
                            else {
                                thisContext.videoSelectFromLibraryClick();
                            }
                        });
                    });
                }
            })
        ]);
        return btnList.render();
    }

    public imageUploadFromComputerClick() {
        this.onClickImageUpload.emit();
    }

    public imageSelectFromLibraryClick() {
        let searchMediaCommand = this.textMapperService.mediaTextToSearchCommand(this.model);
        searchMediaCommand.mediaTypeCode = "IMAGE";
        searchMediaCommand.mediaUseCode = "PRODUCTGALL";
        searchMediaCommand.isMediaText = false;
        searchMediaCommand.isMediaLibrary = true;
        this.onClickImageSelectLibrary.emit(searchMediaCommand);
    }

    public videoUploadFromComputerClick() {
        this.onClickVideoUpload.emit();
    }

    public videoSelectFromLibraryClick() {
        let searchMediaCommand = this.textMapperService.mediaTextToSearchCommand(this.model);
        searchMediaCommand.mediaTypeCode = "VIDEO";
        searchMediaCommand.mediaUseCode = "PRODUCTVIDEO";
        searchMediaCommand.isMediaText = false;
        searchMediaCommand.isMediaLibrary = true;
        this.onClickVideoSelectLibrary.emit(searchMediaCommand);
    }

    public mediaImageUploadCompleted(mediaContentId: string) {
        let urlMedia = this.mediaFilePreviewService.getURLMediaAzureStorage(mediaContentId);
        let imageTag = "<img src = '" + urlMedia + "' />";
        this.model.text = this.model.text.replace(this.TAG_INSERT_IMAGE, imageTag);
        this.changeDectionRef.detectChanges();
    }

    public mediaVideoUploadCompleted(mediaContentId: string) {
        let urlMedia = this.mediaFilePreviewService.getURLMediaAzureStorage(mediaContentId);
        let videoTag = "<iframe frameborder='0' src = '" + urlMedia + "' width='640' height='360' class='note-video-clip'></iframe>";
        this.model.text = this.model.text.replace(this.TAG_INSERT_VIDEO, videoTag);
        this.changeDectionRef.detectChanges();
    }

    public mediaUploadCancel() {
        if (this.model.text) {
            this.model.text = this.model.text.replace(this.TAG_INSERT_IMAGE, '').replace(this.TAG_INSERT_VIDEO, '');
        }
        this.changeDectionRef.detectChanges();
    }

    public mediaImageSelectLibraryCompleted(mediaContentIds: string[]) {
        var imageTags: string = '';
        for (let mediaContentId of mediaContentIds) {
            let urlMedia = this.mediaFilePreviewService.getURLMediaAzureStorage(mediaContentId);
            let imageTag = "<img src = '" + urlMedia + "' /><br/>";
            imageTags += imageTag;
        }
        this.model.text = this.model.text.replace(this.TAG_INSERT_IMAGE, imageTags);
        this.changeDectionRef.detectChanges();
    }

    public mediaVideoSelectLibraryCompleted(mediaContentIds: string[]) {
        var videoTags: string = '';
        for (let mediaContentId of mediaContentIds) {
            let urlMedia = this.mediaFilePreviewService.getURLMediaAzureStorage(mediaContentId);
            let videoTag = "<iframe frameborder='0' src = '" + urlMedia + "' width='640' height='360' class='note-video-clip'></iframe><br/>";
            videoTags += videoTag;
        }
        this.model.text = this.model.text.replace(this.TAG_INSERT_VIDEO, videoTags);
        this.changeDectionRef.detectChanges();
    }

    public mediaSelectLibraryCancel() {
        this.model.text = this.model.text.replace('[image/]', '').replace('[video/]', '');
        this.changeDectionRef.detectChanges();
    }

    get mediaHashTagIds(): string | string[] {
        return this.model.mediaHashTagIds;
    }

    set mediaHashTagIds(value: string | string[]) {
        this.model.mediaHashTagIds = <string[]>value;
    }

    get mediaUseCodes(): string | string[] {
        return this.model.mediaUseCode;
    }

    set mediaUseCodes(value: string | string[]) {
        this.model.mediaUseCode = <string>value;
    }

    get mediaTypeCodes(): string | string[] {
        return this.model.mediaTypeCode;
    }

    set mediaTypeCodes(value: string | string[]) {
        this.model.mediaTypeCode = <string>value;
    }
    
    get languageCodes(): string | string[] {
        return this.model.languageCode;
    }

    set languageCodes(value: string | string[]) {
        this.model.languageCode = <string>value;
    }
}