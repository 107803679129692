import { AfterViewInit, ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, Output, ViewChild } from "@angular/core";
import { OrderDetailIndividualDocumentView } from "../../..";
import { cloneDeep } from 'lodash';
import * as moment from 'moment';
import { NgForm } from "@angular/forms";
import { DocumentTypeSelectionComponent } from "src/app/core/components/document-type-selection/document-type-selection.component";
import { filter, takeUntil } from "rxjs/operators";
import { Subject } from "rxjs";
import { CountrySelectionComponent } from "src/app/core/components/country-selection/country-selection.component";

@Component({
    selector: 'op-order-detail-individual-document-form',
    templateUrl: './order-detail-individual-document-form.component.html'
})
export class OrderDetailIndividualDocumentFormComponent implements AfterViewInit, OnDestroy {
    @Input('data') set data(view: OrderDetailIndividualDocumentView) {
        this.view = cloneDeep(view);
    }

    @Output() apply = new EventEmitter<OrderDetailIndividualDocumentView>();
    @Output() close = new EventEmitter<void>();
    @Output() undo = new EventEmitter();

    @ViewChild(NgForm) form: NgForm;
    @ViewChild(DocumentTypeSelectionComponent) documentTypeSelectionComponent: DocumentTypeSelectionComponent;
    @ViewChild(CountrySelectionComponent) countrySelectionComponent: CountrySelectionComponent;

    focused: boolean = false;
    processing: boolean = false;
    view: OrderDetailIndividualDocumentView;
    issueDateOption: any;
    expiryDateOption: any;
    
    private readonly ISSUE_DATE_OPTION = {
        singleDatePicker: true,
        showDropdowns: true,
        autoApply: true,
        autoUpdateInput: false,
        startDate: moment(),
        endDate: moment(),
        maxDate: new Date(),
        locale: {
            cancelLabel: 'Clear',
            format: 'YYYY-MM-DD'
        },
        isCustomDate: null
    }
    
    private readonly EXPIRY_DATE_OPTION = {
        singleDatePicker: true,
        showDropdowns: true,
        autoApply: true,
        autoUpdateInput: false,
        startDate: moment(),
        endDate: moment(),
        locale: {
            cancelLabel: 'Clear',
            format: 'YYYY-MM-DD'
        },
        isCustomDate: null
    }

    private destroy$ = new Subject();

    constructor(private changeDetector: ChangeDetectorRef) {
        this.issueDateOption = cloneDeep(this.ISSUE_DATE_OPTION);
        this.expiryDateOption = cloneDeep(this.EXPIRY_DATE_OPTION);
    }

    ngAfterViewInit(): void {
        this.checkIfErrorPresist();
    }

    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
    }

    onUndoClick() {
        this.undo.emit();
    }
    onApplyClick() {
        this.processing = true;
        if (!this.form.valid) {
            return;
        }

        this.view.countryName = this.countrySelectionComponent.getSelectedText();
        this.view.identityDocumentName = this.documentTypeSelectionComponent.getSelectedText();
        this.apply.emit(this.view);
    }
    onClearClick() {
        this.resetInputFields();
    }
    onCloseClick() {
        this.close.emit();
    }
    private resetInputFields() {
        this.view = this.getEmptyView();
    }
    private getEmptyView(): OrderDetailIndividualDocumentView {
        return {
            identityDocumentId: null,
            identityDocumentCode: null,
            identityDocumentName: null,
            identityDocumentNumber: null,
            countryCode: null,
            countryName: null,
            expiryDate: null,
            lastName: null,
            firstName: null,
            title: null,
            allowedEdit: null,
            showDetail: null,
            individualId: null,
            orderId: null,
            partnerOrderId: null,
            issueDate: null,
            placeOfIssue: null,
            orderSalesStatusCode: null
        }
    }
    private checkIfErrorPresist() {
        this.form.valueChanges
            .pipe(
                filter(_ => this.processing == true),
                takeUntil(this.destroy$)
            )
            .subscribe(_ => this.changeDetector.markForCheck());
    }
}