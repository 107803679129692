import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AncillaryServiceDetailComponent } from './ancillary-service-detail.component';
import { GeneralInformationComponent } from './general-information/general-information.component';
import { TreeComponent } from './tree/tree.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FocusingModule } from 'src/app/shared/ui/forms/inputs/focusing.module';
import { MatIconModule } from '@angular/material/icon';
import { PanelsModule } from 'src/app/shared/panels/panels.module';
import { Select2Module } from 'src/app/shared/ui/forms/inputs/oops-select2/select2.module';
import { UiModule } from 'src/app/shared/ui/ui.module';
import { TreeModule } from 'primeng/tree';
import { NgbNavModule } from '@ng-bootstrap/ng-bootstrap';
import { CounterTextModule } from 'src/app/core/pipes/counter-texts/counter-text.module';
import { AngularResizeEventModule } from 'angular-resize-event-package';
import { LoadingSpinnerModule } from 'src/app/shared/layout/loading-spinner';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { RouterModule } from '@angular/router';
import { DxTreeListModule, DxCheckBoxModule } from 'devextreme-angular';
import { FreezingTabModule } from 'src/app/shared/ui/forms/inputs/freezing-tab/freezing-tab.module';
import { TranslationNameModule } from 'src/app/core/components/translation-name/translation-name.module';
import { TranslationMediaModule } from 'src/app/core/components/translation-media/translation-media.module';
import { TranslationTextModule } from 'src/app/core/components/translation-text/translation-text.module';
import { AncillaryServiceAttributeModule } from '../ancillary-service-attribute/ancillary-service-attribute.module';
import { TextMediaModule } from 'src/app/core/components/text-media/text-media.module';
import { AlertBarModule } from 'src/app/shared/layout/alertbar';
import { ActivityDomainModule } from 'src/app/core/components/activity-domain/activity-domain.module';

@NgModule({
    declarations: [
        GeneralInformationComponent,
        AncillaryServiceDetailComponent,
        TreeComponent,  
    ],
    imports: [
        ActivityDomainModule,
        AlertBarModule,
        AngularResizeEventModule,
        BsDropdownModule,
        CommonModule,
        CounterTextModule,
        DxCheckBoxModule,
        DxTreeListModule,
        FocusingModule,
        FormsModule,
        FreezingTabModule,
        LoadingSpinnerModule,
        MatIconModule,
        NgbNavModule,
        PanelsModule,
        ReactiveFormsModule,
        RouterModule,
        Select2Module,
        AncillaryServiceAttributeModule,
        TextMediaModule,
        TranslationMediaModule,
        TranslationNameModule,
        TranslationTextModule,
        TreeModule,
        UiModule,
    ],
    exports: [
        AncillaryServiceDetailComponent
    ]
})
export class AncillaryServiceDetailModule { }
