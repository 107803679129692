import { EventEmitter, Input, OnDestroy, Output, QueryList, ViewChildren, OnInit, Component } from '@angular/core';
import { NgForm } from '@angular/forms';
import { BehaviorSubject, combineLatest, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { DomainAttributeModel } from 'src/app/core/models/reference-model/reference-general-model';
import { AttributeChoiceModel } from 'src/app/core/models/reference-model/reference-general-model/attribute-choice.model';
import {
    ProductGroupReferenceModel,
    ProductTypeGroupModel
} from 'src/app/core/models/reference-model/reference-product-model';

import {
    DomainAttributeService,
    ProductTypeGroupService
} from 'src/app/core/services/airline-services';
import { ProductService } from 'src/app/core/services/product-services/product.service';
import { PricingDetailConstant } from '../../../shared/pricing-detail.constant';
import { Select2OptionsService } from '../../product-eligible-restricted/shared/select2-options.service';
import { 
    PriceRuleAttributeAndRuleRowView,
    PriceRuleAttributeView, 
    PriceRuleProductView 
} from '../../product-eligible-restricted/shared/views';
import { AttributeAndRuleBase } from '../../shared/attribute-and-rule.base';
import { Select2Option } from '../../shared/attribute-and-rule/views/select2.option';
import { DateConverterService } from 'src/app/core/utils/date-converter.service';
import { dateOption, dateTimeOption, timeOption } from 'src/app/shared/ui/forms/inputs/oops-choice-value/oops-choice-value-configuration';
import { cloneDeep } from "lodash";
import { Select2Data } from 'src/app/shared/ui/forms/inputs/oops-select2';

@Component({
    template: ''
})
export abstract class AttributeBase extends AttributeAndRuleBase implements OnInit, OnDestroy {
    abstract title: string;
    abstract priceRuleAttributeGroupCode: string;
    abstract priceRuleTypeCode: string;
    abstract focusing: boolean;

    public testMultiples = ['d5a802b8-f9c9-4837-85a5-2b9cf8424195', 'e7fbc29c-2c12-454f-a9ee-3791179012b4'];

    public readonly TIME_ONLY_FORMAT = 'HH:mm';
    
    public blurAfterSelected: boolean = true;

    public select2AttributeOption = new Select2Option("<Type>");
    public select2ChoiceOption = new Select2Option();
    public select2MultiChoiceOption = new Select2Option();
    public select2ProductNameOption = new Select2Option('<Product Name>');
    public daterangepickerTimeOption = cloneDeep(timeOption);
    public daterangepickerDateTimeOption = cloneDeep(dateTimeOption);
    public daterangepickerDateOption = cloneDeep(dateOption);

    public rows$ = new BehaviorSubject<PriceRuleAttributeAndRuleRowView[]>(null);
    public domainAttributes$ = new BehaviorSubject<DomainAttributeModel[]>(null);
    public productTypeReferences$ = new BehaviorSubject<ProductTypeGroupModel[]>(null);
    public products$ = new BehaviorSubject([]);

    public productGroupReferences: ProductGroupReferenceModel[];

    @Input() searchMode: boolean = true;
    @Input() priceRuleId: string;
    @Input() set data(val: PriceRuleAttributeAndRuleRowView[]) {
        if (val?.length) {
            for (let row of val) {
                row.attribute.attributeTime = this.dateConverterService.convertDateStringToFormat(row.attribute.attributeDateTime, this.dateConverterService.DATETIME_FORMAT_24, this.dateConverterService.TIME_FORMAT_24);
            }
            this.rows$.next(val);
            this.setPreSelectedItems();
        }
    }
    @Input() disabled: boolean = false;
    @Output() dataChange = new EventEmitter();
    @ViewChildren(NgForm) forms: QueryList<NgForm>;

    private unsubscribe$ = new Subject();

    get domainAttributes(): DomainAttributeModel[][] {
        let result = new Array<Array<DomainAttributeModel>>();
        let rows = this.rows$.value;
        if (rows?.length) {
            for (let row of rows) {
                let otherAttributeTypeCodes = rows.map(r => r.attribute?.attributeTypeCode)
                    .filter(r => r != row.attribute?.attributeTypeCode);
                let domainAttrs = this.domainAttributes$.value?.filter(da => otherAttributeTypeCodes.includes(da.attributeTypeCode) == false);
                result.push(domainAttrs);
            }
        }
        return result;
    }

    constructor(
        private domainAttributeService: DomainAttributeService,
        private select2OptionsService: Select2OptionsService,
        private productTypeGroupService: ProductTypeGroupService,
        private productService: ProductService,
        private dateConverterService: DateConverterService) {
        super();
    }

    ngOnDestroy(): void {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }


    ngOnInit(): void {
        this.select2ChoiceOption = this.select2OptionsService.getSelect2ConditionOption();
        this.select2MultiChoiceOption = this.select2OptionsService.getSelect2MultiChoickOption();
        this.getDomainAttributes();
        this.getProductTypeReferences();
    }

    add() {
        if (this.validForms() && this.noDuplicatedRowFound()) {
            this.addErrorFlag = false;
            let rows = this.rows$.value ?? [];
            rows.push(this.createNewRow());
            this.rows$.next(rows);
        } else {
            this.addErrorFlag = true;
        }
        this.togglePanelCollapseStatus(false);
    }

    private createNewRow(): PriceRuleAttributeAndRuleRowView {
        let row = new PriceRuleAttributeAndRuleRowView();
        row.attribute = new PriceRuleAttributeView();
        return row;
    }

    private getDomainAttributes() {
        this.domainAttributeService
            .getPriceRuleAttributes(this.priceRuleTypeCode ?? '', this.priceRuleAttributeGroupCode, '')
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe(
                res => {
                    let domainAttributes = res;
                    if (this.searchMode) {
                        domainAttributes = domainAttributes.filter(da => da.searchFlag == true);
                    }
                    this.domainAttributes$.next(domainAttributes);
                    const choices = domainAttributes?.find(d => d.attributeTypeCode == 'FORMOFPAYMENT')?.attributeChoices;
                    this.addAttributesOnNew();
                    this.updateAttributeChoiceList();
                }
            )
    }

    private getDomainAttributeByCode(attributeTypeCode: string): DomainAttributeModel {
        return this.domainAttributes$.value.find(attr => attr.attributeTypeCode == attributeTypeCode);
    }

    private addAttributesOnNew() {
        let rows = this.rows$.value ?? [];
        if (this.searchMode) {
            this.rows$.next([]);
        } else if (!rows?.length && !this.priceRuleId) {
            for (let attrType of this.domainAttributes$.value) {
                if (attrType.showOnNewFlag == true && !this.priceRuleId) {
                    let row = this.createNewRow();
                    row.attribute.attributeTypeCode = attrType.attributeTypeCode;
                    this.onAttributeChange(row, attrType.attributeTypeCode);
                    rows.push(row);
                }
            }
            this.rows$.next(rows)
        }
        this.togglePanelCollapseStatus(false);
    }

    public getChoices(attributeTypeCode: string) {
        if (!this.domainAttributes$.value || !attributeTypeCode) {
            return [];
        }
        let priceRuleAttributeType = this.getDomainAttribute(attributeTypeCode);
        return priceRuleAttributeType ? priceRuleAttributeType.attributeChoices : [];
    }

    public getMultipleChoices(attributeTypeCode: string){
        if (!this.domainAttributes$.value || !attributeTypeCode) {
            return [];
        }
        let priceRuleAttributeType = this.getDomainAttribute(attributeTypeCode);
        let test = priceRuleAttributeType ? priceRuleAttributeType.attributeChoices : [];

        return test.map(t => new Select2Data(t.attributeChoiceId, t.attributeChoiceName));
    }

    private getDomainAttribute(attributeTypeCode: string) {
        return this.domainAttributes$.value.find(at => at.attributeTypeCode == attributeTypeCode);
    }

    public onAttributeChange(row: PriceRuleAttributeAndRuleRowView, event) {
        row.attribute.attributeChoiceId = null;
        let attribute = this.domainAttributes$.value
            .filter(at => at.attributeTypeCode == event)[0];
        this.setDefaultChoice(attribute, row);
        row.attribute.attributeTypeCode = event;
    }

    private setDefaultChoice(attribute: DomainAttributeModel, row: PriceRuleAttributeAndRuleRowView) {
        if (attribute) {
            if (attribute.multipleChoiceFlag) {
                this.setDefaultChoiceForMultipleChoice(attribute, row);
                return;
            }

            let choices = attribute.attributeChoices;
            if (choices?.length) {
                let defaultChoice = choices.filter(c => c.defaultFlag == true)[0];
                if (defaultChoice) {
                    row.attribute.attributeChoiceId = defaultChoice.attributeChoiceId;
                } else {
                    row.attribute.attributeChoiceId = choices[0].attributeChoiceId;
                }
            }
        }
    }

    private setDefaultChoiceForMultipleChoice(attribute: DomainAttributeModel, row: PriceRuleAttributeAndRuleRowView) {
        let choices = attribute.attributeChoices;
        if (!choices?.length) {
            return;
        }
        row.attribute.attributeOptionList = attribute.attributeChoices.map(t => new Select2Data(t.attributeChoiceId, t.attributeChoiceName));
        let defaultChoices = choices.filter(c => c.defaultFlag == true);
        
        row.attribute.attributeMultiChoiceIds = [];
        if (!defaultChoices?.length) {
            row.attribute.attributeMultiChoiceIds.push(choices[0]?.attributeChoiceId);
            return;
        }

        for (let defaultChoice of defaultChoices) {
            row.attribute.attributeMultiChoiceIds.push(defaultChoice.attributeChoiceId);
        }
        
    }

    public onChoiceChange(row: PriceRuleAttributeAndRuleRowView, event) {
        row.attribute.attributeChoiceId = event;
    }

    public onMultipleChoiceChange(row: PriceRuleAttributeAndRuleRowView, event) {
        if (!event) {
            row.attribute.attributeMultiChoiceIds = null;
            return;
        }

        for (let e of event) {
            if (row.attribute.attributeMultiChoiceIds.find(x => x == e)) {
                continue;
            }

            row.attribute.attributeMultiChoiceIds.push(e);
        }
    }

    private createPriceRuleProduct(attributeTypeCode: string): PriceRuleProductView {
        let priceRuleProduct = new PriceRuleProductView();
        priceRuleProduct.productTypeCode = this.getProductTypeCode(attributeTypeCode);
        priceRuleProduct.productGroupCode = this.getProductGroupCode(attributeTypeCode);
        priceRuleProduct.productCategoryCode = PricingDetailConstant.ATTRIBUTE_PRODUCT_CATEGORY_CODE;
        priceRuleProduct.excludeFlag = false;
        return priceRuleProduct;
    }

    public isProductIncluded(attributeTypeCode: string, attributeChoiceId: string): boolean {
        if (this.domainAttributes$.value?.length) {
            let domainAttribute = this.getDomainAttribute(attributeTypeCode);
            if (domainAttribute) {
                let choice = this.getAttributeChoice(domainAttribute, attributeChoiceId);
                if (choice) {
                    return this.isChoiceHaveProductIncluded(domainAttribute.attributeTypeCode, choice.attributeChoiceCode);
                }
            }
        }
        return false;
    }

    private isChoiceHaveProductIncluded(attributeTypeCode: string, priceRuleAttributeChoiceCode): boolean {
        return PricingDetailConstant.PRICE_RULE_ATTRIBUTE_TYPE_CODES_PRODUCT_INCLUDED
            .find(attr => attr.attributeTypeCode == attributeTypeCode) &&
            PricingDetailConstant.PRICE_RULE_ATTRIBUTE_CHOICE_CODES_TO_SHOW_PRODUCT.includes(priceRuleAttributeChoiceCode);
    }

    private getAttributeChoice(domainAttribute: DomainAttributeModel, attributeChoiceId: string): AttributeChoiceModel {
        return domainAttribute.attributeChoices.find(c => c.attributeChoiceId == attributeChoiceId);
    }

    public attemptToSubmit() {
        if (this.validForms() && this.noDuplicatedRowFound()) {
            this.addErrorFlag = false;
            this.setAttributeDateTimeAndDateBrforeSaveData();
            this.dataChange.emit(this.rows$.value);
            return true;
        } else {
            this.addErrorFlag = true;
            return false;
        }
    }

    private setAttributeDateTimeAndDateBrforeSaveData() {
        for (let row of this.rows$.value) {
            if (this.domainAttributes$.value.find(x => x.attributeTypeCode == row.attribute.attributeTypeCode).dimensionUnitCode == "DATETIME") {
                row.attribute.attributeTime = null;
            }
        }
    }

    private setPreSelectedItems() {
        this.productTypeReferences$.pipe(takeUntil(this.unsubscribe$)).subscribe(
            (values) => {
                if (values?.length) {
                    for (let row of this.rows$.value) {
                        if (row.attribute?.products?.length) {
                            this.getProducts(row.attribute.attributeTypeCode,
                                this.getProductGroupCode(row.attribute.attributeTypeCode),
                                this.getProductTypeCode(row.attribute.attributeTypeCode))
                        }
                    }
                }
            }
        )
    }

    private getProducts(attributeTypeCode: string, productGroupCode: string, productTypeCode: string) {
        this.productService.getBy(productGroupCode, productTypeCode, 'DATA')
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe(
                res => {
                    if (res?.length) {
                        let products = this.products$.value;
                        products[attributeTypeCode] = res;
                        this.products$.next(products)
                    }
                }
            )
    }

    private getProductTypeCode(attributeTypeCode: string) {
        return this.getProductTypeGroupByPriceRuleAttributeTypeId(attributeTypeCode)?.productTypeCode;
    }

    private getProductGroupCode(attributeTypeCode: string) {
        return this.getProductTypeGroupByPriceRuleAttributeTypeId(attributeTypeCode)?.productGroupCode;
    }

    private getProductTypeGroupByPriceRuleAttributeTypeId(attributeTypeCode: string): ProductTypeGroupModel {
        var priceRuleAttributeType = this.getDomainAttributeByCode(attributeTypeCode)
        var priceRuleAttributeTypeProductIncluded = this.getPriceRuleAttributeTypeProductIncluded(priceRuleAttributeType);
        return this.getProductTypeGroup(this.productTypeReferences$.value, priceRuleAttributeTypeProductIncluded.productGroupCode, priceRuleAttributeTypeProductIncluded.productTypeCode);
    }

    private getPriceRuleAttributeTypeProductIncluded(domainAttribute: DomainAttributeModel) {
        return PricingDetailConstant.PRICE_RULE_ATTRIBUTE_TYPE_CODES_PRODUCT_INCLUDED
            .find(prat => prat.attributeTypeCode == domainAttribute.attributeTypeCode)
    }

    private getProductTypeReferences() {
        combineLatest(
            PricingDetailConstant.PRICE_RULE_ATTRIBUTE_TYPE_CODES_PRODUCT_INCLUDED.map(
                item => this.productTypeGroupService.getProductTypeGroupByProductGroupCode(item.productGroupCode)
            )
        )
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe(
                (responses: ProductTypeGroupModel[][]) => {
                    this.productTypeReferences$.next(this.extractProductTypeReferences(responses));
                }
            )
    }

    private extractProductTypeReferences(responses: ProductTypeGroupModel[][]) {
        let productTypeReferences = [];
        for (let item of PricingDetailConstant.PRICE_RULE_ATTRIBUTE_TYPE_CODES_PRODUCT_INCLUDED) {
            for (let res of responses) {
                let productTypeReference = this.getProductTypeGroup(res, item.productGroupCode, item.productTypeCode);
                if (productTypeReference) {
                    productTypeReferences.push(productTypeReference);
                    break;
                }
            }
        }
        return productTypeReferences;
    }

    private getProductTypeGroup(productTypeGroups: ProductTypeGroupModel[], productGroupCode: string, productTypeCode: string): ProductTypeGroupModel {
        return productTypeGroups.find(pt => pt.productGroupCode == productGroupCode && pt.productTypeCode == productTypeCode);
    }

    public isMultipleSelect2(row: PriceRuleAttributeAndRuleRowView): boolean {
        let multipleChoiceFlag = this.domainAttributes$.value.find(x => x.attributeTypeCode == row.attribute.attributeTypeCode)?.multipleChoiceFlag;

        if (multipleChoiceFlag) {
            row.attribute.multipleChoiceFlag = true;
            return true;
        }

        row.attribute.multipleChoiceFlag = false;
        return false;
    }

    public checkDimentionTypeCondition(attributeTypeCode: string, typeCode: string) {
        if (!this.domainAttributes$.value) {
            return false;
        }

        let dimensionUnitCode = this.domainAttributes$.value.find(x => x.attributeTypeCode == attributeTypeCode)?.dimensionUnitCode;
        return dimensionUnitCode == typeCode;
    }

    public getTimeFromAttributeDateTime(attributeDateTime: Date): string {
        return this.dateConverterService.convertTime24(attributeDateTime)
    }

    public timeChangeProcess(row: PriceRuleAttributeAndRuleRowView, e) {
        row.attribute.attributeDateTime = null;
        row.attribute.attributeTime = e;
    }

    public dateTimeChangeProcess(row: PriceRuleAttributeAndRuleRowView, e) {
        row.attribute.attributeTime = null
        row.attribute.attributeDateTime = e;
    }

    public onTextChange(row: PriceRuleAttributeAndRuleRowView, e) {
        row.attribute.attributeText = e;
    }

    isSelected(id: string): boolean {
        return this.testMultiples.includes(id);
    }

    private updateAttributeChoiceList() {
        if (!this.domainAttributes$.value) {
            return [];
        }
        
        for (let row of this.rows$.value) {
            if (row.attribute?.attributeTypeCode) {
                let attributeTypeCode = row.attribute?.attributeTypeCode;
                let priceRuleAttributeType = this.getDomainAttribute(attributeTypeCode);
                let optionList = priceRuleAttributeType ? priceRuleAttributeType.attributeChoices : [];

                row.attribute.attributeOptionList = optionList.map(t => new Select2Data(t.attributeChoiceId, t.attributeChoiceName));
            }
        }
    }
}