import { Injectable, Type } from '@angular/core';

import { Store } from '@ngrx/store';
import { NavigationItems } from 'src/app/app.navigation';

import { TabModel } from '../../core/models/tab';
import {
    addTab,
    deleteAllTab,
    deleteTab,
    setActiveTab,
    setTabName,
    setTabUrl,
    TabState,
} from '../../store/tab';
import {
    addTabSession,
    changeNavigateUrl,
    deleteAllTabSession,
    deleteTabSession,
    TabSessionState
} from '../../store/tab-session';

@Injectable({
    providedIn: 'root'
})
export class TabService {

    constructor(private tabStore: Store<TabState>,
        private tabSessionStore: Store<TabSessionState>) { }

    public add(id: string, name: string, url: string, component: string | Type<any>): void {
        if (!url) {
            return;
        }
        const navigationSetting = NavigationItems.find(n => n.displayName === name);
        
        let addedTab = {
            id,
            active: true,
            component,
            name,
            url,
            disableCloseTab: navigationSetting?.disableCloseTab,
            domainCode: navigationSetting?.id
        };
        let addedTabSession = {
            id,
            name,
            baseUrl: url,
            navigateUrl: url,
            disableCloseTab: navigationSetting?.disableCloseTab,
            domainCode: navigationSetting?.id
        };
        this.tabStore.dispatch(addTab(addedTab));
        if (!navigationSetting?.openOnLoad) {
            this.tabSessionStore.dispatch(addTabSession(addedTabSession));
        }
        this.setSelectedTab(addedTab);
    }
    
    public delete(tab: TabModel): void {
        this.tabStore.dispatch(deleteTab(tab));
        let tabSession = {
            id: tab.id,
            name: tab.name,
            baseUrl: tab.url,
            navigateUrl: tab.url,
            disableCloseTab: tab.disableCloseTab,
            domainCode: tab.domainCode
        };
        this.tabSessionStore.dispatch(deleteTabSession(tabSession))
    }
    public setSelectedTab(selectedTab: TabModel) {
        this.tabStore.dispatch(setActiveTab(selectedTab));
    }
    public setChangedTabUrl(selectedTab: TabModel) {
        let tabSession = {
            id: selectedTab.id,
            name: null,
            baseUrl: null,
            navigateUrl: selectedTab.url,
            disableCloseTab: selectedTab.disableCloseTab,
            domainCode: selectedTab.domainCode
        };
        this.tabStore.dispatch(setTabUrl(selectedTab));
        this.tabSessionStore.dispatch(changeNavigateUrl(tabSession));
    }
    public closeAllTab(forceCloseAll: boolean = false): void {
        this.tabStore.dispatch(deleteAllTab(forceCloseAll));
        this.tabSessionStore.dispatch(deleteAllTabSession());
    }
    private getNumberOfAllowOpenTab(): number {
        const tabSize = 320;
        return Math.round(window.innerWidth / tabSize);
    }
    public exceedAllowedOpenTab(tabs: TabModel[]): boolean {
        if (!tabs) {
            return false;
        }
        return tabs.length > this.getNumberOfAllowOpenTab();
    }
    public setChangedTabName(selectedTab: TabModel) {
        this.tabStore.dispatch(setTabName(selectedTab));
    }
}