import { AfterViewInit, ChangeDetectionStrategy, Component, EventEmitter, HostListener, Input, OnInit, Output, ViewChild } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Select2DataMapperService } from 'src/app/core/components/rules-config/shared/mappers/select2-data-mapper.service';
import { OrganisationGroupReferenceModel, OrganisationModel, OrganisationRoleReferenceModel, OrganisationTypeReferenceModel } from 'src/app/core/models/organisation-model';
import { CalendarValidityReferenceModel, ConditionReferenceModel, CountryReferenceModel, DateTimeDimensionReferenceModel, DomainAttributeModel, HashTagReferenceModel, LocationGroupModel, RegionReferenceModel, StatusReferenceModel, UsageTypeReferenceModel } from 'src/app/core/models/reference-model/reference-general-model';
import { SeasonReferenceModel } from 'src/app/core/models/reference-model/reference-general-model/season-reference.model';
import { ProductCategoryReferenceModel, ProductGroupReferenceModel, ProductLocationPointReferenceModel, ProductLocationTypeReferenceModel, ProductNumberTypeReferenceModel, ProductTypeGroupModel } from 'src/app/core/models/reference-model/reference-product-model';
import { DomainAttributeService, HashTagReferenceService, OrganisationGroupReferenceService, RegionReferenceService } from 'src/app/core/services/airline-services';
import { OrganisationRoleReferenceService, OrganisationService } from 'src/app/core/services/organisation-services';
import { AncillaryServiceService } from 'src/app/core/services/product-services';
import { ProductSearchFilterService } from 'src/app/core/services/product-services/product-search-filter.service';
import { LocationGroupService } from 'src/app/core/services/reference-service/reference-general-service';
import { ProductTypeGroupService } from 'src/app/core/services/reference-service/reference-product-service';
import { CalendarValidityService, ConditionReferenceService, CountryReferenceService, DateTimeDimensionReferenceService, OrganisationTypeReferenceService, ProductCategoryReferenceService, ProductGroupReferenceService, ProductLocationPointReferenceService, ProductLocationTypeReferenceService, ProductNumberTypeReferenceService, StatusReferenceService, UsageTypeReferenceService } from 'src/app/core/services/system-services';
import { SeasonReferenceService } from 'src/app/core/services/system-services/season-reference.service';
import { PagingDataView } from 'src/app/core/views/pagging-data.view';
import { AttributeSearchModel } from 'src/app/core/models/attribute-type-reference-model';
import { ProductCategory } from 'src/app/modules/product-management/product-management-content/product-categories/product-category';
import { LoadingSpinnerService } from 'src/app/shared/layout/loading-spinner';
import { ActionBarHandlerModel, ActionbarService, ACTION_STATUS } from 'src/app/shared/ui/actionbar';
import { CancelButtonModel, CopyButtonModel, DeleteButtonModel, NewButtonModel, RefreshButtonModel, SaveAsButtonModel, SaveButtonModel } from 'src/app/shared/ui/actionbar/models';
import { FocusingService } from 'src/app/shared/ui/forms/inputs/focusing.service';
import { Select2Data } from 'src/app/shared/ui/forms/inputs/oops-select2';
import { HashTagMapperService } from '../../../shared/mapper/hashtag-mapper.service';
import { ProductHashTagView } from '../../../shared/views/product-hashtag.view';
import { MapperService } from './shared/mapper.service';
import { AncillaryServiceSearchTableView } from './shared/ancillary-service-search-table.view';
import { AncillaryServiceSearchConditionComponent } from './ancillary-service-search-condition/ancillary-service-search-condition.component';
import { AncillaryServiceSearchTableComponent } from './ancillary-service-search-table/ancillary-service-search-table.component';
import { AncillaryServiceSearchCommandModel, AncillaryServiceViewModel } from 'src/app/core/models/product-model/ancillary-service-model';
import { SecurityGroupSecurityModel } from 'src/app/core/models/security-model/security-group-security.model';

@Component({
    selector: 'op-ancillary-service-search',
    templateUrl: './ancillary-service-search.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [
        ProductTypeGroupService,
        MapperService,
        Select2DataMapperService,
        HashTagMapperService
    ]
})
export class AncillaryServiceSearchComponent implements OnInit, AfterViewInit {
    readonly SERVICE_PRODUCT_CATE_CODE = 'ANCILLARY';
    readonly USAGETYPE_DATA: string = "DATA";
    readonly USAGETYPE_TEMPLATE: string = "TEMPLATE";
    readonly USAGETYPE_FILTER: string = "FILTER";
    private readonly ORG_TYPE_SUPPLIER_CODE = 'SUPPLIER';
    private readonly ORG_TYPE_PROVIDER_CODE = 'AIRLINE';
    private readonly ATTRBUTE_GROUP_CODE: string = "ATTRIBUTE";
    private readonly ATTRIBUTE_TYPE_SERVICE: string = "SERVICE";
    private readonly ATTRBUTE_GROUP_CODE_INV: string = "INVENTORY";
    private readonly SALESBUCKET_CODE: string = "SALESBUCKET";
    private readonly PRODUCT_LOCATION_TYPE_CODE = 'RESTRICTION';
    private readonly CALENDAR_VALIDITY_CODE = "SERVICE";
    private readonly ACTIVE_STATUS = "A";

    public serviceProductGroupReference$ = new BehaviorSubject<ProductGroupReferenceModel[]>(null);
    public productTypeGroup$ = new BehaviorSubject<ProductTypeGroupModel[]>(null);
    public statusReference$ = new BehaviorSubject<StatusReferenceModel[]>(null);
    public productSearchFilters$ = new BehaviorSubject<Select2Data[]>(null);
    public usageTypeReferenceFilter$ = new BehaviorSubject<UsageTypeReferenceModel[]>(null);
    public productSearchResults$ = new BehaviorSubject<AncillaryServiceSearchTableView[]>(null);
    public paggingView: PagingDataView[];
    public selectedItem: any;
    private unsubscribe$ = new Subject();
    public advanceSearchModeEnabled = false;
    public ancillaryServiceView$ = new BehaviorSubject<AncillaryServiceViewModel>(null);
    public hashTagReference$ = new BehaviorSubject<HashTagReferenceModel[]>(null);
    public provider$ = new BehaviorSubject<OrganisationModel[]>(null);
    public supplier$ = new BehaviorSubject<OrganisationModel[]>(null);
    public productTypeCode$ = new BehaviorSubject<string>(null);
    public productNumberTypeReference$ = new BehaviorSubject<ProductNumberTypeReferenceModel[]>(null);
    public domainAttributeSearch$ = new BehaviorSubject<DomainAttributeModel[]>(null);
    public domainAttributeService$ = new BehaviorSubject<DomainAttributeModel[]>(null);
    public domainAttributeINV$ = new BehaviorSubject<DomainAttributeModel[]>(null);
    public seasonReference$ = new BehaviorSubject<SeasonReferenceModel[]>(null);
    public conditionReference$ = new BehaviorSubject<ConditionReferenceModel[]>(null);
    public productLocationPointReference$ = new BehaviorSubject<ProductLocationPointReferenceModel[]>(null);
    public productLocationTypeReference$ = new BehaviorSubject<ProductLocationTypeReferenceModel[]>(null);
    public countryReferenceSelect2Data: Select2Data[];
    public locationGroupSelect2Data: Select2Data[];
    public regionReferenceSelect2Data: Select2Data[];
    public calendarValidityReference$ = new BehaviorSubject<CalendarValidityReferenceModel[]>(null);
    public dateTimeDimensionReference$ = new BehaviorSubject<DateTimeDimensionReferenceModel[]>(null);
    public organisationSelect2Data: Select2Data[];
    public organisationTypeSelect2Data: Select2Data[];
    public organisationGroupSelect2Data: Select2Data[];
    public organisationRoleSelect2Data: Select2Data[];
    public ancillaryServiceFilterModel: AncillaryServiceViewModel;
    public productHashTag$ = new BehaviorSubject<ProductHashTagView[]>(null);
    public productCategoryReference$ = new BehaviorSubject<ProductCategoryReferenceModel[]>(null);
    private actionBarHandler: ActionBarHandlerModel;
    public userSecurity: SecurityGroupSecurityModel;

    newBtn = new NewButtonModel();
    copyBtn = new CopyButtonModel();
    saveBtn = new SaveButtonModel();
    ddSaveBtn = new SaveButtonModel();
    ddSaveAsBtn = new SaveAsButtonModel();
    cancelBtn = new CancelButtonModel();
    deleteBtn = new DeleteButtonModel();

    @Input() productGroup: string;
    @Input() selectedProductCategory: ProductCategory;
    @Input() searchFilterId: string;
    @Input() executeFlag: boolean = true;
    @Output() cancelRequest = new EventEmitter();
    @ViewChild(AncillaryServiceSearchConditionComponent) searchConditionComponent: AncillaryServiceSearchConditionComponent;
    @ViewChild(AncillaryServiceSearchTableComponent) searchTableComponent: AncillaryServiceSearchTableComponent;

    constructor(
        private productGroupReferenceService: ProductGroupReferenceService,
        private productSearchFilterService: ProductSearchFilterService,
        private productTypeGroupService: ProductTypeGroupService,
        private statusReferenceService: StatusReferenceService,
        private usageTypeReferenceService: UsageTypeReferenceService,
        private ancillaryServiceService: AncillaryServiceService,
        private mapperService: MapperService,
        private focusingService: FocusingService,
        private actionbarService: ActionbarService,
        private spinnerService: LoadingSpinnerService,
        private hashTagReferenceService: HashTagReferenceService,
        private organisationService: OrganisationService,
        private productNumberTypeReferenceService: ProductNumberTypeReferenceService,
        private domainAttributeService: DomainAttributeService,
        private seasonReferenceService: SeasonReferenceService,
        private conditionReferenceService: ConditionReferenceService,
        private select2DataMapperService: Select2DataMapperService,
        private countryReferenceService: CountryReferenceService,
        private locationGroupService: LocationGroupService,
        private productLocationPointReferenceService: ProductLocationPointReferenceService,
        private productLocationTypeReferenceService: ProductLocationTypeReferenceService,
        private regionReferenceService: RegionReferenceService,
        private calendarValidityReferenceService: CalendarValidityService,
        private dateTimeDimensionReferenceService: DateTimeDimensionReferenceService,
        private organisationGroupReferenceService: OrganisationGroupReferenceService,
        private organisationTypeReferenceService: OrganisationTypeReferenceService,
        private organisationRoleReferenceService: OrganisationRoleReferenceService,
        private productCategoryReferenceService: ProductCategoryReferenceService) { }

    ngOnInit(): void {
        this.getProductSearchFilter();
        this.getProductGroupReference();
        this.getProductTypeGroup();
        this.getStatusReference();
        this.getUsageTypeReference();
        this.getHashTagReference();
        this.getSupplier();
        this.getProvider();
        this.getProductNumberTypeReference();
        this.getDomainAttributeTypeService();
        this.getDomainAttributeTypeInventory();
        this.getSeasonReference();
        this.getConditionReference();
        this.getCountryReference();
        this.getLocationGroup();
        this.getProductLocationPointReference();
        this.getProductLocationTypeReference();
        this.getRegionReference();
        this.getCalendarValidityReference();
        this.getDateTimeDimensionReference();
        this.getOrganisation();
        this.getOrganisationGroupReference();
        this.getOrganisationTypeReference();
        this.getOrganisationRoleReferences();
        this.getProductCategoryReference();
    }

    ngAfterViewInit(): void {
        this.setDefaultActionBarHandler();
        this.getSelectedServiceGroup();
        if (this.executeFlag == true) {
            this.searchConditionComponent.doSearch();
        }
        this.actionbarService.updateState(this.actionBarHandler);
        this.actionbarService.action$.pipe(takeUntil(this.unsubscribe$)).subscribe(
            actionId => {
                this.actionbarClick(actionId);
            }
        )
    }

    setDefaultActionBarHandler() {
        this.userSecurity = this.selectedProductCategory?.security;
        this.newBtn.enable(this.userSecurity?.newFlag ?? false);
        this.copyBtn.enable(this.userSecurity?.copyFlag ?? false);
        this.saveBtn.disable();
        this.ddSaveBtn.disable();
        this.ddSaveAsBtn.disable();
        this.deleteBtn.enable(this.userSecurity?.deleteFlag ?? false);

        this.saveBtn.addDropdownBtn(this.ddSaveBtn);
        this.saveBtn.addDropdownBtn(this.ddSaveAsBtn);

        this.actionBarHandler = new ActionBarHandlerModel(
            this.newBtn,
            this.copyBtn,
            this.saveBtn,
            this.cancelBtn,
            this.deleteBtn,
            new RefreshButtonModel()
        )
    }

    private getSelectedServiceGroup() {
        if (this.productGroup != null) {
            this.searchConditionComponent.productGroupCode = this.productGroup
        }
    }

    private getProductSearchFilter() {
        this.productSearchFilterService.getByProductCategory(this.SERVICE_PRODUCT_CATE_CODE)
            .subscribe(
                (responses) => {
                    let searchfilters = responses.map(r => new Select2Data(r.productId, r.productName));
                    this.productSearchFilters$.next(searchfilters);
                }
            )
    }

    private getProductCategoryReference() {
        this.productCategoryReferenceService.getAll()
            .subscribe(
                (responses: ProductCategoryReferenceModel[]) => {
                    this.productCategoryReference$.next(responses);
                }
            )
    }

    private getProductGroupReference() {
        this.productGroupReferenceService.getAll()
            .subscribe(
                (responses: ProductGroupReferenceModel[]) => {
                    let serviceProductGroup = responses.filter(x => x.productCategoryCode == this.SERVICE_PRODUCT_CATE_CODE);
                    this.serviceProductGroupReference$.next(serviceProductGroup);
                }
            )
    }

    private getProductTypeGroup() {
        this.productTypeGroupService.getAll()
            .subscribe(
                (responses: ProductTypeGroupModel[]) => {
                    this.productTypeGroup$.next(responses);
                }
            )
    }

    private getStatusReference() {
        this.statusReferenceService.getAll()
            .subscribe(
                (responses: StatusReferenceModel[]) => {
                    this.statusReference$.next(responses);
                }
            )
    }

    private getUsageTypeReference() {
        this.usageTypeReferenceService.getUsageTypeReferences()
            .subscribe(
                (responses: UsageTypeReferenceModel[]) => {
                    if (responses) {
                        this.getUsageTypeReferenceWithFilter(responses); 
                    }
                }
            )
    }

    private getUsageTypeReferenceWithFilter(responses: UsageTypeReferenceModel[]) {
        this.usageTypeReferenceFilter$.next(
            responses.filter(x => x.usageTypeCode == this.USAGETYPE_DATA ||
                x.usageTypeCode == this.USAGETYPE_TEMPLATE || x.usageTypeCode == this.USAGETYPE_FILTER));
    }

    public onSearch(condition: AncillaryServiceViewModel) {
        if (!condition) {
            return;
        }
        let searchCommand = this.mapperService.convertToSearchCommand(condition);
        this.search(searchCommand);
        this.focusOnSearchResult();
    }

    private search(searchCommand: AncillaryServiceSearchCommandModel) {
        this.ancillaryServiceService.search(searchCommand)
            .subscribe(
                (responses: AncillaryServiceViewModel[]) => {
                    this.paggingView = new Array<PagingDataView>();
                    let searchResults = this.mapperService.ancillaryServiceSearchTableViews(responses, this.paggingView);
                    this.productSearchResults$.next(searchResults);
                }
            );
    }

    private focusOnSearchResult() {
        this.focusingService.focus(this.searchTableComponent.focusingDirective)
    }

    public onClearCondition(condition: AncillaryServiceViewModel) {
        this.advanceSearchModeEnabled = false;
        if (!condition) {
            condition = {} as AncillaryServiceViewModel;
            condition.statusCode = this.ACTIVE_STATUS;
        } 
        this.ancillaryServiceView$.next(condition);
        this.searchTableComponent.dataGrid.instance.clearFilter();
        let searchCommand = this.mapperService.convertToSearchCommand(condition);
        this.search(searchCommand);
    }

    actionbarClick(clickedButton: string) {
        switch (clickedButton) {
            case ACTION_STATUS.back:
            case ACTION_STATUS.cancel:
                this.cancelRequest.emit();
                break;
            case ACTION_STATUS.new:
                this.searchTableComponent.onNew();
                break;
            case ACTION_STATUS.copy:
                this.onCopyClick();
                break;
        }
    }

    onAncillaryServiceSearchFilterIdChange(productId: string) {
        if (productId) {
            this.getAncillaryService(productId);
        } else {
            this.onClearCondition(null);
        }
    }

    private getAncillaryService(productId: string) {
        this.spinnerService.show();
        this.ancillaryServiceService.getByProductId(productId)
            .subscribe(  
                (response: AncillaryServiceViewModel) => {
                    this.spinnerService.hide();
                    response = this.getIsOwnerFilter(response);
                    this.ancillaryServiceView$.next(response);
                    this.ancillaryServiceFilterModel = response;
                    this.toggleCopyButton();
                },
                () => {
                    this.spinnerService.hide()
                }
            )
    }

    private getHashTagReference() {
        this.hashTagReferenceService.getAll()
            .subscribe(
                (responses: HashTagReferenceModel[]) => {
                    this.hashTagReference$.next(responses);
                }
            )
    }

    private getProvider() {
        this.organisationService.getByOrganisationType(this.ORG_TYPE_PROVIDER_CODE)
            .subscribe(
                (responses: OrganisationModel[]) => {
                    this.provider$.next(responses);
                }
            )
    }

    private getSupplier() {
        this.organisationService.getByOrganisationType(this.ORG_TYPE_SUPPLIER_CODE)
            .subscribe(
                (responses: OrganisationModel[]) => {
                    this.supplier$.next(responses);
                }
            )
    }

    private getProductNumberTypeReference() {
        this.productNumberTypeReferenceService.getAll()
            .subscribe(
                (responses: ProductNumberTypeReferenceModel[]) => {
                    this.productNumberTypeReference$.next(responses);
                }
            )
    }

    private getDomainAttributeTypeService() {
        this.domainAttributeService.getByProductType(this.ATTRBUTE_GROUP_CODE, "", false, this.ATTRIBUTE_TYPE_SERVICE)
            .subscribe(
                (responses: DomainAttributeModel[]) => {
                    this.domainAttributeService$.next(responses);
                }
            )
    }

    private getDomainAttributeTypeInventory() {
        this.domainAttributeService.getByProductType(this.ATTRBUTE_GROUP_CODE_INV)
            .subscribe(
                (responses: DomainAttributeModel[]) => {
                    if (responses) {
                        this.domainAttributeINV$.next(
                            responses.filter(x => x.attributeTypeCode != this.SALESBUCKET_CODE));
                    }
                }
            )
    }

    private getSeasonReference() {
        this.seasonReferenceService.getAll()
            .subscribe(
                (responses: SeasonReferenceModel[]) => {
                    this.seasonReference$.next(responses);
                }
            )
    }

    private getConditionReference() {
        this.conditionReferenceService.getConditionsByCodes([])
            .subscribe(
                (responses: ConditionReferenceModel[]) => {
                    this.conditionReference$.next(responses);
                }
            )
    }

    private getCountryReference() {
        this.countryReferenceService.searchAllCountry()
            .subscribe(
                (responses: CountryReferenceModel[]) => {
                    this.countryReferenceSelect2Data = this.select2DataMapperService.countryReferenceToSelect2Data(responses);
                }
            )
    }

    private getLocationGroup() {
        this.locationGroupService.getAll()
            .subscribe(
                (responses: LocationGroupModel[]) => {
                    this.locationGroupSelect2Data = this.select2DataMapperService.locationGroupToSelect2Data(responses);
                }
            )
    }

    private getProductLocationPointReference() {
        this.productLocationPointReferenceService.getAll()
            .subscribe(
                (responses: ProductLocationPointReferenceModel[]) => {
                    this.productLocationPointReference$.next(responses);
                }
            )
    }

    private getProductLocationTypeReference() {
        this.productLocationTypeReferenceService.getByProductLocationTypeCode(this.PRODUCT_LOCATION_TYPE_CODE)
            .subscribe(
                (response: ProductLocationTypeReferenceModel) => {
                    if (response) {
                        let productLocationTypeReference = new Array<ProductLocationTypeReferenceModel>();
                        productLocationTypeReference.push(response);
                        this.productLocationTypeReference$.next(productLocationTypeReference);
                    }
                }
            )
    }

    private getRegionReference() {
        this.regionReferenceService.getRegionReference()
            .subscribe(
                (responses: RegionReferenceModel[]) => {
                    this.regionReferenceSelect2Data = this.select2DataMapperService.regionReferenceToSelect2Data(responses);
                }
            )
    }

    private getCalendarValidityReference() {
        this.calendarValidityReferenceService.getByCalendarValidityCodes(this.CALENDAR_VALIDITY_CODE)
            .subscribe(
                (responses: CalendarValidityReferenceModel[]) => {
                    this.calendarValidityReference$.next(responses);
                }
            )
    }

    private getDateTimeDimensionReference() {
        this.dateTimeDimensionReferenceService.getDateTimeDimensionReference()
            .subscribe(
                (responses: DateTimeDimensionReferenceModel[]) => {
                    this.dateTimeDimensionReference$.next(responses);
                }
            )
    }

    private getOrganisation() {
        this.organisationService.getByStatus(this.ACTIVE_STATUS)
            .subscribe(
                (responses: OrganisationModel[]) => {
                    this.organisationSelect2Data = this.select2DataMapperService.organisationToSelect2Data(responses);
                }
            )
    }

    private getOrganisationGroupReference() {
        this.organisationGroupReferenceService.getOrganisationGroupReference()
            .subscribe(
                (responses: OrganisationGroupReferenceModel[]) => {
                    this.organisationGroupSelect2Data = this.select2DataMapperService.organisationGroupToSelect2Data(responses);
                }
            )
    }

    private getOrganisationTypeReference() {
        this.organisationTypeReferenceService.getByStatus(this.ACTIVE_STATUS)
            .subscribe(
                (responses: OrganisationTypeReferenceModel[]) => {
                    this.organisationTypeSelect2Data = this.select2DataMapperService.organisationTypeToSelect2Data(responses);
                }
            )
    }

    private getOrganisationRoleReferences() {
        this.organisationRoleReferenceService.getAllActive()
            .subscribe(
                (responses: OrganisationRoleReferenceModel[]) => {
                    this.organisationRoleSelect2Data = this.select2DataMapperService.organisationRoleToSelect2Data(responses);
                }
            )
    }

    public onCopyClick() {
        if (this.ancillaryServiceFilterModel) {
            this.copyFilter();
        } else {
            this.searchTableComponent.onCopy();
        }
    }

    onSelectedChange(item: AncillaryServiceViewModel) {
        this.selectedItem = item;
    }

    async copyFilter() {
        if (!this.ancillaryServiceFilterModel) {
            return;
        }
        this.advanceSearchModeEnabled = true;
        this.ancillaryServiceFilterModel.productName += ' - copy';
        this.ancillaryServiceFilterModel.productId = null;

        this.ancillaryServiceView$.next(this.ancillaryServiceFilterModel);

        this.ancillaryServiceFilterModel = null;
        this.toggleCopyButton();
    }

    toggleCopyButton() {
        setTimeout(() => {
            let handler = this.actionbarService.getCurrentState();
            handler.get(ACTION_STATUS.copy).enable(!!(this.ancillaryServiceFilterModel))
            this.actionbarService.updateState(handler);
        }, 0);
    }

    editFilter(productId: string) {
        this.advanceSearchModeEnabled = true;
        this.getAncillaryService(productId);
    }

    getIsOwnerFilter(response: AncillaryServiceViewModel): AncillaryServiceViewModel{
        response.isOwnerFilter = response.filterUserAccountId ? true: false;
        return response
    }

    checkSpecialServiceAttribute(searchAttributeCode: string, serviceAttributes: AttributeSearchModel[]) {
        return serviceAttributes.findIndex(item => item.attributeCode == searchAttributeCode) !== -1;
    }

    @HostListener("window:keydown", ["$event"])
    public keyDown(event) {
        const enterKeyCode = 13;
        switch (event.keyCode) {
            case enterKeyCode:
                this.searchConditionComponent.doSearch();
                break;
            default:
                break;
        }
    }
}
