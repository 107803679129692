import {
    Component,
    ChangeDetectionStrategy,
    OnInit,
    Input,
    ViewChild,
    ComponentFactoryResolver,
    ElementRef,
    ChangeDetectorRef,
    Output,
    EventEmitter
} from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { UiHelperService } from 'src/app/core/utils/ui-helper.service';

import { TextView } from './shared/text.view';
import { TextDetailComponent } from './text-detail/text-detail.component';
import { TextDetailDirective } from './text-detail/text-detail.directive';
import { TextTableComponent } from './text-table/text-table.component';
import {
    LanguageReferenceModel,
    MediaUseReferenceModel,
    MediaTypeReferenceModel
} from 'src/app/core/models/reference-model/reference-media-model';

import { Select2Data } from 'src/app/shared/ui/forms/inputs/oops-select2';
import { TextMapperService } from './shared/text-mapper.service';
import { TextLibraryTableComponent } from './text-library-table/text-library-table.component';
import { TextLibraryTableDirective } from './text-library-table/text-library-table.directive';
import { FocusingDirective } from 'src/app/shared/ui/forms/inputs/focusing.directive';
import { FocusingService } from 'src/app/shared/ui/forms/inputs/focusing.service';
import { MediaSearchCommandModel, MediaViewModel } from 'src/app/core/models/media-model';
import { MediaService } from 'src/app/core/services/media-services';

declare var $: any;

@Component({
    selector: 'op-text',
    templateUrl: './text.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [TextMapperService]
})
export class TextComponent implements OnInit {

    @Input() id: string;
    @Input() languageReference$ = new BehaviorSubject<LanguageReferenceModel[]>(null);
    @Input() mediaTypeReference$ = new BehaviorSubject<MediaTypeReferenceModel[]>(null);
    @Input() mediaUseReference$ = new BehaviorSubject<MediaUseReferenceModel[]>(null);
    @Input() mediaHashTagSelect2Data: Select2Data[];
    @Input() disabled: boolean = false;

    @Output() textLibrarySelected = new EventEmitter<MediaViewModel[]>();
    @Output() mediaIdDeleted = new EventEmitter<String>();
    @Output() textImageUploadClick = new EventEmitter();
    @Output() textVideoUploadClick = new EventEmitter();
    @Output() textImageSelectLibraryClick = new EventEmitter<MediaSearchCommandModel>();
    @Output() textVideoSelectLibraryClick = new EventEmitter<MediaSearchCommandModel>();
    @Output() cancelCallFromText = new EventEmitter();

    @ViewChild("codeDetailRef") detailElement: ElementRef;
    @ViewChild("codeRef") codeElement: ElementRef;
    @ViewChild("textDetailLibraryTableRef") textLibraryElement: ElementRef;

    @ViewChild(TextDetailDirective) textDetailDirective: TextDetailDirective;
    @ViewChild(TextTableComponent) textTable: TextTableComponent;
    @ViewChild(TextDetailComponent) textDetailComponent: TextDetailComponent;
    @ViewChild(TextLibraryTableDirective) textLibraryTableDirective: TextLibraryTableDirective;
    @ViewChild(TextLibraryTableComponent) textLibraryTableComponent: TextLibraryTableComponent;

    @ViewChild(FocusingDirective) focusingDirective: FocusingDirective;

    private textDetailComponentByDirective: TextDetailComponent;

    readonly ADD_BINDING_DELAY_TIME = 50;
    public singleRecord: boolean = true;
    public miniMizePanel: boolean = false;
    public miniMizeDetailPanel: boolean = false;
    public isCollapsedDetail: boolean = true;
    
    public enabledEdit: boolean = false;
    public model = new TextView();
    public mediaTexts: TextView[] = new Array();
    public browseEnabled: boolean = false;
    public mediaProcess: boolean = false;
    private mediaSelectFromLibrary: boolean = false;
    public isCollapsedMediaLibrary: boolean = true;
    private isSaveAs = false;
    public detailDirectiveNewMode: boolean = false;
    public focusing: boolean = false;

    constructor(private changeDectionRef: ChangeDetectorRef,
        private componentFactoryResolver: ComponentFactoryResolver,
        private focusingService: FocusingService,
        private uiHelper: UiHelperService,
        private mediaService: MediaService,
        private textMapperService: TextMapperService) {
    }

    ngOnInit(): void {
    }

    get isShowAdd() : boolean {
        let isShow = false;
        if (this.mediaTexts.length > 1) {
            isShow = true;
        } else if (this.singleRecord && this.mediaTexts.length == 1) {
            isShow = true;
        }
        return isShow;
    }

    get isShowMainBrowse(): boolean {
        return !this.isShowAdd && this.mediaTexts.length == 0;
    }

    get isHideSaveDropdown(): boolean {
        return !this.singleRecord || this.mediaTexts.length == 0;
    }

    get isSecondBrowseEnabled(): boolean {
       return this.detailDirectiveNewMode && this.isCollapsedMediaLibrary
    }

    public browse() {
        this.textDetailComponent.browseLibraryClick();
    }

    public browseByDirective() {
        this.textDetailComponentByDirective.browseLibraryClick();
    }

    public add() {
        this.detailDirectiveNewMode = true;
        this.showDetailDirective();
        this.setSingleRecord(false);
        
        setTimeout(() => {
            this.loadDetailForm();
            this.changeDectionRef.detectChanges();
            this.scrollToDetail();
        }, this.ADD_BINDING_DELAY_TIME);
    }

    public edit() {
        this.detailDirectiveNewMode = false;
        this._edit(this.model);
    }

    private _edit(data: TextView) {
        if (data) {
            this.showDetailDirective();
            this.loadDetailForm(data);
            this.scrollToDetail();
        }
    }

    public validateForm() {
        if (this.singleRecord) {
            return this.textDetailComponent.validateForm();
        }
        if (!this.isCollapsedDetail) {
            this.textDetailComponentByDirective.validateForm();
            return false;
        }
        if (this.mediaTexts.length == 0) {
            return false;
        }
        return true;
    }

    public save(saveAs: boolean = false) {
        this.isSaveAs = saveAs;
        if (saveAs) {
            this.textDetailComponent.saveAs();
        } else {
            this.textDetailComponent.save();
        }
    }

    public saveByDirective(saveAs: boolean = false) {
        this.isSaveAs = saveAs;
        if (saveAs) {
            this.textDetailComponentByDirective.saveAs();
        } else {
            this.textDetailComponentByDirective.save();
        }
    }

    public onSave(data:TextView) {
        if (data) {
            this.savedata(data, this.isSaveAs);
            this.displayAfterSave();
        }
    }

    public delete() {
        this._delete(this.model);
    }

    private _delete(data: TextView) {
        let mediaId = data.mediaId;
        let mediaTranslationId = data.mediaTranslationId;
        this.deletedata(data);
        this.displayAfterDelete();
        if (mediaId) {
            this.deleteTextTranslation(mediaId);
        }
        else {
            this.deleteTextTranslation(mediaTranslationId);
        }
    }

    public cancel() {
        this.model = new TextView();
        if (this.mediaTexts.length == 1 && this.singleRecord == false) {
            this.model = this.mediaTexts[0];
            this.setSingleRecord(true);
        }
        else if (this.singleRecord) {
            this.mediaTexts = [];
            this.rowSelected(this.model);
        } else {
            this.scrollToSearch();
        }
    }

    public cancelByDirective() {
        this.detailDirectiveNewMode = false;
        this.cancel();
        this.hideDetailDirective();
    }


    public onDetailFocus(): void {
        this.scrollToDetail();
    }

    public rowSelected(data: TextView) {
        this.model = data.clone();
        this.enabledEdit = data.mediaName ? true : false;
    }

    public setBrowseEnabled(enabled: boolean) {
        this.browseEnabled = enabled;
    }

    public setSingleRecord(isSingle: boolean) {
        this.singleRecord = isSingle;
    }

    private showDetailDirective() {
        if (this.isCollapsedDetail) {
            this.isCollapsedDetail = false;
            this.changeDectionRef.markForCheck();
        }
    }

    private hideDetailDirective() {
        if (!this.isCollapsedDetail) {
            this.isCollapsedDetail = true;
            this.formDetailClose();
            this.scrollToSearch();
        }
    }

    private scrollToSearch() {
        this.uiHelper.moveScrollPosition(this.codeElement, 280);
    }

    public scrollToDetail() {
        if (this.singleRecord == true) {
            if (this.model.mediaTypeCode == 'TEXT') {
                this.uiHelper.moveScrollPosition(this.detailElement, 170);
            }
            else {
                if (this.mediaTexts.length == 1) {
                    this.uiHelper.moveScrollPosition(this.detailElement, 600);
                }
                else {
                   this.uiHelper.moveScrollPosition(this.detailElement, 360);
                }
            }
        }
        else {
            setTimeout(() => {
                this.uiHelper.moveScrollPosition(this.detailElement, 300);
            }, this.ADD_BINDING_DELAY_TIME);
        }
    }

    private loadDetailForm(dataEdit: TextView = null) {
        let componentFactory = this.componentFactoryResolver
            .resolveComponentFactory(TextDetailComponent);

        let viewContainerRef = this.textDetailDirective.viewContainerRef;
        viewContainerRef.clear();

        let componentRefs = viewContainerRef.createComponent(componentFactory);
        let component: TextDetailComponent = (<TextDetailComponent>componentRefs.instance);
        component.languageReference$ = this.languageReference$;
        component.mediaTypeReference$ = this.mediaTypeReference$;
        component.mediaUseReference$ = this.mediaUseReference$;
        component.mediaHashTagSelect2Data = this.mediaHashTagSelect2Data;
        component.singleRecord = false;
        if (dataEdit != null) {
            component.model = dataEdit.clone();
            dataEdit.mediaName = dataEdit.mediaName;
            component.showBrowse = false;
        }
        else {
            component.showBrowse = true;
            this.setBrowseEnabled(false);
        }
        component.onBrowseEnabledChanged.subscribe((enabled: boolean) => {
            this.browseEnabled = enabled;
        });
        component.onSave.subscribe(
            (dataSave: TextView) => {
                this.onSave(dataSave);
            }
        );
        component.onCancel.subscribe(
            response => {
                this.displayAfterCancel();
            }
        );
        component.onSaveMediaLibrary.subscribe(
            (dataSave: TextView[]) => {
                this.saveMediaLibrary(dataSave);
            }
        );
        component.notCopyTextTranslation.subscribe(
            (mediaId: string) => {
                this.deleteTextTranslation(mediaId);
            }
        );
        this.onClickMediaLibraryEvent(component);
        this.onClickImageUploadEvent(component);
        this.onClickVideoUploadEvent(component);
        this.onClickImageSelectLibraryEvent(component);
        this.onClickVideoSelectLibraryEvent(component);

        this.textDetailComponentByDirective = component;
    }

    private refreshDataGrid() {
        this.textTable.dataGrid.instance.refresh();
    }

    private savedata(data: TextView, isSaveAs: boolean) {
        if (this.mediaTexts != null) {
            var filterIndex = this.mediaTexts.findIndex(x => x.no == data.no);
            if (filterIndex == -1 || isSaveAs) {
                data.no = this.mediaTexts.length + 1;
                this.mediaTexts.push(data);
            }
            else {
                this.mediaTexts[filterIndex] = data;
            }
        }
        else {
            data.no = 1;
            this.mediaTexts.push(data);
        }
        this.refreshDataGrid();
    }

    private deletedata(data: TextView) {
        if (this.mediaTexts != null) {
            let views = [...this.mediaTexts];
            views.splice(views.indexOf(data), 1);
            let no = 1;
            views.forEach(function (value) {
                value.no = no;
                no++;
            });
            this.mediaTexts = views;
            this.refreshDataGrid();
        }
    }

    private displayAfterSave() {
        if (this.mediaTexts != null) {
            if (this.mediaTexts.length > 1) {
                this.setSingleRecord(false);
                this.hideDetailDirective();
            }
            else {
                this.setSingleRecord(true);
            }
        }
        else {
            this.setSingleRecord(true);
        }
    }

    private displayAfterDelete() {
        if (this.mediaTexts.length == 0 || this.mediaTexts.length == 1) {
            if (this.mediaTexts.length == 1) {
                this.model = this.mediaTexts[0].clone();
            }
            else {
                this.model = new TextView();
            }
            this.enabledEdit = false;
            this.setSingleRecord(true);
        }
        
        this.hideDetailDirective();
    }

    private displayAfterCancel() {
        this.hideDetailDirective();
        if (this.mediaTexts.length == 1) {
            this.setSingleRecord(true);
        }
    }

    private formDetailClose() {
        let viewContainerRef = this.textDetailDirective.viewContainerRef;
        viewContainerRef.clear();
    }

    public fillModelToForm(mediaTextViews: TextView[]) {
        this.clearData();
        if (mediaTextViews && mediaTextViews.length > 0) {
            this.fillModelInCase(mediaTextViews);
        }
        else {
            this.clearDetailForm();
        }
        this.mediaTexts = mediaTextViews;
        this.isCollapsedDetail = true;
        this.changeDectionRef.detectChanges();
    }

    public saveMediaLibrary(textViews: TextView[]) {
        if (textViews) {
            var mediaIds: string[] = new Array();
            for (let data of textViews) {
                if (!this.exists(data)) {
                    this.savedata(data, this.isSaveAs);
                    mediaIds.push(data.mediaId);
                }
            }
            this.displayAfterSaveMediaLibrary();
            this.displayTextTranslationFromMediaLibrary(mediaIds);
            this.minimixeMediaLibraryPanel();
        }
    }

    private onClickMediaLibraryEvent(component: TextDetailComponent) {
        component.onClickMediaLibrary.subscribe(
            (searchCommand: MediaSearchCommandModel) => {
                this.clickMediaLibrary(searchCommand);
            }
        );
    }

    private onClickImageUploadEvent(component: TextDetailComponent) {
        component.onClickImageUpload.subscribe(
            () => {
                this.clickImageUpload();
            }
        );
    }

    private onClickVideoUploadEvent(component: TextDetailComponent) {
        component.onClickVideoUpload.subscribe(
            () => {
                this.clickVideoUpload();
            }
        );
    }

    private onClickImageSelectLibraryEvent(component: TextDetailComponent) {
        component.onClickImageSelectLibrary.subscribe(
            (searchCommand: MediaSearchCommandModel) => {
                this.clickImageSelectLibrary(searchCommand);
            }
        );
    }

    private onClickVideoSelectLibraryEvent(component: TextDetailComponent) {
        component.onClickVideoSelectLibrary.subscribe(
            (searchCommand: MediaSearchCommandModel) => {
                this.clickVideoSelectLibrary(searchCommand);
            }
        );
    }

    private fillModelInCase(mediaTextViews: TextView[]) {
        if (mediaTextViews.length == 1) {
            this.fillModelSingleRecord(mediaTextViews[0]);
        }
        else {
            this.setSingleRecord(false);
        }
    }

    private fillModelSingleRecord(mediaTextView: TextView) {
        this.setSingleRecord(true);
        this.model = mediaTextView.clone();
        this.clearDetailForm();
    }

    private clearData() {
        this.setSingleRecord(true);
        this.model = new TextView();
        this.textMapperService.setDefaultPrimaryLanguage(this.languageReference$, this.model);
    }

    public clearDetailForm() {
        if (this.textDetailComponent?.textForm) {
            this.textDetailComponent.textForm.resetForm(this.model);
        }
    }

    private displayAfterSaveMediaLibrary() {
        if (this.mediaTexts != null) {
            this.displayAfterSaveInCase();
        }
        else {
            this.displayAfterSaveNoData();
        }
    }

    private displayAfterSaveInCase() {
        if (this.mediaTexts.length > 1) {
            this.displayAfterSaveInList();
        }
        else {
            this.displayAfterSaveSingleRecord();
        }
    }
    private displayAfterSaveInList() {
        this.setSingleRecord(false);
        this.hideDetailDirective();
        this.scrollToSearch();
    }

    private displayAfterSaveSingleRecord() {
        this.model = this.mediaTexts[0].clone();
        this.setSingleRecord(true);
    }

    private displayAfterSaveNoData() {
        this.setSingleRecord(true);
    }

    private displayTextTranslationFromMediaLibrary(mediaIds: string[]) {
        if (mediaIds) {
            this.mediaService.getByIds(mediaIds)
                .subscribe(
                    (responses: MediaViewModel[]) => {
                        this.textLibrarySelected.emit(responses);
                    }
                )
        }
    }

    public deleteTextTranslation(mediaId: string) {
        if (mediaId) {
            this.mediaIdDeleted.emit(mediaId);
        }
    }

    private exists(textView: TextView): boolean {
        var filter = this.mediaTexts.filter(x => x.mediaId == textView.mediaId);
        if (filter.length > 0) {
            return true;
        }
        else {
            return false;
        }
    }

    public clickMediaLibrary(searchCommand: MediaSearchCommandModel) {
        this.displayTextLibrary().then(() => {
            this.loadTextLibraryTableForm(searchCommand).then(() => {
                this.uiHelper.moveScrollPosition(this.textLibraryElement.nativeElement, 300);
            });
        });
    }

    public displayTextLibrary(): Promise<void> {
        return new Promise((resolve, reject) => {
            if (!this.mediaTexts?.length) {
                this.miniMizePanel = true;
            }
            else {
                this.miniMizeDetailPanel = true;
            }
            this.isCollapsedMediaLibrary = false;
            if (this.textDetailComponent) {
                this.textDetailComponent.browseLibrary = false;
            }
            resolve();
        });
    }

    public clickImageUpload() {
        this.mediaSelectFromLibrary = false;
        this.textImageUploadClick.emit();
    }

    public clickImageSelectLibrary(searchCommand: MediaSearchCommandModel) {
        this.mediaSelectFromLibrary = true;
        this.textImageSelectLibraryClick.emit(searchCommand);
    }

    public clickVideoUpload() {
        this.mediaSelectFromLibrary = false;
        this.textVideoUploadClick.emit();
    }

    public clickVideoSelectLibrary(searchCommand: MediaSearchCommandModel) {
        this.mediaSelectFromLibrary = true;
        this.textVideoSelectLibraryClick.emit(searchCommand);
    }

    public clearForm() {
        this.setSingleRecord(true);
        this.isCollapsedDetail = true;
        this.model = new TextView();
        this.mediaTexts = [];
        this.mediaSelectFromLibrary = false;
        this.changeDectionRef.detectChanges();
        this.textDetailComponentByDirective = null;
    }

    public mediaUploadCancel() {
        this.mediaProcess = false;
        this.mediaSelectFromLibrary = false;
        if (this.textDetailComponentByDirective) {
            this.textDetailComponentByDirective.mediaUploadCancel();
            this.textDetailComponentByDirective = null;
        } else {
            this.textDetailComponent.mediaUploadCancel();
        }
        this.moveToPanelAfterMediaProcess();
    }

    public mediaImageUploadCompleted(mediaContentId: string) {
        this.mediaProcess = false;
        this.mediaSelectFromLibrary = false;
        if (this.textDetailComponentByDirective) {
            this.textDetailComponentByDirective.mediaImageUploadCompleted(mediaContentId);
        } else {
            this.textDetailComponent.mediaImageUploadCompleted(mediaContentId);
        }
        this.moveToPanelAfterMediaProcess();
    }

    public mediaVideoUploadCompleted(mediaContentId: string) {
        this.mediaProcess = false;
        this.mediaSelectFromLibrary = false;
        if (this.textDetailComponentByDirective) {
            this.textDetailComponentByDirective.mediaVideoUploadCompleted(mediaContentId);
        } else {
            this.textDetailComponent.mediaVideoUploadCompleted(mediaContentId);
        }
        this.moveToPanelAfterMediaProcess();
    }

    public mediaSelectLibraryCancel() {
        this.mediaProcess = false;
        this.mediaSelectFromLibrary = false;
        if (this.textDetailComponentByDirective) {
            this.textDetailComponentByDirective.mediaUploadCancel();
        } else {
            this.textDetailComponent.mediaUploadCancel();
        }
        this.moveToPanelAfterMediaProcess();
    }

    public mediaImageSelectLibraryCompleted(mediaContentIds: string[]) {
        this.mediaProcess = false;
        this.mediaSelectFromLibrary = false;
        if (this.textDetailComponentByDirective) {
            this.textDetailComponentByDirective.mediaImageSelectLibraryCompleted(mediaContentIds);
        } else {
            this.textDetailComponent.mediaImageSelectLibraryCompleted(mediaContentIds);   
        }
        this.moveToPanelAfterMediaProcess();
    }

    public mediaVideoSelectLibraryCompleted(mediaContentIds: string[]) {
        this.mediaProcess = false;
        this.mediaSelectFromLibrary = false;
        if (this.textDetailComponentByDirective) {
            this.textDetailComponentByDirective.mediaVideoSelectLibraryCompleted(mediaContentIds);
        } else {
            this.textDetailComponent.mediaVideoSelectLibraryCompleted(mediaContentIds);
        }
        this.moveToPanelAfterMediaProcess();
    }

    private moveToPanelAfterMediaProcess() {
        if (!this.singleRecord) {
            this.uiHelper.moveScrollPosition(this.detailElement, -160);
        }
    }

    public minimizeTextPanel() {
        this.mediaProcess = true;
    }

    public checkCollapsedStatus(collapsed) {
        if (!collapsed) {
            if (this.mediaProcess || this.mediaSelectFromLibrary) {
                this.cancelCallFromText.emit();
                this.mediaUploadCancel();
                this.mediaSelectLibraryCancel();
            }
        }
    }

    private loadTextLibraryTableForm(searchCommand: MediaSearchCommandModel): Promise<void> {
        return new Promise((resolve, reject) => {
            let componentFactory = this.componentFactoryResolver
                .resolveComponentFactory(TextLibraryTableComponent);

            let viewContainerRef = this.textLibraryTableDirective.viewContainerRef;
            viewContainerRef.clear();

            let componentRefs = viewContainerRef.createComponent(componentFactory);
            let component: TextLibraryTableComponent = (<TextLibraryTableComponent>componentRefs.instance);
            this.assignInitialDataMediaLibraryTableForm(component, searchCommand);
            this.onSaveMediaLibraryEvent(component);
            this.textLibraryTableComponent = component;
            resolve();
        });
    }

    private assignInitialDataMediaLibraryTableForm(component: TextLibraryTableComponent, searchCommand: MediaSearchCommandModel) {
        component.mediaSearchCommand = searchCommand;
        component.mediaTypeReference$ = this.mediaTypeReference$;
    }

    private onSaveMediaLibraryEvent(component: TextLibraryTableComponent) {
        component.onSaveMediaLibrary.subscribe(
            (dataSave: TextView[]) => {
                this.saveMediaLibrary(dataSave);
            }
        );
    }


    public applyBrowseLibrary() {
        this.textLibraryTableComponent.saveMediaBrowse();
    }

    public cancelBrowseLibrary() {
        this.cancelBrowse();
        this.formMediaLibraryTableClose();
    }

    public cancelBrowse() {
        this.minimixeMediaLibraryPanel();
    }

    private formMediaLibraryTableClose() {
        let viewContainerRef = this.textLibraryTableDirective.viewContainerRef;
        viewContainerRef.clear();
    }

    private minimixeMediaLibraryPanel() {
        this.isCollapsedMediaLibrary = true;
        this.miniMizePanel = false;
        this.miniMizeDetailPanel = false;
        if (this.textDetailComponent) {
            this.textDetailComponent.browseLibrary = false;
        }
    }
}