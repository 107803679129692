import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { InsightPassengerAddressModel, InsightPassengerModel } from "../../models/individual-model";
import { AddressTypeReferenceService } from "../../services/system-services";
import { BehaviorSubject, forkJoin, of } from "rxjs";
import { Select2Data } from "src/app/shared/ui/forms/inputs/oops-select2";
import { InsightPassengerService } from "../../services/individul-services";
import { AddressTypeReferenceModel } from "../../models/reference-model/reference-general-model";

declare var $: any;

@Component({
    selector: 'op-address-type-selection',
    templateUrl: './address-type-selection.component.html'
})
export class AddressTypeSelectionComponent implements OnInit {
    @Input() individualId: string;
    @Input('data')
    set data(value: any) {
        this._value = value;
    }
    @Input() hasError: boolean = false;
    @Input() disabled: boolean = false;

    @Output() dataChange = new EventEmitter<any>();
    @Output() presetSelected = new EventEmitter<InsightPassengerAddressModel>();

    get value(): any {
        return this._value;
    }

    set value(val: any) {
        this._value = val;
    }

    readonly OPTION = {
        placeholder: "",
        allowClear: false,
        minimumResultsForSearch: -1,
        templateResult: this.template
    }

    select2Datas$ = new BehaviorSubject<Select2Data[]>([]);
    loadCompleted$ = new BehaviorSubject<boolean>(false);

    private readonly EMPTY_SELECT2_DATA: Select2Data = {
        id: '',
        text: '',
        selected: false,
        disabled: false
    }

    private _value: any;

    constructor(private addressTypeReferenceService: AddressTypeReferenceService,
        private insightPassengerService: InsightPassengerService) {
        this.template = this.template.bind(this);
    }

    ngOnInit(): void {
        this.fetchData(this.individualId);
    }

    public fetchData(individualId: string) {
        this.loadCompleted$.next(false);
        forkJoin({
            addressTypeReferences: this.addressTypeReferenceService.getAddressTypeReferences(),
            insightPassenger: individualId ? this.insightPassengerService.getInsightPassenger(individualId) : of<InsightPassengerModel>(null)
        })
            .subscribe(
                ({ addressTypeReferences, insightPassenger }) => {
                    const addressTypeReferenceSelect2Datas = addressTypeReferences
                        ?.sort(this.bySortSequenceThenByName())
                        ?.map(item => new Select2Data(item.addressTypeCode, item.addressTypeName)) || [];
                    const insightPassengerSelect2Datas = insightPassenger?.insightPassengerAddresses?.map(item => ({
                        id: item.addressId,
                        text: item.addressLine1,
                        selected: false,
                        disabled: false,
                        action: item
                    })) || [];
                    const combinedSelect2Datas = [this.EMPTY_SELECT2_DATA,
                    ...insightPassengerSelect2Datas,
                    ...addressTypeReferenceSelect2Datas];
                    this.select2Datas$.next(combinedSelect2Datas);
                    this.loadCompleted$.next(true);
                }
            )
    }

    private bySortSequenceThenByName(): (a: AddressTypeReferenceModel, b: AddressTypeReferenceModel) => number {
        return (a, b) => {
            const maxSortSequence = 9999;
            const aSortSequence = a.sortSequence || maxSortSequence;
            const bSortSequence = b.sortSequence || maxSortSequence;
            if (aSortSequence != bSortSequence) {
                return aSortSequence - bSortSequence;
            }
            return this.byAddressTypeName(a, b);
        };
    }

    private byAddressTypeName(a: AddressTypeReferenceModel, b: AddressTypeReferenceModel): number {
        if (a.addressTypeName < b.addressTypeName) return -1;
        if (a.addressTypeName > b.addressTypeName) return 1;
        return 0;
    }

    public getSelectedText(): string {
        return this.select2Datas$.value?.find(select2Data => select2Data.id == this._value)?.text;
    }

    onPresetSelected(item: InsightPassengerAddressModel) {
        this.presetSelected.emit(item);
    }

    onDataChange(value: any) {
        this.dataChange.emit(value);
    }

    private template(state) {
        return $(`
        <div class="d-table-cell" style="min-width: 150px">${state.action?.addressTypeName || state.text}</div>
        <div class="d-table-cell">${state.action?.addressLine1 || ''}</div>
        `);
    }
}