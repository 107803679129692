import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { DxDataGridComponent } from 'devextreme-angular';
import { AncillaryServiceViewModel } from 'src/app/core/models/product-model/ancillary-service-model';
import { StatusReferenceModel } from 'src/app/core/models/reference-model/reference-general-model';
import { SecurityGroupSecurityModel } from 'src/app/core/models/security-model/security-group-security.model';
import { AncillaryServiceService } from 'src/app/core/services/product-services';
import { ProductCategory } from 'src/app/modules/product-management/product-management-content/product-categories/product-category';
import { FocusingDirective } from 'src/app/shared/ui/forms/inputs/focusing.directive';
import { NavigationService } from 'src/app/shared/utils/navigation';
import { AncillaryServiceSearchTableView } from '../shared/ancillary-service-search-table.view';

@Component({
    selector: 'op-ancillary-service-search-table',
    templateUrl: './ancillary-service-search-table.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class AncillaryServiceSearchTableComponent implements OnChanges, AfterViewInit {
    private readonly USAGE_DATA = 'DATA';
    private readonly USAGE_TEMPLATE = 'TEMPLATE';
    private readonly USAGE_FILTER = 'FILTER';
    private readonly SEARCH_TAB_ID = 'search';

    @Input() productSearchResults: AncillaryServiceSearchTableView[];
    @Input() statusReference: StatusReferenceModel[];
    @Input() selectedProductCategory: ProductCategory;
    @Input() userSecurity: SecurityGroupSecurityModel;
    @Output() onSelected = new EventEmitter<any>();
    @Output() onFilterEdit = new EventEmitter<any>();
    @ViewChild('grid', { static: false }) dataGrid: DxDataGridComponent;
    @ViewChild(FocusingDirective) focusingDirective: FocusingDirective;
    
    selectedItem: any;
    rows = 0;
    exportData: any;
    header: string[];
    public focusing: boolean = false;
    constructor(private changeDetectionRef: ChangeDetectorRef,
        private ancillaryServiceService: AncillaryServiceService,
        private navigationService: NavigationService) { }

    ngOnChanges(changes: SimpleChanges) {
        if (changes['productSearchResults']) {
            this.selectedItem = null;
        }
    }

    ngAfterViewInit(): void {
        this.getDataGridHeader()
    }

    onRowSelected(event) {
        this.selectedItem = event.key;
        this.onSelected.emit(this.selectedItem);
    }

    updateStatus(data: any, e: any) {
        this.ancillaryServiceService.changeStatus(data.id, e)
            .subscribe(
                () => {
                    data.statusCode = e;
                    this.changeDetectionRef.detectChanges();
                }
            )
    }

    public getNumberOfRows(e) {
        this.rows = e.component.totalCount();
        this.getExportData()?.done((filteredData) => {
            this.exportData = this.mapExportData(filteredData);
        });
        this.changeDetectionRef.detectChanges();
    }

    private getExportData() {
        let filterExpr = this.dataGrid.instance.getCombinedFilter();
        let gridDataSource = this.dataGrid.instance.getDataSource();
        return gridDataSource?.store()?.load({
            filter: filterExpr
        });  
    }

    public mapExportData(filteredData) {
        return filteredData.map(dat => (
            {
                No: dat.no,
                Name: dat.name,
                ProductGroup: dat.productGroupName ?? '',
                ProductType: dat.productTypeName,
                UsageType: dat.usageTypeName,
                CommitDateTime: dat.commitDateTime ?? '',
                CommitBy: dat.commitBy ?? '',
                Status: this.getStatusName(dat.statusCode)
            }
        ));
    }

    private getStatusName(statusCode: string): string {
        return this.statusReference?.filter((dat) => dat.statusCode == statusCode)[0]?.displayName;
    }

    private getDataGridHeader() {
        this.header = this.dataGrid.instance.getVisibleColumns().map(item => item.caption);
    }

    public refresh() {
        this.dataGrid.instance.refresh();
    }

    public onDoubleClick(e) {
        this.selectedItem = e.data;
        this.onEdit();
    }

    public onNew() {
        let params = {
            newProduct: true,
            module: this.selectedProductCategory,
            userSecurity: this.userSecurity,
            tab: this.SEARCH_TAB_ID
        }
        this.navigationService.navigate('main/product/ancillary/details', null, null, params)
    }

    public onEdit() {
        if (this.selectedItem == null) {
            return;
        } else if (this.selectedItem.usageTypeCode == this.USAGE_FILTER) {
            this.onFilterEdit.emit(this.selectedItem.id);
            return;
        }

        let params = {
            productId: this.selectedItem.id,
            newProduct: false,
            module: this.selectedProductCategory,
            userSecurity: this.userSecurity,
            tab: this.SEARCH_TAB_ID
        }
        this.navigationService.navigate('main/product/ancillary/details', null, null, params);
        
    }

    public onCopy() {
        if (!this.checkCopy()) {
            return;
        }
        let params = {
            productId: this.selectedItem.id,
            newProduct: true,
            copyProduct: true,
            userSecurity: this.userSecurity,
            tab: this.SEARCH_TAB_ID,
        }
        this.navigationService.navigate('main/product/ancillary/details', null, null, params);
    }

    private checkCopy(): boolean {
        return this.selectedItem?.usageTypeCode == this.USAGE_DATA || this.selectedItem?.usageTypeCode == this.USAGE_TEMPLATE;
    }

    public getDateString(rowData) {
        var date = new Date(rowData.commitDateTime);
        return date.toLocaleString([], { hour12: true, year: 'numeric', month: 'numeric', day: 'numeric', hour: '2-digit', minute: '2-digit'});
    }
}
