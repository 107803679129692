import { BehaviorSubject } from "rxjs";

export class PriceRuleValidityView {
    public dateTimeDimensionCode: string;
    public calendarValidityCode: string;
    public get utcFlag() {
        return this.timezone == 'UTC' ? true : false;
    }
    public priceRuleAttributeGroupCode: string;
    public timezone: string;
    public conditionCode: string = '='
    public startDateTime: string;
    public endDateTime: string;
    public weekdays: string[];
    public months: string[];
    public startValue: string[];
    public endValue: string = "";
    public preSelectedDataList$ = new BehaviorSubject<string[]>(null);
}