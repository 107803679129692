import { Select2Data } from "src/app/shared/ui/forms/inputs/oops-select2";

export class DocumentDistributionRuleView {
    public no: number;
    public documentDistributionRuleId: string;
    public documentDistributionId: string;
    public providerId: string;
    public providerName: string;
    public productNumberFrom: string;
    public productNumberTo: string;
    public serviceDateFrom: string;
    public serviceDateTo: string;
    public originId: string;
    public originName: string;
    public originDataSave: Select2Data[];
    public destinationId: string;
    public destinationName: string;
    public destinationDataSave: Select2Data[];
}