import { 
    PriceRuleAttributeView,
    PriceRuleFormOfPaymentView,
    PriceRuleIndividualView,
    PriceRuleLocationView,
    PriceRuleMembershipView,
    PriceRuleOrganisationView,
    PriceRuleProductNumberView,
    PriceRuleProductView,
    PriceRuleRouteView,
    PriceRuleSalesBucketView,
    PriceRuleServiceCategoryView,
    PriceRuleValidityView,
    PriceRuleVehicleView
} from '.';

export class PriceRuleAttributeAndRuleRowView {
    public type: string;
    public duplicated: boolean = false;
    public hasError: boolean = false;
    public isInherit: boolean = false;
    public productNumber: PriceRuleProductNumberView;
    public route: PriceRuleRouteView;
    public validity: PriceRuleValidityView;
    public location: PriceRuleLocationView;
    public vehicle: PriceRuleVehicleView;
    public attribute: PriceRuleAttributeView;
    public organisation: PriceRuleOrganisationView;
    public serviceCategory: PriceRuleServiceCategoryView;
    public salesBucket: PriceRuleSalesBucketView;
    public individual: PriceRuleIndividualView;
    public membership: PriceRuleMembershipView;
    public product: PriceRuleProductView;
    public formOfPayment: PriceRuleFormOfPaymentView;
}