import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { BehaviorSubject } from "rxjs";
import { IdentityDocumentReferenceModel } from '../../models/reference-model/reference-general-model';
import { IdentityDocumentReferenceService } from '../../services/system-services';
import { Select2Data } from 'src/app/shared/ui/forms/inputs/oops-select2';
import { finalize, map } from 'rxjs/operators';

const COUNTRY_OPTION = {
    placeholder: "",
    allowClear: true,
    width: 'auto',
    dropdownAutoWidth: true,
    minimumInputLength: -1,
    ajax: null
}

@Component({
    selector: 'op-document-type-selection',
    templateUrl: './document-type-selection.component.html'
})
export class DocumentTypeSelectionComponent {
    public _value: string;
    public _isDisable: boolean;
    public documentTypeOption = COUNTRY_OPTION;
    public loadSuccess: boolean = false;

    @Input()
    set data(value: string) {
        this._value = value;
    }

    @Input()
    set disabled(value: boolean) {
        this._isDisable = value;
    }

    @Input() hasError: boolean = false;

    @Output() dataChange: EventEmitter<string> = new EventEmitter();

    identityDocumentTypeReference$ = this.identityDocumentTypeService.getIdentityDocumentReferences()
        .pipe(
            map(res => [this.emptySelect2Data].concat(res?.map(item => new Select2Data(item.identityDocumentCode, item.identityDocumentName)) || [])),
            finalize(() => this.loadSuccess = true))

    private readonly emptySelect2Data: Select2Data = {
        disabled: false,
        id: '',
        selected: false,
        text: ''
    }

    private identityDocumentTypeReferences: Select2Data[];

    constructor(private identityDocumentTypeService: IdentityDocumentReferenceService) {
        this.identityDocumentTypeReference$.subscribe(val => this.identityDocumentTypeReferences = val);
    }

    public onChange($event) {
        this._value = $event;
        this.dataChange.emit($event);
    }

    public getSelectedText(): string {
        if (!this._value) return null;
        return this.identityDocumentTypeReferences
            ?.find(val => val.id == this._value)
            ?.text;
    }
}