import { AfterViewInit, ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, Output, ViewChild } from "@angular/core";
import { OrderDetailIndividualAddressView } from "../../..";
import { cloneDeep } from 'lodash';
import { InsightPassengerAddressModel } from "src/app/core/models/individual-model";
import { NgForm } from "@angular/forms";
import { Subject } from "rxjs";
import { filter, takeUntil } from "rxjs/operators";
import { AddressTypeSelectionComponent } from "src/app/core/components/address-type-selection";
import { CountrySelectionComponent } from "src/app/core/components/country-selection/country-selection.component";
import { isEqual } from "lodash";

@Component({
    selector: 'op-order-detail-address-form',
    templateUrl: './order-detail-address-form.component.html'
})
export class OrderDetailAddressFormComponent implements AfterViewInit, OnDestroy {
    @Input() individualId: string;
    @Input('data') set data(view: OrderDetailIndividualAddressView) {
        this.view = cloneDeep(view);
    }

    @Output() apply = new EventEmitter<OrderDetailIndividualAddressView>();
    @Output() close = new EventEmitter<void>();
    @Output() undo = new EventEmitter();

    @ViewChild(NgForm) form: NgForm;
    @ViewChild(AddressTypeSelectionComponent) addressTypeSelectionComponent: AddressTypeSelectionComponent;
    @ViewChild(CountrySelectionComponent) countrySelectionComponent: CountrySelectionComponent;

    get addressTypeRequired(): boolean {
        return isEqual(this.view, this.getEmptyView()) || this.someFieldsHasValue(this.view);
    }

    view: OrderDetailIndividualAddressView;
    focused: boolean = false;
    processing: boolean = false;

    private destroy$ = new Subject();

    constructor(private changeDetector: ChangeDetectorRef) {

    }

    ngAfterViewInit(): void {
        this.checkIfErrorPresist();
    }

    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
    }

    onPresetSelected(item: InsightPassengerAddressModel) {
        if (!item) return;
        this.view = {
            addressLine1: item.addressLine1,
            addressLine2: item.addressLine2,
            addressTypeCode: item.addressTypeCode,
            addressTypeName: item.addressTypeName,
            city: item.city,
            countryCode: item.countryCode,
            countryName: item.countryName,
            orderAddressRoleCode: this.view.orderAddressRoleCode,
            postalCode: item.postalCode,
            province: item.province,
            state: item.state,
            orderId: null,
            addressId: null,
            primaryFlag: false,
            orderSalesStatusCode: null
        }
    }

    onUndoClick() {
        this.undo.emit();
    }

    onApplyClick() {
        this.processing = true;

        if (!this.form.valid) return;
        if (isEqual(this.view, this.getEmptyView())) return;

        this.view.addressTypeName = this.addressTypeSelectionComponent.getSelectedText();
        this.view.countryName = this.countrySelectionComponent.getSelectedText();
        this.apply.emit(this.view);
    }

    onClearClick() {
        this.resetInputFields();
    }

    onCloseClick() {
        this.close.emit();
    }

    private getEmptyView(): OrderDetailIndividualAddressView {
        return {
            addressLine1: null,
            addressLine2: null,
            addressTypeCode: null,
            addressTypeName: null,
            city: null,
            countryCode: null,
            countryName: null,
            orderAddressRoleCode: null,
            postalCode: null,
            province: null,
            state: null,
            orderId: null,
            addressId: null,
            primaryFlag: null,
            orderSalesStatusCode: null
        };
    }

    private resetInputFields() {
        this.view = this.getEmptyView();
        this.processing = false;
    }

    private checkIfErrorPresist() {
        this.form.valueChanges
            .pipe(
                filter(_ => this.processing == true),
                takeUntil(this.destroy$)
            )
            .subscribe(_ => setTimeout(_ => this.changeDetector.markForCheck()));
    }

    private someFieldsHasValue(view: OrderDetailIndividualAddressView): boolean {
        return Object.keys(view).filter(key => key != 'addressTypeCode').some(key => !!view[key]);
    }
}