import { AfterViewInit, ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, ViewChild } from "@angular/core";
import { BehaviorSubject, Subject } from "rxjs";
import { DocumentDistributionSearchModel } from "src/app/core/models/document-distribution-model/document-distribution-search.model";
import { SecurityGroupSecurityModel } from "src/app/core/models/security-model/security-group-security.model";
import { DocumentDistributionService } from "src/app/core/services/system-services/document-distribution.service";
import { DocumentDistributionSearchTableComponent } from "./document-distribution-search-table/document-distribution-search-table.component";
import { PagingDataView } from "src/app/core/views/pagging-data.view";
import { DistributionTypeReferenceService, DocumentTypeReferenceService, StatusReferenceService } from "src/app/core/services/system-services";
import { DistributionTypeReferenceModel, DocumentTypeReferenceModel, StatusReferenceModel } from "src/app/core/models/reference-model/reference-general-model";
import { OrganisationService } from "src/app/core/services/organisation-services";
import { OrganisationModel } from "src/app/core/models/organisation-model";
import { DocumentDritributionSearchTableView } from "./shared/views/document-distribution-search-table.view";
import { ACTION_STATUS, ActionBarHandlerModel, ActionbarService } from "src/app/shared/ui/actionbar";
import { CancelButtonModel, CopyButtonModel, NewButtonModel, RefreshButtonModel } from "src/app/shared/ui/actionbar/models";
import { takeUntil } from "rxjs/operators";
import { NavigationService } from "src/app/shared/utils/navigation";
import { OopsCategory } from "src/app/core/components/categories";

@Component({
    selector: 'op-document-distribution-search',
    templateUrl: './document-distribution-search.component.html',
})
export class DocumentDistributiobSearchComponent implements OnInit, AfterViewInit {
    private readonly RECEIVE_ORGANISTION_TYPE_CODE = ["AIRLINE", "CUSTOMS","GDS","GROUNDHANDLER", "IMMIGRATION","OTA","APIS"]
    public filters$ = new BehaviorSubject<DocumentDistributionSearchModel>(null);
    public pagingDataView: PagingDataView[];
    public documentDistributionTableData$ = new BehaviorSubject<DocumentDritributionSearchTableView[]>(null);
    private unsubscribe$ = new Subject();
    
    public statusReference$ = new BehaviorSubject<StatusReferenceModel[]>(null);
    public organisation$ = new BehaviorSubject<OrganisationModel[]>(null);
    public documentTypeReference$ = new BehaviorSubject<DocumentTypeReferenceModel[]>(null);
    public distributionTypeReference$ = new BehaviorSubject<DistributionTypeReferenceModel[]>(null);

    @Input() userSecurity: SecurityGroupSecurityModel;
    @Input() executeFlag = true;
    @Input() selectedCategory: OopsCategory;
    @Output() cancelRequest = new EventEmitter();

    @ViewChild(DocumentDistributionSearchTableComponent) searchTable: DocumentDistributionSearchTableComponent;

    private actionBarHandler = new ActionBarHandlerModel(
        new NewButtonModel(),
        new CopyButtonModel(),
        new CancelButtonModel(),
        new RefreshButtonModel()
    )

    public defaultFilter: DocumentDistributionSearchModel = {
        documentDistributionId: null,
        organisationId: null,
        receiverOrganisation: null,
        documentTypeCode: null,
        documentTypeName: null,
        distributionTypeCode: null,
        distributionTypeName: null,
        teletypeAddressElementSender: null,
        teletypeAddressElementReceiver: null,
        mailAddressElementSender: null,
        mailAddressElementReceiver: null,      
        commitBy: null,
        commitByName: null,
        commitDateTime: null,
        statusCode: "A",
        documentDistributionName: null
    }

    constructor(private documentDistributionService: DocumentDistributionService,
        private ref: ChangeDetectorRef,
        private statusReferenceService: StatusReferenceService,
        private distributionTypeReferenceService: DistributionTypeReferenceService,
        private documentTypeReferenceService: DocumentTypeReferenceService,
        private organisationService: OrganisationService,
        private navigationService: NavigationService,
        private actionbarService: ActionbarService,) {
        this.filters$.next(Object.assign({}, this.defaultFilter));
    }

    ngAfterViewInit(): void {
        if (this.userSecurity) {
            this.setupSecurity();
        }

        this.actionbarService.updateState(this.actionBarHandler);
        this.actionbarService.action$.pipe(takeUntil(this.unsubscribe$)).subscribe(
            actionId => {
                this.actionbarClick(actionId);
            }
        )
    }

    ngOnInit(): void {
        this.getStatusReferences();
        this.getReceiverOrganisation();
        this.getDistributionTypeReferences(); 
        this.getDocumentTypeReferences();
        this.searchDocumentDistributions(this.defaultFilter);
    }

    private getStatusReferences() {
        this.statusReferenceService.getAll()
            .subscribe(
                (responses: StatusReferenceModel[]) => {
                    this.statusReference$.next(responses); 
                }
            )
    }

    private getReceiverOrganisation(): any {
        this.organisationService
            .getByOrganisationTypes(this.RECEIVE_ORGANISTION_TYPE_CODE)
            .subscribe(
                (responses: OrganisationModel[]) => {
                    let organistions = responses.filter(x => x.usageTypeCode == 'DATA');
                    this.organisation$.next(organistions.sort((a, b) => this.sortByName(a.organisationCallName.toLowerCase(), b.organisationCallName.toLowerCase())));
                });
    }

    private sortByName(nameA: string, nameB: string): number {
        if (nameA < nameB) {
            return -1;
        }
        if (nameA > nameB) {
            return 1;
        }
        return 0;
    }

    private getDistributionTypeReferences() {
        this.distributionTypeReferenceService.getDistributionTypeReferences()
            .subscribe(
                (responses: DistributionTypeReferenceModel[]) => {
                    this.distributionTypeReference$.next(responses); 
                }
            )
    }

    private getDocumentTypeReferences() {
        this.documentTypeReferenceService.getDocumentTypeReferences()
            .subscribe(
                (responses: DocumentTypeReferenceModel[]) => {
                    this.documentTypeReference$.next(responses); 
                }
            )
    }

    public searchClick(filter: DocumentDistributionSearchModel) {
        this.searchDocumentDistributions(filter);
    }

    public searchDocumentDistributions(filter: DocumentDistributionSearchModel) {
        var dynamicDataArray = new Array();
        
        this.documentDistributionService.search(filter)
        .subscribe(
            (responses: DocumentDistributionSearchModel[]) => {
            this.pagingDataView = new Array();
            var index = 1;
            responses.forEach(doc => {
                this.addPaggingView(index, doc.documentDistributionId, doc.documentDistributionName);
                var attributeTemp  = {};
                attributeTemp["no"] = index;
                attributeTemp["documentDistributionId"] = doc.documentDistributionId;
                attributeTemp["receiverOrganisation"] = doc.receiverOrganisation;
                attributeTemp["documentTypeName"] = doc.documentTypeName;
                attributeTemp["distributionTypeName"] = doc.distributionTypeName;
                attributeTemp["teletypeAddressElementSender"] = doc.teletypeAddressElementSender;
                attributeTemp["teletypeAddressElementReceiver"] = doc.teletypeAddressElementReceiver;
                attributeTemp["mailAddressElementSender"] = doc.mailAddressElementSender;
                attributeTemp["mailAddressElementReceiver"] = doc.mailAddressElementReceiver;
                attributeTemp["commitBy"] = doc.commitBy;
                attributeTemp["commitByName"] = doc.commitByName;
                attributeTemp["commitDateTime"] = doc.commitDateTime;
                attributeTemp["statusCode"] = doc.statusCode;
                attributeTemp["documentDistributionName"] = doc.documentDistributionName;
                dynamicDataArray.push(attributeTemp);
                index += 1;
            });
            
            this.documentDistributionTableData$.next(dynamicDataArray);
            this.ref.detectChanges();
            }
        );
    }

    public addPaggingView(index: number, id: string, documentDistributionName: string) {
        let pview = new PagingDataView();
        pview.no = index;
        pview.id = id;
        pview.name = documentDistributionName;
        this.pagingDataView.push(pview);
    }

    public onClearFilter() {
        this.filters$.next(Object.assign({}, this.defaultFilter));
        this.documentDistributionTableData$.next(null);
        this.searchTable.dataGrid.instance.clearFilter();
    }

    actionbarClick(clickedButton: string) {
        switch (clickedButton) {
            case ACTION_STATUS.back:
                this.cancelRequest.emit();
                break;
            case ACTION_STATUS.new:
                this.newItem();
                break;
            case ACTION_STATUS.copy:
                this.searchTable.copyItem();
                break;
            case ACTION_STATUS.cancel:
                this.cancelRequest.emit();
                break;
            case ACTION_STATUS.refresh:
                this.searchDocumentDistributions(this.filters$.value);
                break;
        }
    }

    back() {
        this.navigationService.navigate('/main/settings/home', null);
    }

    newItem() {
        let param = {
            id: null,
            attributeName: null,
            newFlag: true,
            userSecurity: this.userSecurity,
            paggingView: [],
            searchCondition: this.filters$.value,
            selectedCategoryItem: this.selectedCategory
        }
        this.navigationService.navigate('/main/settings/document-distribution/details', null, null, param);
    }

    editItem(editItem: DocumentDritributionSearchTableView) {
        if (editItem) {
            let param = {
                id: editItem.documentDistributionId,
                attributeName: null,
                newFlag: false,
                userSecurity: this.userSecurity,
                pagingDataView: this.pagingDataView,
                currentIndex: editItem.no,
                searchCondition: this.filters$.value,
                selectedCategoryItem: this.selectedCategory
            }
            this.navigationService.navigate('/main/settings/document-distribution/details', null, null, param);
        }
    }

    copyItem(editItem: DocumentDritributionSearchTableView) {
        if (editItem) {
            let param = {
                id: editItem.documentDistributionId,
                attributeName: null,
                newFlag: false,
                copyFlag: true,
                userSecurity: this.userSecurity,
                pagingDataView: this.pagingDataView,
                currentIndex: editItem.no,
                searchCondition: this.filters$.value,
                selectedCategoryItem: this.selectedCategory
            }
            this.navigationService.navigate('/main/settings/document-distribution/details', null, null, param);
        }
    }

    private setupSecurity() {
        this.resetActionBarState();
        if (!this.userSecurity?.newFlag) {
            this.actionBarHandler.get(ACTION_STATUS.new).disable();
        }
        if (!this.userSecurity?.copyFlag) {
            this.actionBarHandler.get(ACTION_STATUS.copy).disable();
        }
    }
    private resetActionBarState() {
        this.actionBarHandler.get(ACTION_STATUS.new).enable();
        this.actionBarHandler.get(ACTION_STATUS.copy).enable();
    }
}