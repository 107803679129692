import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnDestroy,
    Output,
    ViewChild,
} from '@angular/core';
import {
    Observable,
    Subscriber,
    Subject
} from 'rxjs';

import { FocusingDirective } from "src/app/shared/ui/forms/inputs/focusing.directive";
import { OrderDetailServices } from 'src/app/core/services/order-services';
import { LoadingNotifier } from 'src/app/shared/layout/loading-spinner';
import {
    OrderDetailChildLoadData,
    OrderDetailProductListView,
    OrderDetailProductCommentView,
    OrdersMapperService,
    ProductGroupCodeConstant,
    OrderHeaderLoadData,
    ProductCategoryConstant,
} from '../../../shared';

import {
    OrderProductModel
} from '../../../../../core/models/order-model';
import { StatusReferenceType } from 'src/app/shared/ui/forms/inputs/status-badge/status-reference.type';
import { OrderSalesStatusCode } from 'src/app/core/constants/order-constants';

@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'op-order-detail-product-list',
    templateUrl: './order-detail-product-list.component.html',
    providers: [OrdersMapperService]
})
export class OrderDetailProductListComponent implements OnDestroy {

    public readonly SPINNER_NAME: string = "orderProductList";
    public readonly SPINNER_FULL_SCREEN: boolean = false;
    private readonly PAID_STATUS = ["PAID", "ISSUED"];

    @Input("id") id: string;
    @Input("hideNotExist") hideWhenNotExist: boolean = false;
    @Input() isListView: boolean = false;
    @Input("collapeWhenLoad") collapeWhenLoad: boolean = false;
    @Input("showPanelCounter") showPanelCounter: boolean = false;
    @Input("orderHeaderData") orderHeaderData: OrderHeaderLoadData = null;

    @Input() headerName: string = "Product";

    @Output("childLoadCompleted") childLoadCompleted = new EventEmitter<OrderDetailChildLoadData>();
    @Output() onChangeListView = new EventEmitter();

    @ViewChild("panel") panel: any;
    @ViewChild(FocusingDirective) focusingDirective: FocusingDirective;

    public collapsed: boolean;
    public focused: boolean;
    public loadingNotifier = new LoadingNotifier();
    public orderProductListViews: OrderDetailProductListView[]
        = new Array<OrderDetailProductListView>();
    public orderLock: boolean;

    public StatusReferenceType = StatusReferenceType;
    public countNumberOfItems: number = 0;

    private destroy$: Subject<boolean> = new Subject<boolean>();
    
    constructor(private changeDetection: ChangeDetectorRef,
        private orderDetailService: OrderDetailServices,
        private mapperService: OrdersMapperService) {

    }
    ngOnDestroy(): void {
        this.destroy$.next(true);
        this.destroy$.unsubscribe();
    }

    public getUnderlineClass(index: number): string {
        if (index == 0) {
            return "";
        }
        return "order-detail-start-section";
    }

    public startLoading() {
        this.loadingNotifier.show(this.SPINNER_NAME);
    }

    public stopLoading() {
        this.loadingNotifier.hide(this.SPINNER_NAME);
    }

    public loadIndividualOrderProduct(orderId: string, partnerOrderId: string, orderLock: boolean): Observable<boolean> {
        if (!orderId) {
            return;
        }
        this.orderLock = orderLock;
        return new Observable(observer => {
            this.orderDetailService.getIndividualOrderDetailProduct(orderId, partnerOrderId)
                .subscribe(
                    (result: OrderProductModel[]) => this.processServiceCallResult(result, observer)
                );
        });
    }
    public loadOrderProductFromOrder(parentOrderId: string, orderId: string, orderLock: boolean): Observable<boolean> {

        if (!orderId) {
            return;
        }
        this.orderLock = orderLock;
        return new Observable(observer => {
            this.orderDetailService.getOrderDetailProductsByOrder(parentOrderId, orderId)
                .subscribe(
                    (result: OrderProductModel[]) => this.processServiceCallResult(result, observer)
                );
        });
    }

    public loadOrderProductSearchAtOrderItems(parentOrderId: string, orderId: string, orderLock: boolean): Observable<boolean> {
        return new Observable(observer => {
            if (!orderId) {
                observer.next(false);
                observer.complete();
                return;
            }
            this.orderLock = orderLock;
            this.orderDetailService.getOrderDetailProductsAtOrderItems(parentOrderId, orderId)
                .subscribe(
                    (result: OrderProductModel[]) => this.processServiceCallResult(result, observer)
                );
        });
    }
    public loadOrderProductFromLedger(parentOrderId: string, ledgerId: string, orderLock: boolean): Observable<boolean> {
        if (!ledgerId) {
            return;
        }
        this.orderLock = orderLock;
        return new Observable(observer => {
            this.orderDetailService.getOrderDetailProductsFromLedger(parentOrderId, ledgerId)
                .subscribe(
                    (result: OrderProductModel[]) => this.processServiceCallResult(result, observer)
                );
        });
    }

    public loadProductChildren(parentOrderId: string, productOrderId: string, orderLock: boolean): Observable<boolean> {
        if (!productOrderId) {
            return;
        }
        this.orderLock = orderLock;
        return new Observable(observer => {
            this.orderDetailService.getOrderDetailProductChildren(parentOrderId, productOrderId)
                .subscribe(
                    (result: OrderProductModel[]) => this.processServiceCallResult(result, observer)
                );
        });
    }
    
    private processServiceCallResult(orderProducts: OrderProductModel[], observer: Subscriber<boolean>) {
        
        this.orderProductListViews
            = this.mapperService.toOrderDetailProductListViews(orderProducts);
        this.orderProductListViews = this.filterShowOnlyConfirmOrderProductViews(this.orderProductListViews);
        this.childLoadCompleted.emit({
            recordCount: this.countRecords(orderProducts)
        });
        this.fillNumberOfItems(this.orderProductListViews)
        this.checkCollape();
        this.changeDetection.detectChanges();
        observer.next(true);
        observer.complete();
    }
    private filterShowOnlyConfirmOrderProductViews(orderProducts: OrderDetailProductListView[]) {

        if (!orderProducts) return null;

        if (!this.orderHeaderData || this.orderHeaderData.orderSalesStatusCode == OrderSalesStatusCode.Cancel) {
            return orderProducts;
        }
        
        return orderProducts
            .filter(x => 
                x.orderSalesStatusCode != OrderSalesStatusCode.Cancel && 
                x.orderSalesStatusCode != OrderSalesStatusCode.Exemption
            );
    }
    private checkCollape() {
        if (!this.orderProductListViews || this.orderProductListViews.length == 0 || this.collapeWhenLoad) {
            this.collapsed = true;
            return;
        }
        this.collapsed = false;
    }
    public countRecords(orderProducts: OrderProductModel[]): number {
        if (!orderProducts) {
            return 0;
        }
        return orderProducts.length;
    }
    public commentExist(comments: OrderDetailProductCommentView[]) {

        if (!comments || comments.length == 0) {
            return false;
        }
        return true;
    }

    public showWhenSeat(productGroupCode: string) {
        return ProductGroupCodeConstant.Seat === productGroupCode;
    }

    public collapsedStatus(collapsed: any) {
        this.collapsed = collapsed;
    }

    public togglePanel($event: MouseEvent) {
        this.panel.toggleCollapse($event);
    }
    public seatProduct(orderProduct: OrderDetailProductListView): boolean {
        if (ProductGroupCodeConstant.Seat == orderProduct?.productGroupCode) {
            return true;
        }       
        return false;
    }
    public getProductNumberDisplay(orderProduct: OrderDetailProductListView) {
        if (orderProduct.productGroupCode === ProductGroupCodeConstant.Air) {
            return `${orderProduct?.providerCode} ${orderProduct?.providerNumber}`;
        }
        return orderProduct.productName;
    }
    public getProductNumberLabel(orderProduct: OrderDetailProductListView) {
        if (orderProduct.productGroupCode === ProductGroupCodeConstant.Air) {
            return "Product Number";
        }
        return "Product Name";
    }
    public getProductDateLabel(orderProduct: OrderDetailProductListView) {
        if (orderProduct.productGroupCode === ProductGroupCodeConstant.Seat) {
            return "Seat Number";
        }
        return "Date";
    }
    public getProductDateInformation(orderProduct: OrderDetailProductListView) {
        if (orderProduct.productGroupCode === ProductGroupCodeConstant.Seat) {
            return orderProduct?.seatNumber;
        }
        return orderProduct?.date;
    }
    public hideProductDateWhenNotExist(orderProduct: OrderDetailProductListView) {
        const productDate = this.getProductDateInformation(orderProduct);
        if (!productDate || productDate.length == 0) {
            return true;
        }
        return false;
    }

    public hideProductAncillaryNumberOfUnit(orderProduct: OrderDetailProductListView) {
        if (orderProduct.productCategoryCode == ProductCategoryConstant.Ancillary 
                && orderProduct.productGroupCode != ProductGroupCodeConstant.Seat) {
            return false;
        }
        return true;
    }

    public hideServiceCategoryDateWhenNotExist(orderProduct: OrderDetailProductListView) {
        
        if (!orderProduct?.serviceCategoryName || orderProduct?.serviceCategoryName.length == 0) {
            return true;
        }
        return false;
    }

    public displaySeatInformation(orderProduct: OrderDetailProductListView): boolean {
        if (!orderProduct.seatNumber || orderProduct.seatNumber.length == 0) {
            return false;
        }
        return true;
    }
    public freeTextExist(orderProduct: OrderDetailProductListView) {
        if (!orderProduct?.freetext || orderProduct.freetext.length == 0) {
            return false;
        }
        return true;
    }
    public hidePanel(): boolean {
        if (this.collapsed == true && this.hideWhenNotExist == true) {
            return true;
        }
        return false;
    }

    public bindProductData(orderProducts: OrderProductModel[]) {
        this.orderProductListViews
            = this.mapperService.toOrderDetailProductListViews(orderProducts);
        this.childLoadCompleted.emit({
            recordCount: this.countRecords(orderProducts)
        });

        this.checkCollape();
        this.changeDetection.detectChanges();
    }

    changeListView() {
        this.onChangeListView.emit();
    }

    public getAmountValue(amount: string) {
        if (!amount || Number(amount) === 0) {
            return '';
        }
        return amount;
    }
    
    public getAmountValueByStatusCode(amount: string, orderProduct: OrderDetailProductListView) {
        if (!this.PAID_STATUS.includes(orderProduct?.orderPaymentStatusCode)) {
            return '';
        }

        if (!amount || Number(amount) === 0) {
            return '';
        }

        return amount;
    }

    public getPaidAmountColor(orderProduct: OrderDetailProductListView): string {
        if (Number(orderProduct?.productAmount) == Number(orderProduct?.paymentAmount)) {
            return 'paid-amount-fully-paid'
        }
        return '';
    }

    public getOutstandingBalanceColor(orderProduct: OrderDetailProductListView): string {
        let outstandingBalance = Number(orderProduct?.outstandingBalance.replace(',',''));
        if (outstandingBalance > 0) {
            return 'color-danger-500';
        } else if (outstandingBalance < 0) {
            return 'outstanding-amount-refund'
        }
        return '';
    }

    public getDateChangeText(dayChange: number) {
        if (!dayChange || dayChange == 0) {
            return "";
        }

        let dayChangeDisplay = dayChange > 0 ? '+' + dayChange : dayChange;
        let dayText = `day${Math.abs(dayChange) == 1 ? "" : "s"} `;
        return `${dayChangeDisplay} ${dayText}`
    }
    private fillNumberOfItems(views: OrderDetailProductListView[]) {
        if (!views || views.length === 0) {
            this.countNumberOfItems = 0;
            return;
        }
        this.countNumberOfItems = views.length;
    }
    public showCounter(): boolean {
        return this.showPanelCounter && this.countNumberOfItems > 0;
    }
    public hideFlightRowWhenNotExist(orderDetailProduct: OrderDetailProductListView): boolean {

        if (!orderDetailProduct) {
            return false;
        }

        if (!orderDetailProduct.departureCode && !orderDetailProduct.arrivalCode) {
            return true;
        }
        return false;
    }
    public hideMonitoryRowWhenNotExist(orderDetailProduct: OrderDetailProductListView): boolean {

        if (!orderDetailProduct) {
            return false;
        }

        if (!orderDetailProduct.currencyCode 
            && (!orderDetailProduct.paymentAmount || Number(orderDetailProduct.paymentAmount) == 0)
            && (!orderDetailProduct.productAmount || Number(orderDetailProduct.productAmount) == 0)
            && (!orderDetailProduct.outstandingBalance || Number(orderDetailProduct.outstandingBalance) == 0)) {
            return true;
        }
        return false;
    }
}
