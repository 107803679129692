import { EventEmitter, Component, Input, NgZone, OnInit, Output, QueryList, SimpleChanges, ViewChildren, OnDestroy } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { BehaviorSubject, Subject } from 'rxjs';
import { CalendarValidityReferenceModel } from 'src/app/core/models/reference-model/reference-general-model';
import {
    CalendarValidityService,
    ConditionReferenceService,
    CountryReferenceService,
    DateTimeDimensionReferenceService,
    OrganisationRoleReferenceService,
    OrganisationTypeReferenceService,
    PriceRuleLocationPointService
} from '../../../../../../core/services/system-services';
import { ConditionReferenceModel } from 'src/app/core/models/reference-model/reference-general-model';

import { PricingDetailConstant } from '../../shared/pricing-detail.constant';
import { PricingService } from 'src/app/core/services/pricing-services';
import { Select2Data } from 'src/app/shared/ui/forms/inputs/oops-select2';

import { ProductEligibleRestrictedConverter } from '../product-eligible-restricted/shared/product-eligible-restricted.converter';
import { Select2OptionsService as ProductEligibleSelect2OptionsService } from '../product-eligible-restricted/shared/select2-options.service';
import { 
    PriceRuleAttributeAndRuleRowView,
    PriceRuleLocationView,
    PriceRuleOrganisationView,
    PriceRuleValidityView
} from '../product-eligible-restricted/shared/views';

import { AttributeAndRuleBase } from '../shared/attribute-and-rule.base';
import { Select2Option } from '../shared/attribute-and-rule/views/select2.option';
import {
    LocationReferenceService,
    OrganisationGroupReferenceService,
    RegionReferenceService
} from 'src/app/core/services/airline-services';

import { Select2OptionsService as PointOfSalesSelect2OptionsService } from './shared/select2-options.service'
import { takeUntil } from 'rxjs/operators';
import { OrganisationService } from 'src/app/core/services/organisation-services';

declare var moment: any;

@Component({
    selector: 'op-pricing-point-of-sales',
    templateUrl: './point-of-sales.component.html',
    providers: [
        ProductEligibleRestrictedConverter
    ]
})

export class PointOfSalesComponent extends AttributeAndRuleBase implements OnInit, OnDestroy {

    readonly calendarValidityCode = 'SALE'
    readonly defaultValidityType = 'SALE'
    readonly pointOfSalesGroupCode = 'POINTOFSALES'

    public focusing: boolean = false;

    @Input() set data(val: PriceRuleAttributeAndRuleRowView[]) {
        if (val?.length) {
            this.rows$.next(val);
            this.setPreSelectedItems();
        }
    }
    @Input() disabled: boolean = false;
    @Output() dataChange = new EventEmitter();

    @ViewChildren(NgForm) forms: QueryList<NgForm>;

    public rows$ = new BehaviorSubject<Array<PriceRuleAttributeAndRuleRowView>>(new Array<PriceRuleAttributeAndRuleRowView>());
    public select2DateTimeDimensionDataList$ = new BehaviorSubject<Select2Data[]>(null);
    public select2LocationPointDataList$ = new BehaviorSubject<Select2Data[]>(null);
    public select2IsIsNotConditionDataList$ = new BehaviorSubject<Select2Data[]>(null);
    public select2CalendarValidityDataList$ = new BehaviorSubject<Select2Data[]>(null);
    public select2IsIsNotIsBetweenIsNotBetweenConditionDataList$ = new BehaviorSubject<Select2Data[]>(null);

    public select2RootOption = new Select2Option('<Type>');
    public select2RootDataList = [
        new Select2Data(PricingDetailConstant.LOCATION_CODE, "Location"),
        new Select2Data(PricingDetailConstant.ORGANISATION_CODE, 'Organisation'),
        new Select2Data(PricingDetailConstant.CALENDAR_CODE, 'Calendar')
    ];
    public select2DateTimeDimensionOption = new Select2Option('<Date Type>');
    public select2LocationPointOption = new Select2Option('<O/D>');
    public select2LocationTypeOption = new Select2Option('<Area>');
    public select2LocationTypeDataList = [
        new Select2Data(PricingDetailConstant.REGION_VALUE, 'Region'),
        new Select2Data(PricingDetailConstant.COUNTRY_VALUE, 'Country'),
        new Select2Data(PricingDetailConstant.AIRPORT_CITY_VALUE, 'Airport/City'),
        new Select2Data(PricingDetailConstant.LOCATION_GROUP_VALUE, 'Location Group')
    ];
    public select2TimezoneOption = new Select2Option('<Timezone>');
    public select2TimezoneDataList = [
        new Select2Data(),
        new Select2Data(PricingDetailConstant.UTC_CODE, "UTC"),
        new Select2Data(PricingDetailConstant.LOCAL_CODE, "Local")
    ]

    public dateRangePickerDateOption: Select2Option;
    public dateRangePickerDateTimeOption: Select2Option;
    public dateRangePickerTimeOption: Select2Option;
    public dateRangePickerEndDateOptions = [];
    public dateRangePickerEndDateTimeOptions = [];
    public dateRangePickerEndTimeOptions = [];
    public select2WeekdayOption: Select2Option;
    public select2WeekdayDataList = PricingDetailConstant.SELECT2_WEEKDAY_DATALIST;

    public select2OrganisationTypeOption = new Select2Option('<Type>');
    public select2OrganisationTypeDataList = [
        new Select2Data(PricingDetailConstant.POINT_OF_SALES_TYPE, 'Type'),
        new Select2Data(PricingDetailConstant.POINT_OF_SALES_ROLE, 'Role'),
        new Select2Data(PricingDetailConstant.POINT_OF_SALES_GROUP, 'Group'),
        new Select2Data(PricingDetailConstant.POINT_OF_SALES_SPECIFIC, 'Specific')
    ]

    public select2IsIsNotConditionOption: Select2Option;
    public select2RegionOption: Select2Option;
    public select2CountryOption: Select2Option;
    public select2AirportCityOption: Select2Option;
    public select2LocationGroupOption: Select2Option;
    public select2OrganisationTypeOrganisationTypeOption: Select2Option;
    public select2OrganisationTypeOrganisationRoleOption: Select2Option;
    public select2OrganisationTypeOrganisationGroupOption: Select2Option;
    public select2OrganisationTypeOrganisationIdOption: Select2Option;
    public select2IsIsNotIsBetweenIsNotBetweenConditionOption: Select2Option;
    public select2CalendarValidityOption: Select2Option;


    private unsubscribe$ = new Subject();

    constructor(
        private calendarValidityService: CalendarValidityService,
        private conditionReferenceService: ConditionReferenceService,
        private countryService: CountryReferenceService,
        private datetimeDimensionService: DateTimeDimensionReferenceService,
        private locationReferenceService: LocationReferenceService,
        private organisationRoleReferenceService: OrganisationRoleReferenceService,
        private organisationTypeService: OrganisationTypeReferenceService,
        private productEligibleConverter: ProductEligibleRestrictedConverter,
        private prodEligSelect2OptionsService: ProductEligibleSelect2OptionsService,
        private priceRuleLocationPointService: PriceRuleLocationPointService,
        private posSelect2OptionsService: PointOfSalesSelect2OptionsService,
        private regionService: RegionReferenceService,
        private service: PricingService,
        private organisationGroupReferenceService: OrganisationGroupReferenceService,
        private toastrService: ToastrService,
        private organisationService: OrganisationService) {
        super();
    }

    ngOnDestroy(): void {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }

    private setPreSelectedItems() {
        this.dateRangePickerEndDateOptions = [];
        this.dateRangePickerEndDateTimeOptions = [];
        this.dateRangePickerEndTimeOptions = [];
        for (let row of this.rows$.value) {
            if (row.organisation?.organisationTypeCode) {
                this.getOrganisationTypeReferencePreSelected(row.organisation?.organisationTypeCode,
                    (data) => {
                        row.organisation.preSelectedDataList$.next(data)
                    });
            }
            if (row.organisation?.organisationGroupCode) {
                this.getOrganisationGroupReferencePreSelected(row.organisation?.organisationGroupCode,
                    (data) => {
                        row.organisation.preSelectedDataList$.next(data)
                    });
            }
            if (row.organisation?.organisationRoleCode) {
                this.getOrganisationRoleReferencePreSelected(row.organisation?.organisationRoleCode,
                    (data) => {
                        row.organisation.preSelectedDataList$.next(data)
                    });
            }
            if (row.organisation?.organisationId) {
                this.getOrganisationIdPreSelected(row.organisation?.organisationId,
                    (data) => {
                        row.organisation.preSelectedDataList$.next(data)
                    });
            }
            if (row.location?.regionCode) {
                this.getRegionReferencePreSelected(row.location?.regionCode,
                    (regions: Select2Data[]) => {
                        row.location.preSelectedDataList$.next(regions)
                    })
            }
            if (row.location?.countryCode) {
                this.getCountryReferencePreSelected(row.location?.countryCode,
                    (countries: Select2Data[]) => {
                        row.location.preSelectedDataList$.next(countries)
                    })
            }
            if (row.location?.locationId) {
                this.getLocationReferencePreSeleted(row.location?.locationId,
                    (locations: Select2Data[]) => {
                        row.location.preSelectedDataList$.next(locations)
                    })
            }
            if (row.location?.locationGroupId) {
                this.getLocationGroupPreSeleted(row.location?.locationGroupId,
                    (locationGroups: Select2Data[]) => {
                        row.location.preSelectedDataList$.next(locationGroups)
                    })
            }
            this.dateRangePickerEndDateOptions.push(this.prodEligSelect2OptionsService.getDatePickerOption());
            this.dateRangePickerEndDateTimeOptions.push(this.prodEligSelect2OptionsService.getDatetimePickerOption());
            this.dateRangePickerEndTimeOptions.push(this.prodEligSelect2OptionsService.getTimePickerOption());
        }
        this.togglePanelCollapseStatus(false);
    }

    public getLocationGroupPreSeleted(ids: string[], callback) {
        this.service.getLocationGroup(ids)
            .pipe(takeUntil(this.unsubscribe$)).subscribe(
                res => {
                    callback(res.map(r => new Select2Data(r.locationGroupId, r.locationGroupName)));
                }
            )
    }

    public getCountryReferencePreSelected(codes: string[], callback) {
        this.countryService.getCountryReference(codes)
            .pipe(takeUntil(this.unsubscribe$)).subscribe(
                res => {
                    callback(res.map(r => new Select2Data(r.countryCode, r.countryName)))
                }
            )
    }

    public getRegionReferencePreSelected(id: string[], callback) {
        this.regionService.getRegionReference(id)
            .pipe(takeUntil(this.unsubscribe$)).subscribe(
                res => {
                    callback(res.map(r => new Select2Data(r.regionCode, r.regionName)));
                }
            )
    }

    private getLocationReferencePreSeleted(id: string[], callback) {
        this.locationReferenceService.getLocationReference(id)
            .pipe(takeUntil(this.unsubscribe$)).subscribe(
                res => {
                    callback(res.map(r => new Select2Data(r.locationId, r.locationName)));
                }
            )
    }

    private getOrganisationTypeReferencePreSelected(ids: string[], callback) {
        this.organisationTypeService.getOrganisationTypeReference(ids)
            .pipe(takeUntil(this.unsubscribe$)).subscribe(
                res => {
                    callback(res.map(r => new Select2Data(r.organisationTypeCode, r.organisationTypeName)));
                }
            )
    }
    private getOrganisationGroupReferencePreSelected(id: string[], callback) {
        this.organisationGroupReferenceService.getOrganisationGroupReference(id)
            .pipe(takeUntil(this.unsubscribe$)).subscribe(
                res => {
                    callback(res.map(r => new Select2Data(r.organisationGroupCode, r.organisationGroupName)));
                }
            )
    }
    private getOrganisationRoleReferencePreSelected(codes: string[], callback) {
        this.organisationRoleReferenceService.getByCodes(codes)
            .pipe(takeUntil(this.unsubscribe$)).subscribe(
                res => {
                    callback(res.map(r => new Select2Data(r.organisationRoleCode, r.organisationRoleName)));
                }
            )
    }
    private getOrganisationIdPreSelected(codes: string[], callback) {
        this.organisationService
            .getByStatus('A')
            .pipe(takeUntil(this.unsubscribe$)).subscribe(
                res => {
                    callback(res.map(r => new Select2Data(r.organisationId, r.organisationCallName)));
                }
            )
    }

    ngOnInit(): void {
        this.select2RegionOption = this.prodEligSelect2OptionsService.getSelect2RegionOption();
        this.select2CountryOption = this.prodEligSelect2OptionsService.getSelect2CountryOption();
        this.select2AirportCityOption = this.prodEligSelect2OptionsService.getSelect2AirportCityOption();
        this.select2LocationGroupOption = this.prodEligSelect2OptionsService.getSelect2LocationGroupOption();
        this.select2IsIsNotConditionOption = this.prodEligSelect2OptionsService.getSelect2ConditionOption();
        this.select2IsIsNotIsBetweenIsNotBetweenConditionOption = this.prodEligSelect2OptionsService.getSelect2ConditionOption();
        this.select2CalendarValidityOption = this.prodEligSelect2OptionsService.getSelect2CalendarValidityOption();
        this.dateRangePickerDateOption = this.prodEligSelect2OptionsService.getDatePickerOption();
        this.dateRangePickerDateTimeOption = this.prodEligSelect2OptionsService.getDatetimePickerOption();
        this.dateRangePickerTimeOption = this.prodEligSelect2OptionsService.getTimePickerOption();
        this.select2WeekdayOption = this.prodEligSelect2OptionsService.getSelect2WeekdayOption();
        this.select2OrganisationTypeOrganisationTypeOption = this.posSelect2OptionsService.getSelect2OrganisationTypeOrganisationTypeOption();
        this.select2OrganisationTypeOrganisationRoleOption = this.posSelect2OptionsService.getSelect2OrganisationTypeOrganisationRoleOption();
        this.select2OrganisationTypeOrganisationGroupOption = this.posSelect2OptionsService.getSelect2OrganisationTypeOrganisationGroupOption();
        this.select2OrganisationTypeOrganisationIdOption = this.posSelect2OptionsService.getSelect2OrganisationTypeOrganisationIdOption();

        this.getCalendarValidityReference();
        this.getSelect2IsIsNotConditionDataList();
        this.getPriceRuleLocationPointReference();
        this.getDateTimeDimensionReference();
        this.getSelect2IsIsNotIsBetweenIsNotBetweenConditionDataList();
    }
    add() {
        if (this.validForms() && this.noDuplicatedRowFound()) {
            this.addErrorFlag = false;
            let rows = this.rows$.value
            rows.push(new PriceRuleAttributeAndRuleRowView());
            this.rows$.next(rows);
        } else {
            this.addErrorFlag = true;
        }
        this.togglePanelCollapseStatus(false);
    }

    onCalendarConditionChange(event, row: PriceRuleAttributeAndRuleRowView) {
        if (row.validity.conditionCode != event) {
            row.validity.endDateTime = '';
            row.validity.endValue = '';
        }
        this.onValueChange(event, row.validity, 'conditionCode');
    }

    onRootDataChange(event, row: PriceRuleAttributeAndRuleRowView) {
        if (row.type != event) {
            this.reset(row);
        }
        row.type = event;
        if (row.type == PricingDetailConstant.ORGANISATION_CODE) {
            row.organisation = row.organisation ?? new PriceRuleOrganisationView();
            row.organisation.priceRuleOrganisationTypeCode = PricingDetailConstant.SALES_CODE;
        } else if (row.type == PricingDetailConstant.LOCATION_CODE) {
            row.location = row.location ?? new PriceRuleLocationView();
            row.location.priceRuleLocationTypeCode = PricingDetailConstant.SALES_CODE
        } else if (row.type == PricingDetailConstant.CALENDAR_CODE) {
            row.validity = row.validity ?? new PriceRuleValidityView();
            row.validity.priceRuleAttributeGroupCode = this.pointOfSalesGroupCode
            row.validity.calendarValidityCode = this.defaultValidityType
        }
    }

    onLocationTypeChange(event, row: PriceRuleAttributeAndRuleRowView) {
        if (row.location.locationType != event) {
            row.location = new PriceRuleLocationView();
            row.location.locationType = event;
            row.location.priceRuleLocationTypeCode = PricingDetailConstant.SALES_CODE;
        }
    }

    private getSelect2IsIsNotConditionDataList() {
        if (this.select2IsIsNotConditionDataList$.value?.length) {
            return;
        }
        this.getConditionReference([PricingDetailConstant.IS_OPERATOR_CODE,
        PricingDetailConstant.IS_NOT_OPERATOR_CODE], (res) => {
            this.select2IsIsNotConditionDataList$.next(this.productEligibleConverter.toConditionReferenceSelect2DataList(res))
        });
    }

    private getPriceRuleLocationPointReference() {
        if (this.select2LocationPointDataList$.value?.length) {
            return;
        }
        this.priceRuleLocationPointService.getProductLocationPointReference()
            .pipe(takeUntil(this.unsubscribe$)).subscribe(
                res => {
                    this.select2LocationPointDataList$.next(this.productEligibleConverter.toLocationPointReferenceDataList(res))
                }
            )
    }

    private getConditionReference(conditionCodes: string[], callback) {
        this.conditionReferenceService.getConditionsByCodes(conditionCodes)
            .pipe(takeUntil(this.unsubscribe$)).subscribe(
                (res: ConditionReferenceModel[]) => {
                    callback(res);
                },
                err => {
                    this.toastrService.error(PricingDetailConstant.FAILED_GET_CONDITION_REFERENCE, PricingDetailConstant.ALERT_HEADER);
                }
            );
    }

    private reset(row) {
        delete row.organisation;
        delete row.location;
        delete row.validity;
    }

    private getDateTimeDimensionReference() {
        if (this.select2DateTimeDimensionDataList$.value?.length) {
            return;
        }
        this.datetimeDimensionService.getDateTimeDimensionReference(PricingDetailConstant.CALENDAR_DATE,
            PricingDetailConstant.CALENDAR_DATE_TIME,
            PricingDetailConstant.CALENDAR_TIME,
            PricingDetailConstant.CALENDAR_WEEKDAY)
            .pipe(takeUntil(this.unsubscribe$)).subscribe(
                res => {
                    this.select2DateTimeDimensionDataList$.next(this.productEligibleConverter.toDateTimeDimensionReferenceDataList(res))
                }
            )
    }

    onDateTimeDimensionChange(event, row: PriceRuleAttributeAndRuleRowView) {
        if (row.validity.dateTimeDimensionCode != event) {
            row.validity = new PriceRuleValidityView();
            row.validity.dateTimeDimensionCode = event;
            row.validity.priceRuleAttributeGroupCode = this.pointOfSalesGroupCode
            row.validity.calendarValidityCode = this.defaultValidityType
        }
    }

    private getSelect2IsIsNotIsBetweenIsNotBetweenConditionDataList() {
        if (this.select2IsIsNotIsBetweenIsNotBetweenConditionDataList$.value?.length) {
            return;
        }
        this.getConditionReference([PricingDetailConstant.IS_OPERATOR_CODE,
        PricingDetailConstant.IS_NOT_OPERATOR_CODE,
        PricingDetailConstant.IS_BETWEEN_OPERATOR_CODE,
        PricingDetailConstant.IS_NOT_BETWEEN_OPERATOR_CODE], (res) => {
            this.select2IsIsNotIsBetweenIsNotBetweenConditionDataList$.next(this.productEligibleConverter.toConditionReferenceSelect2DataList(res))
        });
    }

    private getCalendarValidityReference() {
        if (this.select2CalendarValidityDataList$.value?.length) {
            return;
        }
        this.calendarValidityService.getByCalendarValidityCodes(this.calendarValidityCode)
            .pipe(takeUntil(this.unsubscribe$)).subscribe(
                (res: CalendarValidityReferenceModel[]) => {
                    this.select2CalendarValidityDataList$.next(this.productEligibleConverter.toCalendarValidityReferenceDataList(res));
                },
                err => {
                    this.toastrService.error(PricingDetailConstant.FAILED_GET_CALENDAR_VALIDITY_REFERENCE, PricingDetailConstant.ALERT_HEADER)
                }
            )
    }

    onOrganisationTypeChange(organisationType, row) {
        if (row.organisation?.type != organisationType) {
            let organisation = new PriceRuleOrganisationView();
            organisation.priceRuleOrganisationTypeCode = PricingDetailConstant.SALES_CODE;
            organisation.type = organisationType;
            row.organisation = organisation;
        }
    }

    public attemptToSubmit() {
        if (this.validForms() && this.noDuplicatedRowFound()) {
            this.addErrorFlag = false;
            this.dataChange.emit(this.rows$.value);
            return true;
        } else {
            this.addErrorFlag = true;
            return false;
        }
    }

    public onStartDateTimeChange(row: any, value: any) {
        row.validity.startDateTime = value;
        this.resetErrorFlags();

        let index = this.rows$.value.indexOf(row);
        let option: Select2Option = null;
        switch (row.validity.dateTimeDimensionCode) {
            case 'DATE':
                option = this.updateDateOption(option, value, index);
                break;
            case 'DATETIME':
                option = this.updateDateTimeOption(option, value, index);
                break;
            case 'TIME':
                option = this.updateTimeOption(option, value, index);
                break;
            default:
                break;
        }

        this.setEndDateTime(row, value, option);
    }

    private updateTimeOption(option: Select2Option, value: any, index: number) {
        option = this.prodEligSelect2OptionsService.getTimePickerOption();
        if (value) {
            option['minDate'] = value;
        }
        this.dateRangePickerEndTimeOptions[index] = option;
        return option;
    }

    private updateDateTimeOption(option: Select2Option, value: any, index: number) {
        option = this.prodEligSelect2OptionsService.getDatetimePickerOption();
        if (value) {
            option['minDate'] = value;
        }
        this.dateRangePickerEndDateTimeOptions[index] = option;
        return option;
    }

    private updateDateOption(option: Select2Option, value: any, index: number) {
        option = this.prodEligSelect2OptionsService.getDatePickerOption();
        if (value) {
            option['minDate'] = value;
        }
        this.dateRangePickerEndDateOptions[index] = option;
        return option;
    }

    private setEndDateTime(row: any, value: any, option: Select2Option) {
        let endDateTime = row.validity.endDateTime;
        if (!endDateTime || moment(value, option.locale.format) > moment(endDateTime, option.locale.format)) {
            row.validity.endDateTime = value;
        }
    }

    public onEndDateTimeChange(row: any, value: any) {
        row.validity.endDateTime = value;
        this.resetErrorFlags();
    }

    public deleteRow(row, index) {
        super.delete(row);
        this.dateRangePickerEndDateOptions = this.dateRangePickerEndDateOptions.splice(index, 1);
        this.dateRangePickerEndDateTimeOptions = this.dateRangePickerEndDateTimeOptions.splice(index, 1);
        this.dateRangePickerEndTimeOptions = this.dateRangePickerEndTimeOptions.splice(index, 1);
    }

    public onValueChange(value: any, field: any, fieldName: string) {
        field[fieldName] = value;
        this.resetErrorFlags();
    }
}
