import { Injectable } from '@angular/core';
import moment from 'moment';

import { DateConverterService } from './date-converter.service';

@Injectable({
    providedIn: 'root'
})
export class DateHelperService {

    constructor(private dateConversionService: DateConverterService) {

    }
    public DayDifferent(date1: Date, date2: Date): number {

        const dateOnly1 = new Date(date1.getFullYear(), date1.getMonth(), date1.getDate());
        const dateOnly2 = new Date(date2.getFullYear(), date2.getMonth(), date2.getDate());
        const timeDifferent = dateOnly2.getTime() - dateOnly1.getTime();
        return timeDifferent / (1000 * 3600 * 24);
    }

    public addDay(dateValue: Date, numberOfDay: number): Date {
        
        return moment(dateValue)
            .add(numberOfDay, 'd')
            .toDate();
    }

    public getEarlyDate(...dates: Date[]): Date {
        return dates?.reduce((a, b) => {
            if (!a || b < a) return b;
            return a;
        }, null);
    }

    public getLateDate(...dates: Date[]): Date {
        return dates?.reduce((a, b) => {
            if (!a || b > a) return b;
            return a;
        }, null);
    }
    public passCurrentDate(date: Date): boolean {

        const dateValue = this.dateConversionService.dateToDateWithoutTime(date);
        const currentDate = this.dateConversionService
            .dateToDateWithoutTime(new Date());

        if (currentDate > dateValue) {
            return true;
        }
        return false;
    }
}