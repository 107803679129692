import {
    Component,
    ChangeDetectionStrategy,
    Input,
    ViewChild,
    ComponentFactoryResolver,
    ElementRef,
    ChangeDetectorRef,
    Output,
    EventEmitter,
} from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { cloneDeep } from "lodash";

import { UiHelperService } from 'src/app/core/utils/ui-helper.service';

import { MediaView } from './shared/media.view';
import { MediaDetailComponent } from './media-detail/media-detail.component';
import { MediaDetailDirective } from './media-detail/media-detail.directive';
import { MediaTableComponent } from './media-table/media-table.component';
import {
    LanguageReferenceModel,
    MediaUseReferenceModel,
    MediaTypeReferenceModel,
    MediaTypeFileTypeModel
} from 'src/app/core/models/reference-model/reference-media-model';

import { Select2Data } from 'src/app/shared/ui/forms/inputs/oops-select2';
import { MediaMapperService } from './shared/media-mapper.service';
import { MediaLibraryTableDirective } from './media-library-table/media-library-table.directive';
import { MediaLibraryTableComponent } from './media-library-table/media-library-table.component';
import { FocusingDirective } from 'src/app/shared/ui/forms/inputs/focusing.directive';
import { MediaSearchCommandModel, MediaViewModel } from 'src/app/core/models/media-model';
import { MediaService } from 'src/app/core/services/media-services';

declare var $: any;

@Component({
    selector: 'op-media',
    templateUrl: './media.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [MediaMapperService]
})
export class MediaComponent {

    @Input() id: string;
    @Input() languageReference$ = new BehaviorSubject<LanguageReferenceModel[]>(null);
    @Input() mediaTypeReference$ = new BehaviorSubject<MediaTypeReferenceModel[]>(null);
    @Input() mediaUseReference$ = new BehaviorSubject<MediaUseReferenceModel[]>(null);
    @Input() mediaHashTagSelect2Data: Select2Data[];
    @Input() mediaTypeFileType$ = new BehaviorSubject<MediaTypeFileTypeModel[]>(null);
    @Input() disabled: boolean = false;

    @Output() mediaLibrarySelected = new EventEmitter<MediaViewModel[]>();
    @Output() mediaIdDeleted = new EventEmitter<String>();
    @Output() cancelTextUpload = new EventEmitter();
    @Output() saveTextImageUpload = new EventEmitter<String>();
    @Output() saveTextVideoUpload = new EventEmitter<String>();
    @Output() cancelTextSelectLibrary = new EventEmitter();
    @Output() saveTextSelectImageLibrary = new EventEmitter<String[]>();
    @Output() saveTextSelectVideoLibrary = new EventEmitter<String[]>();

    @ViewChild(FocusingDirective) focusingDirective: FocusingDirective;
    @ViewChild("mediaDetailRef") detailElement: ElementRef;
    @ViewChild("mediaRef") codeElement: ElementRef;
    @ViewChild("mediaDetailLibraryTableRef") mediaLibraryElement: ElementRef;

    @ViewChild(MediaDetailDirective) mediaDetailDirective: MediaDetailDirective;
    @ViewChild(MediaTableComponent) mediaTable: MediaTableComponent;
    @ViewChild(MediaDetailComponent) mediaDetailComponent: MediaDetailComponent;
    @ViewChild(MediaLibraryTableDirective) mediaLibraryTableDirective: MediaLibraryTableDirective;
    @ViewChild(MediaLibraryTableComponent) mediaLibraryTableComponent: MediaLibraryTableComponent;
    private mediaDetailComponentDirective: MediaDetailComponent;

    private readonly DELAY_TIME = 400;
    public singleRecord: boolean = true;
    public miniMizePanel: boolean = false;
    public miniMizeDetailPanel: boolean = false;
    public isCollapsedDetail: boolean = true;
    public enabledEdit: boolean = false;
    public model = new MediaView();
    public mediaFiles: MediaView[] = new Array();
    public showMainBrowse: boolean = true;
    public mediaNotCompleted: boolean = false;
    public textImageUploadProcessing: boolean = false;
    public textVideoUploadProcessing: boolean = false;
    public textImageSelectLibraryProcessing: boolean = false;
    public textVideoSelectLibraryProcessing: boolean = false;
    public isCollapsedMediaLibrary: boolean = true;
    public mediaSearchCommand: MediaSearchCommandModel;
    public isEditMode = false;
    public focusing: boolean = false;
    private rowSelectedItem: MediaView;

    constructor(private changeDectionRef: ChangeDetectorRef,
        private componentFactoryResolver: ComponentFactoryResolver,
        private uiHelper: UiHelperService,
        private mediaService: MediaService,
        private mediaMapperService: MediaMapperService) {
    }

    get isShowAdd(): boolean {
        let isShow = false;
        if (this.mediaFiles.length > 1) {
            isShow = true;
        } else if (this.singleRecord && this.mediaFiles.length == 1) {
            isShow = true;
        }
        return isShow;
    }

    get isShowMainBrowse(): boolean {
        this.showMainBrowse = !this.isShowAdd;
        return this.showMainBrowse;
    }

    get isMainBrowseEnabled() : boolean {
        if (!!(this.model.mediaUseCode) && this.singleRecord && this.isCollapsedMediaLibrary) {
            return true;
        }
        return false;
    }

    get isSecondBrowseEnabled(): boolean {
        return this.mediaDetailComponentDirective && !!(this.mediaDetailComponentDirective.model.mediaUseCode && this.isCollapsedMediaLibrary);
    }

    public browse() {
        this.mediaDetailComponent.browseLibraryClick();
    }

    public browseByDirective() {
        this.mediaDetailComponentDirective.browseLibraryClick();
    }

    public add() {
        this.changeToMultiRecord().then(() => {
            this.showDetailDirective();
            this.loadDetailForm();
            this.scrollToDetailPosition();
        });
    }

    addWithSearchCommand(searchCommand: MediaSearchCommandModel) {
        this.changeToMultiRecord().then(() => {
            if (searchCommand) {
                this.model = new MediaView();
                this.model.mediaUseCode = searchCommand.mediaUseCode;
                this.model.mediaTypeCode = searchCommand.mediaTypeCode;
                this.showDetailDirective();
                
                this.loadDetailForm(this.model);
            }
        });
    }

    addDisplayMediaLibrary(searchCommand: MediaSearchCommandModel) {
        if (!this.mediaFiles?.length) {
            this.displayMediaLibraryInSingleRecord(searchCommand)
        }
        else {
            this.displayMediaLibraryInMultiRecord(searchCommand);
        }
    }

    public edit() {
        this._edit(this.rowSelectedItem);
    }

    private _edit(data: MediaView) {
        if (data) {
            this.isEditMode = true;
            this.showDetailDirective();
            this.loadDetailForm(data);
            this.scrollToDetailPosition();
        }
    }

    public save() {
        this.mediaDetailComponent.save();
    }

    public saveByDirective() {
        this.mediaDetailComponentDirective.save();
    }

    public saveData(data: MediaView) {
        if (data) {
            if (data.textImage) {
                this.textImageUploadCompleted(data.mediaContentId);
            } else if (data.textVideo) {
                this.textVideoUploadCompleted(data.mediaContentId);
            }
            this.savedata(data);
            this.displayAfterSave();
            this.validateMediaCompletedAll();
            this.isEditMode = false;
            this.changeDectionRef.detectChanges();
        }
    }

    saveMultipleFile(addedFiles: File[]) {
        this.mediaFiles = this.mediaMapperService.convertFilesToMediaViews(this.mediaFiles, addedFiles,
            this.languageReference$, this.mediaTypeFileType$, this.mediaTypeReference$, this.mediaUseReference$);
        this.displayAfterSaveMultiple();
        this.mediaNotCompleted = true;
        this.mediaTable.dataGrid.instance.refresh();
    }

    public delete() {
        if (this.rowSelectedItem) {
            this._delete(this.rowSelectedItem);
        }
    }

    private _delete(data: MediaView) {
        this.deletedata(data);
        this.displayAfterDelete();
        this.validateMediaCompletedAll();
        this.rowSelectedItem = null;
        if (data.mediaId) {
            this.deleteTextTranslation(data.mediaId);
        }
        else {
            this.deleteTextTranslation(data.mediaTranslationId);
        }
        this.changeDectionRef.detectChanges();
    }

    public cancel() {
        this.model = new MediaView();
        if (this.singleRecord) {
            this.mediaFiles = [];
            this.showMainBrowse = true;
        }
        this.isEditMode = false;
    }

    public cancelByDirective() {
        this.isEditMode = false;
        this.hideDetailDirective();
        this.model = new MediaView();
        if (this.mediaFiles.length == 1) {
            this.singleRecord = true;
            this.model = this.mediaFiles[0];
        }
    }

    public rowSelected(data: MediaView) {
        this.rowSelectedItem = data;
        this.enabledEdit = data.mediaName ? true : false;
    }

    private showDetailDirective() {
        if (this.isCollapsedDetail) {
            this.isCollapsedDetail = false;
            this.changeDectionRef.markForCheck();
        }
    }

    private hideDetailDirective() {
        if (!this.isCollapsedDetail) {
            this.isCollapsedDetail = true;
            this.formDetailClose();
            this.scrollToSearch();
        }
    }

    private scrollToSearch() {
        this.uiHelper.moveScrollPosition(this.codeElement, 280);
    }

    private scrollToDetailPosition() {
        setTimeout(() => {
            this.uiHelper.moveScrollPosition(this.detailElement, 280);
        }, this.DELAY_TIME);
        
    }

    private scrollToMediaLibraryTable() {
        setTimeout(() => {
            this.uiHelper.moveScrollPosition(this.mediaLibraryElement, 300);
        }, this.DELAY_TIME);
        
    }

    private loadDetailForm(dataEdit: MediaView = null) {
        let componentFactory = this.componentFactoryResolver
            .resolveComponentFactory(MediaDetailComponent);

        let viewContainerRef = this.mediaDetailDirective.viewContainerRef;
        viewContainerRef.clear();

        let componentRefs = viewContainerRef.createComponent(componentFactory);
        let component: MediaDetailComponent = (<MediaDetailComponent>componentRefs.instance);

        this.assignInitialData(component);
        this.assignMediaModel(component, dataEdit);
        this.onDetailDirectiveSaveEvent(component);
        this.onDetailDirectiveSaveMultitpleFileEvent(component);
        this.onDetailDirectiveCancelEvent(component);
        this.onDetailDirectiveClickMediaLibraryEvent(component);
        this.onDetailDirectiveCancelTextUploadProcessingEvent(component);
        this.onDetailDirectiveClickMediaLibraryFromTextPanelEvent(component);
        this.onDetailDirectiveSaveToDBEvent(component);
        this.mediaDetailComponentDirective = component;
    }

    private assignInitialData(component: MediaDetailComponent) {
        component.languageReference$ = this.languageReference$;
        component.mediaTypeReference$ = this.mediaTypeReference$;
        component.mediaUseReference$ = this.mediaUseReference$;
        component.mediaHashTagSelect2Data = this.mediaHashTagSelect2Data;
        component.mediaTypeFileType$ = this.mediaTypeFileType$;
        component.singleRecord = false;
        component.textImageUploadProcessing = this.textImageUploadProcessing;
        component.textVideoUploadProcessing = this.textVideoUploadProcessing;
        if (component.textImageUploadProcessing || component.textVideoUploadProcessing) {
            component.callFromTextPanel = true;
        }
    }

    private assignMediaModel(component: MediaDetailComponent, dataEdit: MediaView) {
        if (dataEdit != null) {
            component.model = cloneDeep(dataEdit);
        }
    }

    private onDetailDirectiveSaveEvent(component: MediaDetailComponent) {
        component.onSave.subscribe(
            (dataSave: MediaView) => {
                this.saveData(dataSave);
            }
        );
    }

    private onDetailDirectiveSaveMultitpleFileEvent(component: MediaDetailComponent) {
        component.onSaveMultipleFile.subscribe(
            (addedFiles: File[]) => {
                this.saveMultipleFile(addedFiles);
            }
        );
    }

    private onDetailDirectiveCancelEvent(component: MediaDetailComponent) {
        component.onCancel.subscribe(
            response => {
                this.displayAfterCancel();
            }
        );
    }

    private onDetailDirectiveClickMediaLibraryEvent(component: MediaDetailComponent) {
        component.onClickMediaLibrary.subscribe(
            (searchCommand: MediaSearchCommandModel) => {
                this.clickMediaLibrary(searchCommand);
            }
        );
    }

    private onDetailDirectiveClickMediaLibraryFromTextPanelEvent(component: MediaDetailComponent) {
        component.onClickMediaLibraryFromTextPanel.subscribe(
            (searchCommand: MediaSearchCommandModel) => {
                this.clickMediaLibraryFromTextPanel(searchCommand);
            }
        );
    }

    private onDetailDirectiveCancelTextUploadProcessingEvent(component: MediaDetailComponent) {
        component.onCancelTextUploadProcessing.subscribe(
            () => {
                this.cancelTextUploadProcess();
                this.displayAfterCancel();
            }
        );
    }

    private onDetailDirectiveSaveToDBEvent(component: MediaDetailComponent){
        component.onSaveToDB.subscribe(
            () => {
                this.saveToDB();
            }
        );
    }

    private savedata(data: MediaView) {
        if (this.mediaFiles != null) {
            var filterIndex = this.mediaFiles.findIndex(x => x.no == data.no);
            if (filterIndex == -1) {
                data.no = this.mediaFiles.length + 1;
                this.mediaFiles.push(data);
            }
            else {
                this.mediaFiles[filterIndex] = data;
            }
        }
        else {
            data.no = 1;
            this.mediaFiles.push(data);
        }
        this.mediaTable.dataGrid.instance.refresh();
    }

    private deletedata(data: MediaView) {
        if (this.mediaFiles != null) {
            let views =  [...this.mediaFiles];
            views.splice(views.indexOf(data), 1);
            let no = 1;
            views.forEach(function (value) {
                value.no = no;
                no++;
            });
            this.mediaFiles = views;
        }
    }

    private displayAfterSave() {
        if (this.mediaFiles?.length > 1) {
            this.singleRecord = false;
            this.formDetailClose();
            this.hideDetailDirective();
            this.showMainBrowse = false;
        }
        else {
            this.singleRecord = true;
            this.showMainBrowse = true;
        }
    }

    private displayAfterSaveMultiple() {
        if (!this.singleRecord) {
            this.hideDetailDirective();
            this.formDetailClose();
        }
        this.singleRecord = false;
    }

    private displayAfterDelete() {
        if (this.mediaFiles.length == 0 || this.mediaFiles.length == 1) {
            if (this.mediaFiles.length == 1) {
                this.model = this.mediaFiles[0];
                this.showMainBrowse = false;
            }
            else {
                this.model = new MediaView();
                this.showMainBrowse = true;
            }
            this.singleRecord = true;
        }
        this.enabledEdit = false;
        this.hideDetailDirective();
    }

    private displayAfterCancel() {
        this.hideDetailDirective();
        if (this.mediaFiles.length == 1) {
            this.singleRecord = true;
        }
    }

    private formDetailClose() {
        let viewContainerRef = this.mediaDetailDirective.viewContainerRef;
        viewContainerRef.clear();
    }

    public fillModelToForm(mediaFileViews: MediaView[]) {
        this.clearData();
        if (mediaFileViews && mediaFileViews.length > 0) {
            this.fillModelInCase(mediaFileViews);
        }
        else {
            this.clearDetailForm();
        }
        this.mediaFiles = mediaFileViews;
        this.changeDectionRef.detectChanges();
    }

    public saveMediaLibrary(mediaFileViews: MediaView[]) {
        if (mediaFileViews) {
            var mediaIds: string[] = new Array();
            var mediaContentIds: string[] = new Array();
            for (let data of mediaFileViews) {
                if (!this.exists(data)) {
                    this.savedata(data);
                    mediaIds.push(data.mediaId);
                }
                if (this.textImageSelectLibraryProcessing || this.textVideoSelectLibraryProcessing) {
                    if (data.mediaContentId) {
                        mediaContentIds.push(data.mediaContentId);
                    }
                }
            }
            this.displayAfterSaveMediaLibrary();
            this.displayTextTranslationFromMediaLibrary(mediaIds);
            this.minimixeMediaLibraryPanel();
            this.saveBrowseCallFromTextPanel(mediaContentIds);
        }
    }

    private fillModelInCase(mediaFileViews: MediaView[]) {
        if (mediaFileViews.length == 1) {
            this.fillModelSingleRecord(mediaFileViews[0]);
        }
        else {
            this.singleRecord = false;
        }
    }

    private fillModelSingleRecord(mediaFileViews: MediaView) {
        this.singleRecord = true;
        this.showMainBrowse = false;
        this.model = mediaFileViews;
        this.clearDetailForm();
    }

    private clearData() {
        this.singleRecord = true;
        this.model = new MediaView();
        this.mediaMapperService.setDefaultPrimaryLanguage(this.languageReference$, this.model);
        this.showMainBrowse = true;
    }

    public clearDetailForm() {
        if (this.mediaDetailComponent?.mediaForm) {
            this.mediaDetailComponent.mediaForm.resetForm(this.model);
        }
    }

    private displayAfterSaveMediaLibrary() {
        if (this.mediaFiles != null) {
            this.displayAfterSaveInCase();
        }
        else {
            this.displayAfterSaveNoData();
        }
    }

    private displayAfterSaveInCase() {
        if (this.mediaFiles.length > 1) {
            this.displayAfterSaveInList();
        }
        else {
            this.displayAfterSaveSingleRecord();
        }
    }

    private displayAfterSaveInList() {
        this.singleRecord = false;
        this.showMainBrowse = false;
        this.formDetailClose();
        this.hideDetailDirective();
        if (!this.isCollapsedDetail) {
            this.scrollToSearch();
        }
    }

    private displayAfterSaveSingleRecord() {
        this.model = this.mediaFiles[0];
        this.singleRecord = true;
        this.showMainBrowse = false;
        this.scrollToSearch();
        this.changeDectionRef.detectChanges();
    }

    private displayAfterSaveNoData() {
        this.singleRecord = true;
        this.showMainBrowse = true;
    }

    private displayTextTranslationFromMediaLibrary(mediaIds: string[]) {
        if (mediaIds && mediaIds.length > 0) {
            this.mediaService.getByIds(mediaIds)
                .subscribe(
                    (responses: MediaViewModel[]) => {
                        this.mediaLibrarySelected.emit(responses);
                    }
                )
        }
    }

    public deleteTextTranslation(mediaId: string) {
        if (mediaId) {
            this.mediaIdDeleted.emit(mediaId);
        }
    }

    private exists(textView: MediaView): boolean {
        var filter = this.mediaFiles.filter(x => x.mediaId == textView.mediaId);
        return filter.length > 0;
    }

    public validateMediaCompletedAll(): boolean {
        for (let mediaFile of this.mediaFiles) {
            if (!this.mediaDataCompleted(mediaFile)) {
                this.mediaNotCompleted = true;
                return false;
            }
        }
        this.mediaNotCompleted = false;
        return true;
    }

    private mediaDataCompleted(mediaFile: MediaView): boolean {
        if (mediaFile.mediaName &&
            mediaFile.mediaHashTagIds?.length &&
            mediaFile.mediaUseCode &&
            mediaFile.mediaTypeCode) {
            return true;
        }
        else {
            return false;
        }
    }

    public clickMediaLibrary(searchCommand: MediaSearchCommandModel) {
        this.displayMediaLibrary().then(() => {
            this.loadMediaLibraryTableForm(searchCommand).then(() => {
                setTimeout(() => {
                    this.scrollToMediaLibraryTable();    
                }, this.DELAY_TIME);
            });
        });
    }

    public clickMediaLibraryFromTextPanel(searchCommand: MediaSearchCommandModel) {
        this.displayMediaLibrary().then(() => {
            this.loadMediaLibraryTableForm(searchCommand);
        });
    }

    public displayMediaLibrary(): Promise<void> {
        return new Promise((resolve, reject) => {
            if (!this.mediaFiles?.length) {
                this.miniMizePanel = true;
            }
            else {
                this.miniMizeDetailPanel = true;
            }
            this.isCollapsedMediaLibrary = false;
            if (this.mediaDetailComponent) {
                this.mediaDetailComponent.browseLibrary = false;
            }
            resolve();
        });
    }

    public clearForm() {
        this.singleRecord = true;
        this.isCollapsedDetail = true;
        this.model = new MediaView();
        this.mediaFiles = [];
        this.showMainBrowse = true;
        this.mediaNotCompleted = false;
        this.changeDectionRef.detectChanges();
    }

    public textImageUploadProcess() {
        this.textImageUploadProcessing = true;
        this.changeDectionRef.detectChanges();
    }

    public textVideoUploadProcess() {
        this.textVideoUploadProcessing = true;
        this.changeDectionRef.detectChanges();
    }

    public textImageSelectLibraryProcess() {
        this.textImageSelectLibraryProcessing = true;
        this.changeDectionRef.detectChanges();
    }

    public textVideoSelectLibraryProcess() {
        this.textVideoSelectLibraryProcessing = true;
        this.changeDectionRef.detectChanges();
    }

    public cancelTextUploadProcess() {
        if (!this.mediaFiles?.length) {
            this.model = new MediaView();
        }
        else if (this.mediaFiles?.length == 1) {
            this.model = this.mediaFiles[0];
        }
        this.textImageUploadProcessing = false;
        this.textVideoUploadProcessing = false;
        this.resetCallFromTextPanel();
        this.changeDectionRef.detectChanges();
        this.cancelTextUpload.emit();
    }

    private textImageUploadCompleted(mediaConTextId: string) {
        this.textImageUploadProcessing = false;
        this.resetCallFromTextPanel();
        this.saveTextImageUpload.emit(mediaConTextId);
    }

    private textVideoUploadCompleted(mediaConTextId: string) {
        this.textVideoUploadProcessing = false;
        this.resetCallFromTextPanel();
        this.saveTextVideoUpload.emit(mediaConTextId);
    }

    private resetCallFromTextPanel() {
        if (this.mediaDetailComponent) {
            this.mediaDetailComponent.callFromTextPanel = false;
        }
    }

    private loadMediaLibraryTableForm(searchCommand: MediaSearchCommandModel): Promise<void> {
        return new Promise((resolve, reject) => {
            let componentFactory = this.componentFactoryResolver
                .resolveComponentFactory(MediaLibraryTableComponent);

            let viewContainerRef = this.mediaLibraryTableDirective.viewContainerRef;
            viewContainerRef.clear();

            let componentRefs = viewContainerRef.createComponent(componentFactory);
            let component: MediaLibraryTableComponent = (<MediaLibraryTableComponent>componentRefs.instance);
            this.assignInitialDataMediaLibraryTableForm(component, searchCommand);
            this.onSaveMediaLibraryEvent(component);
            this.mediaLibraryTableComponent = component;
            resolve();
        });
    }

    private assignInitialDataMediaLibraryTableForm(component: MediaLibraryTableComponent, searchCommand: MediaSearchCommandModel) {
        component.mediaSearchCommand = searchCommand;
        component.mediaTypeReference$ = this.mediaTypeReference$;
        if (this.textImageSelectLibraryProcessing || this.textVideoSelectLibraryProcessing) {
            component.searchWithMediaType = true;
        }
    }

    private onSaveMediaLibraryEvent(component: MediaLibraryTableComponent) {
        component.onSaveMediaLibrary.subscribe(
            (dataSave: MediaView[]) => {
                this.saveMediaLibrary(dataSave);
            }
        );
    }


    public applyBrowseLibrary() {
        this.mediaLibraryTableComponent.saveMediaBrowse();
    }

    public cancelBrowse() {
        this.minimixeMediaLibraryPanel();
        this.cancelBrowseCallFromTextPanel();
    }

    private minimixeMediaLibraryPanel() {
        this.isCollapsedMediaLibrary = true;
        this.miniMizePanel = false;
        this.miniMizeDetailPanel = false;
        if (this.mediaDetailComponent) {
            this.mediaDetailComponent.browseLibrary = true;
        }
    }

    private formMediaLibraryTableClose() {
        let viewContainerRef = this.mediaLibraryTableDirective.viewContainerRef;
        viewContainerRef.clear();
    }

    private changeToMultiRecord(): Promise<void> {
        return new Promise((resolve, reject) => {
            this.singleRecord = false;
            resolve();
        });
    }

    private displayMediaLibraryInSingleRecord(searchCommand: MediaSearchCommandModel) {
        this.model.mediaUseCode = searchCommand.mediaUseCode;
        this.model.mediaTypeCode = searchCommand.mediaTypeCode;
        this.mediaSearchCommand = searchCommand;
        this.isCollapsedMediaLibrary = false;

        if (this.mediaDetailComponentDirective) {
            this.mediaDetailComponentDirective.callFromTextPanel = true;
            this.mediaDetailComponentDirective.mediaSearchCommand = searchCommand;
            this.mediaDetailComponentDirective.browseLibraryClick();
        } else {
            this.mediaDetailComponent.callFromTextPanel = true;
            this.mediaDetailComponent.mediaSearchCommand = searchCommand;
            this.mediaDetailComponent.browseLibraryClick();
        }
    }

    private displayMediaLibraryInMultiRecord(searchCommand: MediaSearchCommandModel) {
        if(this.mediaFiles.length == 1) {
            this.singleRecord = false;
            this.changeDectionRef.detectChanges();
        }
        this.model.mediaUseCode = searchCommand.mediaUseCode;
        this.model.mediaTypeCode = searchCommand.mediaTypeCode;
        this.mediaSearchCommand = searchCommand;
        this.mediaTable.mediaSearchCommand = searchCommand;
        this.addWithSearchCommand(searchCommand);
        
        this.isCollapsedMediaLibrary = false;
        setTimeout(() => {
            this.mediaSearchCommand = searchCommand;
            if(this.mediaDetailComponentDirective){
                this.mediaDetailComponentDirective.callFromTextPanel = true;
                this.mediaDetailComponentDirective.mediaSearchCommand = searchCommand;
                this.mediaDetailComponentDirective.browseLibraryClick();
            }
        }, this.DELAY_TIME);
    }

    private cancelBrowseCallFromTextPanel() {
        if (this.textImageSelectLibraryProcessing ||
            this.textVideoSelectLibraryProcessing) {
            this.textImageSelectLibraryProcessing = false;
            this.textVideoSelectLibraryProcessing = false;
            this.formMediaLibraryTableClose();
            this.isCollapsedMediaLibrary = true;
            this.isCollapsedDetail = true;
            if (this.mediaFiles?.length) {
                this.formDetailClose();
            }
            if (this.mediaFiles.length <= 1 && this.mediaDetailComponent) {
                this.mediaDetailComponent.cancelCallFromText();
            }

            this.cancelTextSelectLibrary.emit();
        }
        else {
            if (!this.mediaFiles?.length) {
                this.scrollToSearch();
            }
            else {
                this.scrollToDetailPosition();
            }
        }
    }

    private saveBrowseCallFromTextPanel(mediaContentIds: string[]) {
        if (this.textImageSelectLibraryProcessing ||
            this.textVideoSelectLibraryProcessing) {
            this.formMediaLibraryTableClose();
            this.isCollapsedMediaLibrary = true;
            this.isCollapsedDetail = true;
            if (this.mediaFiles?.length) {
                this.formDetailClose();
            }
            if (this.textImageSelectLibraryProcessing) {
                this.saveTextSelectImageLibrary.emit(mediaContentIds);
            } else if (this.textVideoSelectLibraryProcessing) {
                this.saveTextSelectVideoLibrary.emit(mediaContentIds);
            }
            this.textImageSelectLibraryProcessing = false;
            this.textVideoSelectLibraryProcessing = false;
            if (this.mediaDetailComponent) {
                this.mediaDetailComponent.callFromTextPanel = false;
            }
        }
    }

    public checkCollapsedStatus(collapsed) {
        if (!collapsed) {
            this.cancelBrowse();
        }
    }

    public cancelCallFromText() {
        this.cancelBrowse();
        this.cancelAllCallFromText();
        if (this.mediaDetailComponent) {
            this.mediaDetailComponent.callFromTextPanel = false;
            this.mediaDetailComponent.browseLibrary = false;
        }

        if (this.mediaFiles?.length) {
            this.hideDetailDirective();
        }
        this.formDetailClose();
        this.formMediaLibraryTableClose();
    }

    private cancelAllCallFromText() {
        this.textImageSelectLibraryProcessing = false;
        this.textVideoSelectLibraryProcessing = false;
        this.textVideoUploadProcessing = false;
        this.textImageUploadProcessing = false;
    }

    public saveToDB() {
        if(!this.mediaFiles?.length || this.mediaFiles.length == 1){
            this.scrollToSearch();
        }
        else {
            this.scrollToDetailPosition();
        }
    }
}