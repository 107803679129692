import { Component, ChangeDetectionStrategy, Output, EventEmitter, Input, OnChanges, SimpleChange, ViewEncapsulation, ChangeDetectorRef } from '@angular/core';
import { TreeNode } from 'primeng/api';

@Component({
    selector: 'op-tree',
    templateUrl: './tree.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None,
    styleUrls: ['./tree.component.scss']
})
export class TreeComponent implements OnChanges {

    files: TreeNode[];
    selectedFile: TreeNode;

    @Input() id: string;
    @Input() heightPanelGeneral: Number = 511;
    @Input() focused: boolean = false;
    @Input() transportTree: TreeNode[];

    @Input() nodeSelected: boolean = false;

    @Output() parentId = new EventEmitter<string>();
    @Output() onHide = new EventEmitter();

    private firstLoad: boolean = true;
    public focusing: boolean = false;

    constructor(private changeDetectorRef: ChangeDetectorRef) {
    }

    ngOnChanges(changes: { [propertyName: string]: SimpleChange }) {
        if (this.firstLoad || !this.nodeSelected) {
            this.refreshTree();
        }
    }

    public refreshTree(transportTree: TreeNode[] = null) {
        if (this.id) {
            this.files = transportTree ?? this.transportTree;
            if (this.files) {
                if (this.firstLoad) {
                    this.firstLoad = false;
                }
                this.files.forEach(node => {
                    this.expandRecursive(node, true);
                });
            }
        }
    }

    private expandRecursive(node: TreeNode, isExpand: boolean) {
        node.expanded = isExpand;
        if (node.children) {
            node.children.forEach(childNode => {
                this.expandRecursive(childNode, isExpand);
            });
        }
        if (node.data == this.id) {
            this.selectedFile = node;
        }
    }

    public nodeSelect(value) {
        this.nodeSelected = true;
        this.parentId.emit(value.node.data);
    }

    public hideTree() {
        this.onHide.emit();
    }

    public refreshForm() {
        this.changeDetectorRef.detectChanges();
    }
}