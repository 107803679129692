import { Injectable } from '@angular/core';

import {
    CalendarValidityReferenceModel,
    ConditionReferenceModel,
    DateTimeDimensionReferenceModel,
    LocationReferenceModel,
    VehicleCompositionModel,
    VehicleGroupReferenceModel,
    VehicleTypeReferenceModel
} from 'src/app/core/models/reference-model/reference-general-model';

import { OrganisationModel } from 'src/app/core/models/organisation-model/organisation.model';
import { PriceRuleLocationPointReferenceModel } from 'src/app/core/models/reference-model/reference-pricing-model';
import { ProductGroupReferenceModel, ProductNumberTypeReferenceModel, ProductTypeGroupModel } from 'src/app/core/models/reference-model/reference-product-model';
import { Select2Data } from 'src/app/shared/ui/forms/inputs/oops-select2';
import { ProductCategoryViewModel } from 'src/app/core/models/reference-model/reference-product-model/product-category-view.model';

@Injectable()
export class ProductEligibleRestrictedConverter {

    public toProductNumberTypeSelect2DataList(models: ProductNumberTypeReferenceModel[]): Select2Data[] {
        let views = models.map(model => new Select2Data(model.productNumberTypeCode, model.productNumberTypeName));
        return views;
    }

    public toConditionReferenceSelect2DataList(models: ConditionReferenceModel[]): Select2Data[] {
        return models.map(model => new Select2Data(model.conditionCode, model.conditionName));
    }

    public toProviderSelect2DataList(models: OrganisationModel[], id: string = null): Select2Data[] {
        return models.map(model => new Select2Data(model.organisationId, model.organisationCode));
    }

    public toLocationReferenceDataList(models: LocationReferenceModel[]): Select2Data[] {
        return models.map(model => new Select2Data(model.locationId, model.locationName));
    }

    public toLocationPointReferenceDataList(models: PriceRuleLocationPointReferenceModel[]): Select2Data[] {
        let views = models.map(model => new Select2Data(model.priceRuleLocationPointCode, model.priceRuleLocationPointName));
        return views;
    }

    public toCalendarValidityReferenceDataList(models: CalendarValidityReferenceModel[]): Select2Data[] {
        return models.map(model => new Select2Data(model.calendarValidityCode, model.calendarValidityName));
    }

    public toDateTimeDimensionReferenceDataList(models: DateTimeDimensionReferenceModel[]): Select2Data[] {
        let views = models.map(model => new Select2Data(model.dateTimeDimensionCode, model.dateTimeDimensionName));
        return views;
    }

    public toVehicleGroupReferenceDataList(models: VehicleGroupReferenceModel[]): Select2Data[] {
        let views = models.map(model => new Select2Data(model.vehicleGroupCode, model.vehicleGroupName));
        return views;
    }

    public toVehicleTypeReferenceDataList(models: VehicleTypeReferenceModel[]): Select2Data[] {
        let views = models.map(model => new Select2Data(model.vehicleTypeCode, model.vehicleTypeName))
        return views;
    }

    public toVehicleCompositionReferenceDataList(models: VehicleCompositionModel[]): Select2Data[] {
        let views = models.map(model => new Select2Data(model.vehicleCompositionId, model.compositionName));
        return views;
    }

    public toProductCategoryReferenceDataList(models: ProductCategoryViewModel[]): Select2Data[] {
        return models.map(model => new Select2Data(model.productCategoryCode, model.productCategoryName));
    }

    public toProductGroupDataList(models: ProductGroupReferenceModel[]): Select2Data[] {
        return models.map(model => new Select2Data(model.productGroupCode, model.productGroupName));
    }

    public toProductTypeDataList(models: ProductTypeGroupModel[]): Select2Data[] {
        return models.map(model => new Select2Data(model.productTypeCode, model.productTypeName));
    }
}