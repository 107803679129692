import {
    Component,
    Input,
    ViewChild,
    Output,
    EventEmitter,
    OnChanges,
    SimpleChanges,
    ComponentFactoryResolver,
    ViewContainerRef,
    AfterViewInit,
    OnDestroy,
} from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ParentServiceCategoriesAndSalesBucketComponent } from './parent-service-categories-and-sales-bucket/parent-service-categories-and-sales-bucket.component';
import { PointOfSalesComponent } from './point-of-sales/point-of-sales.component';
import { ProductEligibleRestrictedComponent } from './product-eligible-restricted/product-eligible-restricted.component';
import { PriceRuleAttributeAndRuleRowView } from './product-eligible-restricted/shared/views';
import { RoundtripDefinitionComponent } from './roundtrip-definition/roundtrip-definition.component';
import { ServiceCategoriesAndSalesBucketComponent } from './service-categories-and-sales-bucket/service-categories-and-sales-bucket.component';
import { AttributeComponent } from './attribute/attribute.component';
import { CustomerComponent } from './customer/customer.component';
import { PriceRuleView } from '../../../shared/views/price-rule.view';
import { ProductAttributeComponent } from './product-attribute/product-attribute.component';
import { AttributeAndRuleBase } from './shared/attribute-and-rule.base';
import { TransitStopoverComponent } from './transit-stopover/transit-stopover.component';
import { FocusingDirective } from 'src/app/shared/ui/forms/inputs/focusing.directive';
import { PriceRuleModel } from 'src/app/core/models/pricing-model';
import { PricingConverter } from '../../../shared/pricing.converter';
import { FormOfPaymentComponent } from './form-of-payment/form-of-payment.component';

declare var $: any;

@Component({
    selector: 'op-price-rule-detail-attribute-and-rule',
    templateUrl: './attribute-and-rule.component.html'
})
export class PricingDetailAttributeAndRuleComponent implements OnChanges, AfterViewInit, OnDestroy {
    @Input() id: string;
    @Input() priceRuleView: PriceRuleView;
    @Input() classIcon: string;
    @Input() searchMode: boolean = false;
    @Input() panelTitle = 'ATTRIBUTES AND RULES';
    @Input() panelIcon = 'fal fa-hand-holding-box';
    @Input() disabled: boolean = false;

    @Output() focusEmit = new EventEmitter<any>();
    @Output() onSubmit = new EventEmitter();
    
    focusing: boolean = false;

    @Output() priceRuleViewChange = new EventEmitter();

    public productEligibleData$ = new BehaviorSubject<PriceRuleAttributeAndRuleRowView[]>(null);
    public pointOfSalesData$ = new BehaviorSubject<PriceRuleAttributeAndRuleRowView[]>(null);
    public serviceCategoriesSalesBucketsData$ = new BehaviorSubject<PriceRuleAttributeAndRuleRowView[]>(null);
    public parentServiceCategoriesSalesBucketsData$ = new BehaviorSubject<PriceRuleAttributeAndRuleRowView[]>(null);
    public roundtripDefinitionData$ = new BehaviorSubject<PriceRuleAttributeAndRuleRowView[]>(null);
    public attributeData$ = new BehaviorSubject<PriceRuleAttributeAndRuleRowView[]>(null);
    public customerData$ = new BehaviorSubject<PriceRuleAttributeAndRuleRowView[]>(null);
    public productAttributeData$ = new BehaviorSubject<PriceRuleAttributeAndRuleRowView[]>(null);
    public transitStopoverData$ = new BehaviorSubject<PriceRuleAttributeAndRuleRowView[]>(null);
    public formOfPaymentData$ = new BehaviorSubject<PriceRuleAttributeAndRuleRowView[]>(null);

    get focusingDirective(): FocusingDirective {
        return this.productEligibleRestrictedComponent.focusingDirective;
    }
    
    private productEligibleRestrictedComponent: ProductEligibleRestrictedComponent;
    private pointOfSalesComponent: PointOfSalesComponent;
    private serviceCategoriesAndSalesBucketComponent: ServiceCategoriesAndSalesBucketComponent;
    private parentServiceCategoriesAndSalesBucketComponent: ParentServiceCategoriesAndSalesBucketComponent;
    private roundtripDefinitionComponent: RoundtripDefinitionComponent;
    private attributeComponent: AttributeComponent;
    private customerComponent: CustomerComponent;
    private productAttributeComponent: ProductAttributeComponent;
    private transitStopoverComponent: TransitStopoverComponent;
    private formOfPaymentComponent: FormOfPaymentComponent;

    private unsubscribe$ = new Subject();

    private priceRuleCategoryCode$ = new BehaviorSubject<string>(null);
    private priceRuleTypeCode = "";

    @ViewChild('productEligible', {static: true, read: ViewContainerRef}) productEligibleContainer: ViewContainerRef;
    @ViewChild('pointOfSales', {static: true, read: ViewContainerRef}) pointOfSalesContainer: ViewContainerRef;
    @ViewChild('serviceCategoriesSalesBuckets', {static: true, read: ViewContainerRef}) serviceCategoriesSalesBucketsContainer: ViewContainerRef;
    @ViewChild('parentServiceCategoriesSalesBuckets', {static: true, read: ViewContainerRef}) parentServiceCategoriesSalesBucketsContainer: ViewContainerRef;
    @ViewChild('roundtripDefinition', {static: true, read: ViewContainerRef}) roundtripDefinitionContainer: ViewContainerRef;
    @ViewChild('attribute', {static: true, read: ViewContainerRef}) attributeContainer: ViewContainerRef;
    @ViewChild('customer', {static: true, read: ViewContainerRef}) customerContainer: ViewContainerRef;
    @ViewChild('productAttribute', {static: true, read: ViewContainerRef}) productAttributeContainer: ViewContainerRef;
    @ViewChild('transitStopover', {static: true, read: ViewContainerRef}) transitStopoverContainer: ViewContainerRef;
    @ViewChild('formOfPayment', {static: true, read: ViewContainerRef}) formOfPaymentContainer: ViewContainerRef;

    constructor(private componentFactoryResolver: ComponentFactoryResolver,
        private pricingConverter: PricingConverter) {
    }

    ngAfterViewInit(): void {
        this.subscribeToPriceRuleCategoryCodeToCreateDynamicComponentsRelatedTo();
        this.subscribeToUnsubscribeSubjectToClearComponentContainers();
    }

    private subscribeToPriceRuleCategoryCodeToCreateDynamicComponentsRelatedTo() {
        this.priceRuleCategoryCode$
            .subscribe(
                val => {
                    this.unsubscribe$.next();
                    this.loadComponentsRelatedToPriceRuleCategoryCode(val);
                }
            );
    }

    private subscribeToUnsubscribeSubjectToClearComponentContainers() {
        this.unsubscribe$.subscribe(
            () => {
                this.productEligibleContainer?.clear();
                this.pointOfSalesContainer?.clear();
                this.serviceCategoriesSalesBucketsContainer?.clear();
                this.parentServiceCategoriesSalesBucketsContainer?.clear();
                this.roundtripDefinitionContainer?.clear();
                this.attributeContainer?.clear();
                this.productAttributeContainer?.clear();
                this.customerContainer?.clear();
                this.transitStopoverContainer?.clear();
                this.formOfPaymentContainer?.clear();
            }
        );
    }

    ngOnDestroy(): void {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }

    private loadComponentsRelatedToPriceRuleCategoryCode(priceRuleCategoryCode: string) {
        switch(priceRuleCategoryCode) {
            case 'PRICE':
                this.loadPriceRelatedComponents();
                break;
            case 'TAX':
                this.loadTaxRelatedComponents();
                break;
            case 'DISCOUNT':
                this.loadDiscountRelatedComponents();
                break;
            case 'FEE':
                this.loadFeeRelatedComponents();
                break;
            default:
                this.loadPriceRelatedComponents();
                break;
        }
    }

    private loadPriceRelatedComponents() {
        this.loadProductEligibleComponent();
        this.loadPointOfSalesComponent();
        this.loadServiceCategoriesSalesBucketsComponent();
        this.loadParentServiceCategoriesSalesBucketsComponent();
        this.loadRoundtripDefinitionComponent();
        this.loadAttributeComponent();
        this.loadCustomerComponent();
        this.loadFormOfPaymentComponent();
    }

    private loadTaxRelatedComponents() {
        this.loadDiscountRelatedComponents();
        this.loadTransitStopoverComponent();
        this.loadFormOfPaymentComponent();
    }

    private loadDiscountRelatedComponents() {
        this.loadProductEligibleComponent();
        this.loadPointOfSalesComponent();
        this.loadAttributeComponent();
        this.loadProductAttributeComponent();
        this.loadCustomerComponent();
        this.loadFormOfPaymentComponent();
    }

    private loadFeeRelatedComponents() {
        this.loadDiscountRelatedComponents();
        this.loadTransitStopoverComponent();
        this.loadFormOfPaymentComponent();
    }

    private fetchAttributesData() {
        this.productEligibleData$.next(this.priceRuleView?.productEligibleData ?? []);
        this.pointOfSalesData$.next(this.priceRuleView?.pointOfSalesData ?? []);
        this.serviceCategoriesSalesBucketsData$.next(this.priceRuleView?.serviceCategoriesSalesBucketsData ?? []);
        this.parentServiceCategoriesSalesBucketsData$.next(this.priceRuleView?.parentServiceCategoriesSalesBucketsData ?? []);
        this.roundtripDefinitionData$.next(this.priceRuleView?.roundtripDefinitionData ?? []);
        this.attributeData$.next(this.priceRuleView?.attributeData ?? []);
        this.customerData$.next(this.priceRuleView?.customerData ?? []);
        this.productAttributeData$.next(this.priceRuleView?.productAttributeData ?? []);
        this.transitStopoverData$.next(this.priceRuleView?.transitStopoverData ??[]);
        this.formOfPaymentData$.next(this.priceRuleView?.formOfPaymentData ?? []);
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes['priceRuleView']) {
            let priceRuleView = changes['priceRuleView'].currentValue;
            this.clearAttributesDataIfPriceRuleTypeCodeChange(priceRuleView);
            this.setPriceRuleCategoryCode(changes['priceRuleView'].currentValue?.priceRuleCategoryCode);
            this.fetchAttributesData();
        }
    }

    private clearAttributesDataIfPriceRuleTypeCodeChange(priceRuleView: PriceRuleView) {
        if (this.bothHaveValueAndNotEqual(this.priceRuleTypeCode, priceRuleView?.priceRuleTypeCode)) {
            this.priceRuleView.attributeData = [];
            this.priceRuleView.productAttributeData = [];
        }
        this.priceRuleTypeCode = priceRuleView?.priceRuleTypeCode;
    }

    private bothHaveValueAndNotEqual(currentPriceRuleTypeCode: string, previousPriceRuleTypeCode: string): boolean {
        return currentPriceRuleTypeCode && previousPriceRuleTypeCode && currentPriceRuleTypeCode != previousPriceRuleTypeCode;
    }

    private setPriceRuleCategoryCode(priceRuleCategoryCode: string) {
        if (this.priceRuleCategoryCode$.value != priceRuleCategoryCode) {
            this.priceRuleCategoryCode$.next(priceRuleCategoryCode);
        }
    }

    focusFunction() {
        this.focusEmit.emit(true);
    }

    public getAttributeAndRuleViews(): PriceRuleView {
        if (this.canSubmitComponent(this.productEligibleRestrictedComponent) &&
            this.canSubmitComponent(this.pointOfSalesComponent) &&
            this.canSubmitComponent(this.serviceCategoriesAndSalesBucketComponent) &&
            this.canSubmitComponent(this.parentServiceCategoriesAndSalesBucketComponent) &&
            this.canSubmitComponent(this.roundtripDefinitionComponent) &&
            this.canSubmitComponent(this.attributeComponent) &&
            this.canSubmitComponent(this.formOfPaymentComponent) &&
            this.canSubmitComponent(this.customerComponent) &&
            this.canSubmitComponent(this.productAttributeComponent) &&
            this.canSubmitComponent(this.transitStopoverComponent)) {
            this.priceRuleViewChange.emit(this.priceRuleView);
            this.onSubmit.emit();
            return this.priceRuleView;
        }
    }

    public getValues(priceRuleModel: PriceRuleModel): PriceRuleModel {
        if (!this.canSubmitComponent(this.productEligibleRestrictedComponent)){
            return null;
        }
        if (!this.canSubmitComponent(this.pointOfSalesComponent)){
            return null;
        }
        if (!this.canSubmitComponent(this.serviceCategoriesAndSalesBucketComponent)){
            return null;
        }
        if (!this.canSubmitComponent(this.parentServiceCategoriesAndSalesBucketComponent)){
            return null;
        }
        if (!this.canSubmitComponent(this.roundtripDefinitionComponent)){
            return null;
        }
        if (!this.canSubmitComponent(this.attributeComponent)){
            return null;
        }
        if (!this.canSubmitComponent(this.formOfPaymentComponent)) {
            return null;
        }
        if (!this.canSubmitComponent(this.customerComponent)){
            return null;
        }
        if (!this.canSubmitComponent(this.productAttributeComponent)){
            return null;
        }
        if (!this.canSubmitComponent(this.transitStopoverComponent)){
            return null;
        }
        let model = this.pricingConverter.toModel(this.priceRuleView);
        return model;
    }

    private canSubmitComponent(component: AttributeAndRuleBase): boolean {
        if (component) {
            return component.attemptToSubmit();
        }
        return true;
    }

    public productEligibleDataChange(event) {
        this.priceRuleView.productEligibleData = event;
    }

    public pointOfSalesDataChange(event) {
        this.priceRuleView.pointOfSalesData = event;
    }

    public serviceCategoriesSalesBucketsDataChange(event) {
        this.priceRuleView.serviceCategoriesSalesBucketsData = event;
    }

    public parentServiceCategoriesSalesBucketsDataChange(event) {
        this.priceRuleView.parentServiceCategoriesSalesBucketsData = event;
    }

    public roundtripDefinitionDataChange(event) {
        this.priceRuleView.roundtripDefinitionData = event;
    }

    public attributeDataChange(event) {
        this.priceRuleView.attributeData = event;
    }

    public productAttributeDataChange(event) {
        this.priceRuleView.productAttributeData = event;
    }

    public customerDataChange(event) {
        this.priceRuleView.customerData = event;
    }

    public formOfPaymentDataChange(event) {
        this.priceRuleView.formOfPaymentData = event;
    }

    public transitStopoverDataChange(event) {
        this.priceRuleView.transitStopoverData = event;
    }

    private loadProductEligibleComponent() {
        this.productEligibleData$.pipe(takeUntil(this.unsubscribe$)).subscribe(
            val => {
                this.createProductEligibleComponent(val);
            }
        )
    }

    private createProductEligibleComponent(val: PriceRuleAttributeAndRuleRowView[]) {
        let componentFactory = this.componentFactoryResolver
            .resolveComponentFactory(ProductEligibleRestrictedComponent);

        this.productEligibleContainer.clear();

        let componentRef = this.productEligibleContainer.createComponent(componentFactory);
        componentRef.instance.dataChange.subscribe(
            val => {
                this.productEligibleDataChange(val);
            }
        );
        componentRef.instance.data = val;
        componentRef.instance.searchMode = this.searchMode;
        componentRef.instance.disabled = this.disabled;
        this.productEligibleRestrictedComponent = componentRef.instance;
    }

    private loadPointOfSalesComponent() {
        this.pointOfSalesData$.pipe(takeUntil(this.unsubscribe$)).subscribe(
            val => {
                this.createPointOfSalesComponent(val);
            }
        )
    }

    private createPointOfSalesComponent(val: PriceRuleAttributeAndRuleRowView[]) {
        let componentFactory = this.componentFactoryResolver
            .resolveComponentFactory(PointOfSalesComponent);

        this.pointOfSalesContainer.clear();

        let componentRef = this.pointOfSalesContainer.createComponent(componentFactory);
        componentRef.instance.dataChange.subscribe(
            val => {
                this.pointOfSalesDataChange(val);
            }
        );
        componentRef.instance.data = val;
        componentRef.instance.disabled = this.disabled;
        this.pointOfSalesComponent = componentRef.instance;
    }

    private loadServiceCategoriesSalesBucketsComponent() {
        this.serviceCategoriesSalesBucketsData$.pipe(takeUntil(this.unsubscribe$)).subscribe(
            val => {
                this.createServiceCategoriesSalesBucketsComponent(val);
            }
        )
    }

    private createServiceCategoriesSalesBucketsComponent(val: PriceRuleAttributeAndRuleRowView[]) {
        let componentFactory = this.componentFactoryResolver
            .resolveComponentFactory(ServiceCategoriesAndSalesBucketComponent);

        this.serviceCategoriesSalesBucketsContainer.clear();

        let componentRef = this.serviceCategoriesSalesBucketsContainer.createComponent(componentFactory);
        componentRef.instance.dataChange.subscribe(
            val => {
                this.serviceCategoriesSalesBucketsDataChange(val);
            }
        );
        componentRef.instance.data = val;
        componentRef.instance.disabled = this.disabled;
        this.serviceCategoriesAndSalesBucketComponent = componentRef.instance;
    }

    private loadParentServiceCategoriesSalesBucketsComponent() {
        this.parentServiceCategoriesSalesBucketsData$.pipe(takeUntil(this.unsubscribe$)).subscribe(
            val => {
                this.createParentServiceCategoriesSalesBucketsComponent(val);
            }
        )
    }

    private createParentServiceCategoriesSalesBucketsComponent(val: PriceRuleAttributeAndRuleRowView[]) {
        let componentFactory = this.componentFactoryResolver
            .resolveComponentFactory(ParentServiceCategoriesAndSalesBucketComponent);

        this.parentServiceCategoriesSalesBucketsContainer.clear();

        let componentRef = this.parentServiceCategoriesSalesBucketsContainer.createComponent(componentFactory);
        componentRef.instance.dataChange.subscribe(
            val => {
                this.parentServiceCategoriesSalesBucketsDataChange(val);
            }
        );
        componentRef.instance.data = val;
        componentRef.instance.disabled = this.disabled;
        this.parentServiceCategoriesAndSalesBucketComponent = componentRef.instance;
    }

    private loadRoundtripDefinitionComponent() {
        this.roundtripDefinitionData$.pipe(takeUntil(this.unsubscribe$)).subscribe(
            val => {
                this.createRoundTripDefinitionComponent(val);
            }
        )
    }

    private createRoundTripDefinitionComponent(val: PriceRuleAttributeAndRuleRowView[]) {
        let componentFactory = this.componentFactoryResolver
            .resolveComponentFactory(RoundtripDefinitionComponent);

        this.roundtripDefinitionContainer.clear();

        let componentRef = this.roundtripDefinitionContainer.createComponent(componentFactory);
        componentRef.instance.dataChange.subscribe(
            val => {
                this.roundtripDefinitionDataChange(val);
            }
        );
        componentRef.instance.data = val;
        componentRef.instance.searchMode = this.searchMode;
        componentRef.instance.disabled = this.disabled;
        this.roundtripDefinitionComponent = componentRef.instance;
    }

    private loadAttributeComponent() {
        this.attributeData$.pipe(takeUntil(this.unsubscribe$)).subscribe(
            val => {
                this.createAttributeComponent(val);
            }
        )
    }

    private createAttributeComponent(val: PriceRuleAttributeAndRuleRowView[]) {
        let componentFactory = this.componentFactoryResolver
            .resolveComponentFactory(AttributeComponent);

        this.attributeContainer.clear();

        let componentRef = this.attributeContainer.createComponent(componentFactory);
        componentRef.instance.dataChange.subscribe(
            val => {
                this.attributeDataChange(val);
            }
        );
        componentRef.instance.data = val;
        componentRef.instance.priceRuleId = this.id;
        componentRef.instance.priceRuleTypeCode = this.priceRuleTypeCode;
        componentRef.instance.searchMode = this.searchMode;
        componentRef.instance.disabled = this.disabled;
        this.attributeComponent = componentRef.instance;
    }

    private loadCustomerComponent() {
        this.customerData$.pipe(takeUntil(this.unsubscribe$)).subscribe(
            val => {
                this.craeteCustomerComponent(val);
            }
        )
    }

    private loadFormOfPaymentComponent() {
        this.formOfPaymentData$.pipe(takeUntil(this.unsubscribe$)).subscribe(
            val => {
                this.createFormOfPaymentComponent(val);
            }
        )
    }

    private craeteCustomerComponent(val: PriceRuleAttributeAndRuleRowView[]) {
        let componentFactory = this.componentFactoryResolver
            .resolveComponentFactory(CustomerComponent);

        this.customerContainer.clear();

        let componentRef = this.customerContainer.createComponent(componentFactory);
        componentRef.instance.dataChange.subscribe(
            val => {
                this.customerDataChange(val);
            }
        );
        componentRef.instance.data = val;
        componentRef.instance.searchMode = this.searchMode;
        componentRef.instance.disabled = this.disabled;
        this.customerComponent = componentRef.instance;
    }

    private createFormOfPaymentComponent(val: PriceRuleAttributeAndRuleRowView[]) {
        let componentFactory = this.componentFactoryResolver
            .resolveComponentFactory(FormOfPaymentComponent);

        this.formOfPaymentContainer.clear();

        let componentRef = this.formOfPaymentContainer.createComponent(componentFactory);
        componentRef.instance.dataChange.subscribe(
            val => {
                this.formOfPaymentDataChange(val);
            }
        );
        componentRef.instance.data = val;
        componentRef.instance.searchMode = this.searchMode;
        componentRef.instance.disabled = this.disabled;
        this.formOfPaymentComponent = componentRef.instance;
    }

    private loadProductAttributeComponent() {
        this.productAttributeData$.pipe(takeUntil(this.unsubscribe$)).subscribe(
            val => {
                this.createProductAttributeComponent(val);
            }
        )
    }

    private createProductAttributeComponent(val: PriceRuleAttributeAndRuleRowView[]) {
        let componentFactory = this.componentFactoryResolver
            .resolveComponentFactory(ProductAttributeComponent);

        this.productAttributeContainer.clear();

        let componentRef = this.productAttributeContainer.createComponent(componentFactory);
        componentRef.instance.dataChange.subscribe(
            val => {
                this.productAttributeDataChange(val);
            }
        );
        componentRef.instance.data = val;
        componentRef.instance.priceRuleId = this.id;
        componentRef.instance.priceRuleTypeCode = this.priceRuleTypeCode;
        componentRef.instance.searchMode = this.searchMode;
        componentRef.instance.disabled = this.disabled;
        this.productAttributeComponent = componentRef.instance;
    }

    private loadTransitStopoverComponent() {
        this.transitStopoverData$.pipe(takeUntil(this.unsubscribe$)).subscribe(
            val => {
                this.createTransitStopoverComponent(val);
            }
        )
    }

    private createTransitStopoverComponent(val: PriceRuleAttributeAndRuleRowView[]) {
        let componentFactory = this.componentFactoryResolver
            .resolveComponentFactory(TransitStopoverComponent);

        this.transitStopoverContainer.clear();

        let componentRef = this.transitStopoverContainer.createComponent(componentFactory);
        componentRef.instance.dataChange.subscribe(
            val => {
                this.transitStopoverDataChange(val);
            }
        );
        componentRef.instance.data = val;
        componentRef.instance.priceRuleTypeCode = this.priceRuleTypeCode;
        componentRef.instance.searchMode = this.searchMode;
        componentRef.instance.disabled = this.disabled;
        this.transitStopoverComponent = componentRef.instance;
    }

    onClick() {
        this.focusEmit.emit(true);
    }
}
