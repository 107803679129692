import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    Output,
    SimpleChange,
    ViewChild
} from '@angular/core';
import {
    AbstractControl,
    UntypedFormArray,
    UntypedFormBuilder,
    UntypedFormGroup,
    Validators
} from '@angular/forms';
import { BehaviorSubject } from 'rxjs';
import { cloneDeep } from "lodash";
import { AttributeRuleConstant } from 'src/app/core/components/rules-config/shared/constants';
import { ProductAttributeViewModel } from 'src/app/core/models/product-model/product-base-model/product-attribute';
import { DomainAttributeModel } from 'src/app/core/models/reference-model/reference-general-model';
import { AttributeChoiceModel } from 'src/app/core/models/reference-model/reference-general-model/attribute-choice.model';
import { DomainAttributeService } from 'src/app/core/services/airline-services';
import { field } from 'src/app/modules/product-management/product-categories-content/shared/constant/attribute.constant';
import { AttributeMapperService } from 'src/app/modules/product-management/product-categories-content/shared/mapper/attribute-mapper.service';
import { FocusingDirective } from 'src/app/shared/ui/forms/inputs/focusing.directive';
import { OopsChoiceValueConstant } from 'src/app/shared/ui/forms/inputs/oops-choice-value/oops-choice-value.constant';
import { Select2Data } from 'src/app/shared/ui/forms/inputs/oops-select2';
import {
    select2Attribute,
    select2ChoiceMultiple,
    select2Dimension
} from './attribute-configuration';

@Component({
    selector: 'op-ancillary-service-attribute',
    templateUrl: './attribute.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [
        AttributeMapperService
    ]
})
export class AttributeComponent {
    @Input() productTypeCode: string;
    @Input() productGroupCode: string;
    @Input() id: string;
    @Input() newProduct: boolean = false;
    @Input() newFromParent = false;
    @Input() domainAttributeSearch$ = new BehaviorSubject<DomainAttributeModel[]>(null);
    @Input() domainAttributeService$ = new BehaviorSubject<DomainAttributeModel[]>(null);
    @Input() searchMode: boolean = false;
    @Input() rootLevel: boolean = false;

    @ViewChild(FocusingDirective) focusingDirective: FocusingDirective;

    private readonly ATTRBUTE_GROUP_CODE: string = "ATTRIBUTE";
    private readonly ATTRIBUTE_REQUIRED_ERROR = 'Attribute is required';

    actionForm: UntypedFormGroup;
    public attributeOption: any = cloneDeep(select2Attribute);
    public choiceOption: any = cloneDeep(select2Dimension);
    public choiceMultipleOption: any = cloneDeep(select2ChoiceMultiple);
    public ssrValide: boolean = true;
    public hideInherit: boolean = true;
    public draftFlag: boolean = true;
    public removedAttribute: DomainAttributeModel[] = [];

    public domainAttribute$ = new BehaviorSubject<DomainAttributeModel[]>(null);
    public productAttributes: ProductAttributeViewModel[];
    public focusing: boolean = false;
    constructor(
        private fb: UntypedFormBuilder,
        private domainAttributeService: DomainAttributeService,
        private attributeMapperService: AttributeMapperService,
        private changeDetectionRef: ChangeDetectorRef) {
        this.clearForms();
    }

    ngOnChanges(changes: SimpleChange) {
        if (!this.searchMode) {
            this.getAttributeByProductType();
        } else {
            this.getDomainAttributeSearch();
        }
    }

    public getAttributeByProductType() {
        if (this.productTypeCode) {
            this.domainAttributeService.getByProductType(this.ATTRBUTE_GROUP_CODE, this.productTypeCode)
                .subscribe(
                    (responses: DomainAttributeModel[]) => {
                        this.domainAttribute$.next(responses);
                        if (this.newProduct || this.newFromParent) {
                            this.displayShowOnNewFlag();
                        } else {
                            this.addDataToFormGroup();
                        }
                    }
                )
        }
    }

    private getDomainAttributeSearch() {
        this.domainAttribute$ = this.domainAttributeSearch$;
    }

    add() {
        if (this.forms.controls.length != 0) {
            let isDuplicate = this.findDuplicateAllRow();
            let isInvalid = this.findInvalidRows();
            if (isDuplicate || isInvalid) {
                return;
            }
        }
        if (this.noAttribute()) {
            return;
        }
        this.forms.push(this.createFormGroupEmpty(null));
        this.changeDetectionRef.detectChanges();
    }

    get forms() {
        return (<UntypedFormArray>this.actionForm.get('forms'));
    }

    get controlLength(): number {
        if (this.forms.controls) {
            return this.forms.controls.length;
        }
        return 0;
    }

    delete(i) {
        this.addAttributeToOption((<UntypedFormArray>this.actionForm.get('forms')).controls[i]);
        (<UntypedFormArray>this.actionForm.get('forms')).removeAt(i);
    }

    addAttributeToOption(ctl) {
        if (ctl?.get(field.ATTRTYPE_CODE)) {
            let deletedAttributeCode = ctl.get(field.ATTRTYPE_CODE).value;
            let addAttribute = this.removedAttribute.filter(attr => attr.attributeTypeCode == deletedAttributeCode)[0];
            if (!addAttribute) {
                return;
            }
            <UntypedFormArray>this.actionForm.get('forms').value.forEach(item => {
                let attributeTypeData = item.productAttributeTypeData.value;
                attributeTypeData.push(addAttribute);
                item.productAttributeTypeData.next(attributeTypeData);
            })

            this.addAttributeToList(addAttribute);
        }

    }

    addAttributeToList(addAttribute: DomainAttributeModel) {
        let domainAttr = this.domainAttribute$.value;
        domainAttr.push(addAttribute)
        this.domainAttribute$.next(domainAttr);

        let addIndex = this.removedAttribute.findIndex(item => item.attributeTypeCode == addAttribute.attributeTypeCode);
        this.removedAttribute.splice(addIndex, 1);
    }

    selectChange(value: string, ctl, name: string) {
        ctl.get(name).setValue(value);
    }

    isRequiredFlag(item): any {
        if (!item.id || this.searchMode) {
            return "";
        }
        var id = $(item.element.outerHTML).attr("value");
        if (this.domainAttributeService$.value) {
            var filter = this.domainAttributeService$.value.filter(x => x.attributeTypeCode == id && x.requiredFlag == true);
            if (filter?.length) {
                return " *";
            }
        }
        return "";
    }

    public attributeChange(value: string | string[], ctl: AbstractControl) {
        ctl.get(field.ATTRTYPE_CODE).setValue(value);
        if (this.domainAttribute$.value) {
            let attribute = this.domainAttribute$.value;
            let attributeFilter = attribute.filter(x => x.attributeTypeCode == value);
            if (attributeFilter.length > 0) {
                ctl.get(field.CHOICE_VALUE).setValue(null);
                ctl.get(field.DIMENSIONUNIT_CODE).setValue(attributeFilter[0].dimensionUnitCode);
                ctl.get(field.MIN).setValue(this.attributeMapperService.getMinimunValue(attributeFilter[0].attributeTypeCode, this.domainAttribute$));
                ctl.get(field.MAX).setValue(this.attributeMapperService.getMaximumValue(attributeFilter[0].attributeTypeCode, this.domainAttribute$));
                if (attributeFilter[0].dimensionUnitCode == OopsChoiceValueConstant.CHOICE) {
                    this.setChoiceData(ctl, attributeFilter[0].attributeChoices);
                    this.setSingleOrMultipleChoice(ctl, attributeFilter[0].multipleChoiceFlag);
                    this.setDefaultChoiceValue(ctl, attributeFilter[0]);
                }
                this.setDefaultValue(ctl, attributeFilter[0]);
                this.removeAttributeFromOption(attributeFilter[0])
            } else {
                this.setEmptySingleDropdown(ctl);
            }
        }
    }

    removeAttributeFromOption(domainAttribute: DomainAttributeModel) {
        let newDomainAttributeList = this.domainAttribute$.value.filter(item => item.attributeTypeCode != domainAttribute.attributeTypeCode);
        this.domainAttribute$.next(newDomainAttributeList);
        this.removedAttribute.push(domainAttribute);
        this.removeAttributeFromForm(domainAttribute);
    }

    removeAttributeFromForm(domainAttribute: DomainAttributeModel) {
        <UntypedFormArray>this.actionForm.get('forms').value.forEach(item => {
            if (item.productAttributeTypeCode != domainAttribute.attributeTypeCode) {
                let attributeTypeData = item.productAttributeTypeData.value.filter(attr => attr.attributeTypeCode != domainAttribute.attributeTypeCode);
                item.productAttributeTypeData.next(attributeTypeData);
            }
        })
    }

    private setChoiceData(ctl: AbstractControl, attributeChoices: AttributeChoiceModel[]) {
        let choiceData = this.convertToSelect2Data(attributeChoices);
        ctl.get(field.CHOICE_DATA).setValue(choiceData);
    }

    private setSingleOrMultipleChoice(ctl: AbstractControl, multipleChoiceFlag: boolean) {
        ctl.get(field.MULCHOICE_FLAG).setValue(multipleChoiceFlag);
        if (multipleChoiceFlag) {
            ctl.get(field.CHOICE_OPTOIN).setValue(this.choiceMultipleOption);
        } else {
            ctl.get(field.CHOICE_OPTOIN).setValue(this.choiceOption);
        }
    }

    private setDefaultChoiceValue(ctl: AbstractControl, attribute: DomainAttributeModel) {
        let filterDefaultChoice = attribute.attributeChoices.filter(x => x.defaultFlag == true);
        if (filterDefaultChoice.length > 0) {
            this.assignDefaultChoice(ctl, attribute.multipleChoiceFlag, filterDefaultChoice);
        } else {
            this.assignDefaultSingleChoice(ctl, attribute.multipleChoiceFlag, attribute.attributeChoices);
        }
    }

    private setDefaultValue(ctl: AbstractControl, attribute: DomainAttributeModel) {
        if ((attribute.dimensionUnitCode == OopsChoiceValueConstant.NUMBER ||
            attribute.dimensionUnitCode == OopsChoiceValueConstant.PERCENTAGE)
            && attribute.defaultValue) {
            ctl.get(field.CHOICE_VALUE).setValue(attribute.defaultValue);
        }
    }

    private assignDefaultChoice(ctl: AbstractControl, multipleChoiceFlag: boolean, chloces: AttributeChoiceModel[]) {
        if (multipleChoiceFlag) {
            let dataMultiple: string[] = new Array();
            dataMultiple.push(chloces[0].attributeChoiceId);
            ctl.get(field.CHOICE_VALUE).setValue(dataMultiple);
        } else {
            ctl.get(field.CHOICE_VALUE).setValue(chloces[0].attributeChoiceId);
        }
    }

    private assignDefaultSingleChoice(ctl: AbstractControl, multipleChoiceFlag: boolean, chloces: AttributeChoiceModel[]) {
        if (!multipleChoiceFlag) {
            ctl.get(field.CHOICE_VALUE).setValue(chloces[0].attributeChoiceId);
        }
    }

    private convertToSelect2Data(choiceData: AttributeChoiceModel[]): Select2Data[] {
        let returnValue: Select2Data[] = new Array();
        for (let choice of choiceData) {
            let value = new Select2Data();
            value.id = choice.attributeChoiceId;
            value.text = choice.attributeChoiceName ? choice.attributeChoiceName : choice.serviceName;
            returnValue.push(value);
        }
        return returnValue;
    }

    private setEmptySingleDropdown(ctl: AbstractControl) {
        ctl.get(field.CHOICE_DATA).setValue(new BehaviorSubject<AttributeChoiceModel[]>(null));
        ctl.get(field.MULCHOICE_FLAG).setValue(false);
    }

    public findDuplicateAllRow(): boolean {
        let returnValue: boolean = false;
        let duplicate: boolean = false;
        if (this.forms.controls.length != 0) {
            let formCurrent = this.forms.controls[this.forms.controls.length - 1];
            formCurrent.get(field.SUBMITTED).setValue(true);

            for (let i = 0; i <= this.forms.controls.length - 1; i++) {
                let ctl = this.forms.controls[i];
                returnValue = this.findDuplicate(ctl, i);
                if (returnValue) {
                    this.updateValidatorDuplicate(ctl, true);
                } else {
                    this.updateValidatorDuplicate(ctl, false);
                }
                if (returnValue) {
                    duplicate = true;
                }
            }
        }
        this.changeDetectionRef.detectChanges();
        return duplicate;
    }

    private findInvalidRows(): boolean {
        for (let ctl of this.forms.controls) {
            if (ctl.status == AttributeRuleConstant.STATUS_INVALID) {
                return true;
            }
        }
        return false;
    }

    private findDuplicate(ctlCheck, ctlIndex) {
        for (let i = 0; i <= this.forms.controls.length - 1; i++) {
            if (i != ctlIndex) {
                let ctl = this.forms.controls[i];
                if (this.equalValue(ctl, ctlCheck)) {
                    return true;
                }
            }
        }
        return false;
    }

    private equalValue(ctl, ctlCheck): boolean {
        if (ctl.value.productAttributeTypeCode == ctlCheck.value.productAttributeTypeCode) {
            if (ctl.value.multipleChoiceFlag) {
                if (this.checkArrayEqual(ctl.value.choiceValue, ctlCheck.value.choiceValue)) {
                    return true;
                }
            } else {
                if (ctl.value.choiceValue == ctlCheck.value.choiceValue) {
                    return true;
                }
            }
        }
        return false;
    }

    private updateValidatorDuplicate(formCurrent, required: boolean = false) {
        if (required) {
            formCurrent.get(field.DUPLICATE).setValue(null);
            formCurrent.get(field.DUPLICATE).setValidators(Validators.required);
            formCurrent.get(field.DUPLICATE).updateValueAndValidity();
        } else {
            formCurrent.get(field.DUPLICATE).setValue(false);
            formCurrent.get(field.DUPLICATE).setValidators(Validators.nullValidator);
            formCurrent.get(field.DUPLICATE).updateValueAndValidity();
        }
    }

    private checkArrayEqual(array1: string[], array2: string[]): boolean {
        let returnValue: boolean = true;
        if (array1.length > 0 && array2.length > 0) {
            if (array1.length == array2.length) {
                for (let value of array1) {
                    var filter = array2.filter(x => x == value);
                    if (filter.length == 0) {
                        return false;
                    }
                }
            }
            return false;
        }
        return returnValue;
    }

    public checkHiddenDelete(ctl): boolean {
        if (!this.checkIsRequiredFlag(ctl.get(field.ATTRTYPE_CODE).value) || this.searchMode) {
            return false;
        }
        return true;
    }


    public clearForms() {
        this.actionForm = this.fb.group({
            forms: this.fb.array([])
        });
        this.removedAttribute = [];
    }

    public addDataToFormGroup() {
        this.clearForms();
        if (!this.productAttributes) {
            return;
        }
        let displaySequenceAdded: number[] = new Array();
        for (let productAttribute of this.productAttributes) {
            let filterDisplaySequence = displaySequenceAdded.filter(x => x == productAttribute.displaySequence);
            if (filterDisplaySequence.length == 0) {
                if (this.canGetProductAttributeType(productAttribute)) {
                    this.assignDataToForm(productAttribute);
                    let formCurrent = this.forms.controls[this.forms.controls.length - 1];
                    formCurrent.get(field.SUBMITTED).setValue(true);
                    this.removeAttributeFromOption(this.filtereAttributeType(productAttribute));
                }
                displaySequenceAdded.push(productAttribute.displaySequence);
            }
        }
        this.changeDetectionRef.detectChanges();
    }

    private assignDataToForm(productAttribute: ProductAttributeViewModel) {
        if (productAttribute.multipleChoiceFlag) {
            let choiceMultiple = this.createChoiceMultipleData(productAttribute);
            let choiceArrayDBId = this.createChoiceDBIdData(productAttribute);
            this.forms.push(
                this.createFormGroupWithData(productAttribute, choiceMultiple, choiceArrayDBId));
        } else {
            this.forms.push(
                this.createFormGroupWithData(productAttribute));
        }
    }

    private createFormGroupEmpty(productAttributeTypeReference) {
        let form = {};
        if (productAttributeTypeReference) {
            form = this.assignAttributeToEmptyForm(
                productAttributeTypeReference.attributeTypeCode,
                productAttributeTypeReference.dimensionUnitCode,
                productAttributeTypeReference.multipleChoiceFlag);
        } else {
            form = this.assignAttributeToEmptyForm(null, null, false);
        }
        let f = this.fb.group(form);
        return f;
    }

    private createFormGroupWithData(productAttribute: ProductAttributeViewModel, choiceMultipleValue: string[] = null,
        choiceArrayDBId: Select2Data[] = null,
        disabled: boolean = false) {
        let form = {};
        form[field.DIMENSIONUNIT_CODE] = [productAttribute.dimensionUnitCode, [Validators.nullValidator]];
        form[field.ATTR_ID] = [productAttribute.productAttributeId, [Validators.nullValidator]];
        form[field.MULCHOICE_FLAG] = [productAttribute.multipleChoiceFlag, [Validators.nullValidator]];
        form[field.CHOICE_DATA] = [null, [Validators.nullValidator]];
        form[field.DUPLICATE] = [false, [Validators.nullValidator]];
        form[field.SUBMITTED] = [true, [Validators.nullValidator]];
        form[field.REQUIRED] = [this.checkIsRequiredFlag(productAttribute.attributeTypeCode) ? true : false, [Validators.nullValidator]];
        form[field.MIN] = [this.attributeMapperService.getMinimunValue(productAttribute.attributeTypeCode, this.domainAttribute$), [Validators.nullValidator]];
        form[field.MAX] = [this.attributeMapperService.getMaximumValue(productAttribute.attributeTypeCode, this.domainAttribute$), [Validators.nullValidator]];
        this.assignValueToAttribute(form, productAttribute, disabled);
        if (productAttribute.dimensionUnitCode == OopsChoiceValueConstant.CHOICE) {
            this.assignValueToChoiceData(form, productAttribute);
            this.assignValueToChoiceValue(productAttribute.multipleChoiceFlag, choiceMultipleValue, form, choiceArrayDBId, productAttribute, disabled);
        } else {
            this.attributeMapperService.assignValue(form, productAttribute, disabled, this.choiceOption);
        }
        let f = this.fb.group(form);
        if (this.checkIsRequiredFlag(productAttribute.attributeTypeCode)) {
            this.setDataAttributeTypeRequireFlag(f, productAttribute.attributeTypeCode);
        }
        return f;
    }

    private displayShowOnNewFlag() {
        if (this.domainAttribute$.value) {
            this.clearForms();

            let required = this.domainAttribute$.value.filter(x => x.requiredFlag == true);
            this.display(required);

            let showOnNew = this.domainAttribute$.value.filter(x => x.showOnNewFlag == true && x.requiredFlag != true);
            this.display(showOnNew);
            this.changeDetectionRef.detectChanges();
        }
    }

    display(domainAttributeRequire: DomainAttributeModel[]) {
        for (let productAttributeTypeReference of domainAttributeRequire) {
            (<UntypedFormArray>this.actionForm.get('forms')).push(this.createFormGroupEmpty(productAttributeTypeReference));
            let formCurrent = this.forms.controls[this.forms.controls.length - 1];
            formCurrent.get(field.SUBMITTED).setValue(true);
            if (productAttributeTypeReference.requiredFlag) {
                this.setDataAttributeTypeRequireFlag(formCurrent, productAttributeTypeReference.attributeTypeCode);
            }
            this.attributeChange(productAttributeTypeReference.attributeTypeCode, formCurrent);
        }
    }

    private checkIsRequiredFlag(productAttributeTypeCode: string) {
        if (productAttributeTypeCode && this.domainAttribute$.value) {
            var filter = this.domainAttribute$.value.filter(x =>
                x.attributeTypeCode == productAttributeTypeCode && x.requiredFlag == true);
            if (filter?.length) {
                return true;
            }
        }
        return false;
    }

    private canGetProductAttributeType(productAttribute: ProductAttributeViewModel): boolean {
        var returnValue: boolean = false;
        if (this.domainAttribute$.value) {
            var attribute = this.domainAttribute$.value;
            var attributeFilter = attribute.filter(x => x.attributeTypeCode == productAttribute.attributeTypeCode);
            if (attributeFilter.length > 0) {
                returnValue = true;
            }
        }
        return returnValue;
    }

    private assignValueToAttribute(form: any, productAttribute: ProductAttributeViewModel, disabled: boolean) {
        form[field.ATTRTYPE_CODE] = [{ value: productAttribute.attributeTypeCode, disabled: disabled }, [Validators.required]];
        form[field.ATTRTYPE_DATA] = [this.createDomainAttributeNotRequire(this.domainAttribute$), [Validators.nullValidator]];
    }

    private createDomainAttributeNotRequire(domainAttributeBliding$: BehaviorSubject<DomainAttributeModel[]>): BehaviorSubject<DomainAttributeModel[]> {
        if (!domainAttributeBliding$.value) {
            return new BehaviorSubject<DomainAttributeModel[]>(null);
        }
        if (!this.searchMode) {
            let filterNoRequireFlag = domainAttributeBliding$.value.filter(x => x.requiredFlag != true);
            let domainAttributeNoRequireFlag$ = new BehaviorSubject<DomainAttributeModel[]>(null);
            domainAttributeNoRequireFlag$.next(filterNoRequireFlag);
            return domainAttributeNoRequireFlag$;
        }
        return domainAttributeBliding$;
    }

    private createChoiceMultipleData(productAttribute: ProductAttributeViewModel): string[] {
        let filterProductAttributeTypes = this.productAttributes.filter(x => x.displaySequence == productAttribute.displaySequence);
        let choiceMultiple: string[] = new Array<string>();
        for (let productAttributeChoice of filterProductAttributeTypes) {
            choiceMultiple.push(productAttributeChoice.attributeChoiceId);
        }
        return choiceMultiple;
    }

    private createChoiceDBIdData(productAttribute: ProductAttributeViewModel): Select2Data[] {
        let filterProductAttributeTypes = this.productAttributes.filter(x => x.displaySequence == productAttribute.displaySequence);
        let choiceArrayDBId: Select2Data[] = new Array();
        for (let productAttributeChoice of filterProductAttributeTypes) {
            let dbIdData = new Select2Data();
            dbIdData.id = productAttributeChoice.productAttributeId;
            dbIdData.text = productAttributeChoice.attributeChoiceId;
            choiceArrayDBId.push(dbIdData);
        }
        return choiceArrayDBId;
    }

    private assignValueToChoiceData(form: any, productAttribute: ProductAttributeViewModel) {
        if (this.domainAttribute$.value) {
            let attribute = this.domainAttribute$.value;
            let attributeFilter = attribute.filter(x => x.attributeTypeCode == productAttribute.attributeTypeCode);
            if (attributeFilter.length > 0) {
                let choiceData = this.convertToSelect2Data(attributeFilter[0].attributeChoices);
                form[field.CHOICE_DATA] = [choiceData, [Validators.nullValidator]];
            } else {
                form[field.CHOICE_DATA] = [null, [Validators.nullValidator]];
            }
        }
    }

    private assignValueToChoiceValue(multipleChoiceFlag: boolean,
        choiceMultipleValue: string[], form: any, choiceArrayDBId: Select2Data[],
        productAttribute: ProductAttributeViewModel, disabled: boolean) {
        form[field.CHOICE_ID] = [choiceArrayDBId, [Validators.nullValidator]];
        if (multipleChoiceFlag) {
            form[field.CHOICE_OPTOIN] = [this.choiceMultipleOption, [Validators.nullValidator]];
            form[field.CHOICE_VALUE] = [{ value: choiceMultipleValue, disabled: disabled }, [Validators.required]];
        } else {
            form[field.CHOICE_OPTOIN] = [this.choiceOption, [Validators.nullValidator]];
            form[field.CHOICE_VALUE] = [{ value: productAttribute.attributeChoiceId, disabled: disabled }, [Validators.required]];
        }
    }

    private setDataAttributeTypeRequireFlag(formCurrent: AbstractControl, attributeTypeCode: string) {
        formCurrent.get(field.REQUIRED).setValue(true);
        let domainAttributeRequireFlag$ = new BehaviorSubject<DomainAttributeModel[]>(null);
        let filterAttributeType = this.domainAttribute$.value.filter(x => x.attributeTypeCode == attributeTypeCode);
        domainAttributeRequireFlag$.next(filterAttributeType);
        formCurrent.get(field.ATTRTYPE_DATA).setValue(domainAttributeRequireFlag$);
    }

    public findRequireAttribute(): boolean {
        if (this.forms.controls.length != 0) {
            let formCurrent = this.forms.controls[this.forms.controls.length - 1];
            formCurrent.get(field.SUBMITTED).setValue(true);
            this.changeDetectionRef.detectChanges();
            for (var i = 0; i <= this.forms.controls.length - 1; i++) {
                var ctl = this.forms.controls[i];
                if (this.chekRequireAttribute(ctl)) {
                    return true;
                }
            }
        }
        return false;
    }

    private chekRequireAttribute(ctl) {
        return ctl.value.required && ((ctl.value.multipleChoiceFlag && !ctl.value.choiceValue?.length) || (!ctl.value.choiceValue))
    }

    public rowInValid(ctl: AbstractControl): boolean {
        return (ctl.get(field.CHOICE_VALUE).invalid || ctl.get(field.DUPLICATE).invalid) && ctl.get(field.SUBMITTED).value == true;
    }

    dataChange(value: string | string[], ctl) {
        ctl.get(field.CHOICE_VALUE).setValue(value);
    }

    private assignAttributeToEmptyForm(attributeTypeCode: string, dimensionUnitCode: string, multipleChoiceFlag: boolean) {
        let form = {};
        form[field.SUBMITTED] = [null, [Validators.nullValidator]];
        form[field.REQUIRED] = [null, [Validators.nullValidator]];
        form[field.CHOICE_ID] = [null, [Validators.nullValidator]];
        form[field.ATTRTYPE_CODE] = [attributeTypeCode, [Validators.required]];
        form[field.ATTRTYPE_DATA] = [this.createDomainAttributeNotRequire(this.domainAttribute$), [Validators.nullValidator]];
        form[field.DIMENSIONUNIT_CODE] = [dimensionUnitCode, [Validators.nullValidator]];
        form[field.MULCHOICE_FLAG] = [multipleChoiceFlag, [Validators.nullValidator]];
        form[field.CHOICE_DATA] = [null, [Validators.nullValidator]];
        form[field.CHOICE_VALUE] = [null, [Validators.required]];
        form[field.DUPLICATE] = [false, [Validators.nullValidator]];
        form[field.MIN] = [this.attributeMapperService.getMinimunValue(attributeTypeCode, this.domainAttribute$), [Validators.nullValidator]];
        form[field.MAX] = [this.attributeMapperService.getMaximumValue(attributeTypeCode, this.domainAttribute$), [Validators.nullValidator]];
        if (multipleChoiceFlag) {
            form[field.CHOICE_OPTOIN] = [this.choiceMultipleOption, [Validators.nullValidator]];
        } else {
            form[field.CHOICE_OPTOIN] = [this.choiceOption, [Validators.nullValidator]];
        }
        return form;
    }

    public clearForm() {
        this.actionForm = this.fb.group({
            forms: this.fb.array([])
        });
        this.productAttributes = [];
        this.changeDetectionRef.detectChanges();
    }

    public getAttributeId(): string {
        if (this.searchMode) {
            return "ddlAttributeSearch";
        }
        return "ddlAttribute";
    }

    public get addDisable() {
        return (this.rootLevel || this.newProduct) && !this.searchMode;
    }

    public get allowCollapseToggle() {
        return (this.forms.controls.length != 0 || this.searchMode == false);
    }

    public getErrorMessage() {
        if (this.findRequireAttribute()) {
            return this.ATTRIBUTE_REQUIRED_ERROR;
        } else {
            return;
        }
    }

    private noAttribute() : boolean {
        if (!this.domainAttribute$.value?.length || !this.domainAttribute$?.value[0]) {
            return true;
        }
        return false;
    }

    private filtereAttributeType(productAttribute: ProductAttributeViewModel): DomainAttributeModel {
        if (this.domainAttribute$.value) {
            var attribute = this.domainAttribute$.value;
            var attributeFilter = attribute.filter(x => x.attributeTypeCode == productAttribute.attributeTypeCode);
            if (attributeFilter.length > 0) {
               return attributeFilter[0];
            }
        }
        return null;
    }
}
