import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgxSummernoteModule } from 'ngx-summernote';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { ButtonsModule } from 'ngx-bootstrap/buttons';
import { CollapseModule } from 'ngx-bootstrap/collapse';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { DxDataGridModule } from 'devextreme-angular';
import { SweetAlert2Module } from '@sweetalert2/ngx-sweetalert2';

import { DialogsService } from 'src/app/shared/dialogs/dialogs.service';
import { PanelsModule } from 'src/app/shared/panels/panels.module';
import { DialogsModule } from 'src/app/shared/dialogs/dialogs.module';
import { Select2Module } from 'src/app/shared/ui/forms/inputs/oops-select2/select2.module';
import { FocusingModule } from 'src/app/shared/ui/forms/inputs/focusing.module';
import { DaterangepickerModule } from 'src/app/shared/ui/forms/inputs/oops-daterangepicker/daterangepicker.module';
import { Helper } from 'src/app/shared/helper/app.helper';
import { UtilsModule } from 'src/app/shared/utils/utils.module';

import { TextComponent } from './text.component';
import { TextTableComponent } from './text-table/text-table.component';
import { TextDetailComponent } from './text-detail/text-detail.component';
import { TextDetailDirective } from './text-detail/text-detail.directive';
import { TextLibraryTableComponent } from './text-library-table/text-library-table.component';
import { TextLibraryTableDirective } from './text-library-table/text-library-table.directive';




@NgModule({
    declarations: [
        TextComponent,
        TextTableComponent,
        TextDetailComponent,
        TextDetailDirective,
        TextLibraryTableComponent,
        TextLibraryTableDirective
    ],
    exports: [
        TextComponent, TextTableComponent, TextDetailComponent, TextDetailDirective,
        TextLibraryTableComponent, TextLibraryTableDirective
    ],
    imports: [
        CommonModule,
        PanelsModule,
        ReactiveFormsModule,
        FocusingModule,
        FormsModule,
        DialogsModule,
        MatIconModule,
        NgbModule,
        Select2Module,
        CollapseModule,
        DaterangepickerModule,
        NgxSummernoteModule,
        ButtonsModule,
        BsDropdownModule,
        UtilsModule,
        TooltipModule,
        DxDataGridModule,
        SweetAlert2Module
    ],
    providers: [
        DialogsService, Helper
    ]
})
export class TextModule {
}