import { Injectable } from '@angular/core';
import { PagingDataView } from '../../../../../../../core/views/pagging-data.view';
import { Select2Data } from 'src/app/shared/ui/forms/inputs/oops-select2';
import { MediaHashTagModel } from 'src/app/core/models/reference-model/reference-media-model';
import { AncillaryServiceSearchTableView } from './ancillary-service-search-table.view';
import { AncillaryServiceSearchCommandModel, AncillaryServiceViewModel } from 'src/app/core/models/product-model/ancillary-service-model';

@Injectable()
export class MapperService {

    constructor() {
    }

    public ancillaryServiceSearchTableViews(models: AncillaryServiceViewModel[], pagingView: PagingDataView[]): AncillaryServiceSearchTableView[] {
        let views = new Array();
        let i = 0;
        for (let model of models) {
            ++i;
            views.push(this.ancillaryServiceSearchTableView(i, model));
            pagingView.push(this.paggingView(i, model.productId));
        }

        return views;
    }

    public ancillaryServiceSearchTableView(index: number, model: AncillaryServiceViewModel): AncillaryServiceSearchTableView {
        let view: AncillaryServiceSearchTableView = {
            no: index,
            id: model.productId,
            rootProductId: model.rootProductId,
            name: model.productName,
            productGroupName: model.productGroupName,
            productTypeName: model.productTypeName,
            statusCode: model.statusCode,
            usageTypeCode: model.usageTypeCode,
            usageTypeName: model.usageTypeName,
            commitBy: model.commitBy,
            commitDateTime: model.commitDateTime,
            productGroupCode: model.productGroupCode,
            productTypeCode: model.productTypeCode,
            productTypeGroupCode: null,
            productTypeGroupName: null,
            statusName: null
        }

        return view;
    }

    public paggingView(index: number, id: string): PagingDataView {
        let pview = new PagingDataView();
        pview.no = index;
        pview.id = id;
        return pview;
    }

    public mediaHashTagToSelect2Data(mediaHashTags: MediaHashTagModel[]): Select2Data[] {
        let select2Data: Select2Data[] = new Array();
        for (let mediaHash of mediaHashTags) {
            let data = new Select2Data();
            data.id = mediaHash.mediaHashTagId
            data.text = mediaHash.mediaHashTagName;
            select2Data.push(data);
        }
        return select2Data;
    }

    public convertToSearchCommand(product: AncillaryServiceViewModel): AncillaryServiceSearchCommandModel {
        let command: AncillaryServiceSearchCommandModel = {
            productCategoryCode: null,
            productGroupCode: product.productGroupCode,
            productTypeCode: product.productTypeCode,
            productName: product.productName,
            searchName: product.searchName,
            searchUsageTypeCode: product.searchUsageTypeCode,
            searchStatusCode: product.searchStatusCode,
            filterUserAccountId: product.filterUserAccountId,
            statusCode: product.statusCode,
            productHashTags: [],
            productNumbers: [],
            productRestrictionProducts: [],
            productRestrictionProductNumbers: [],
            productRestrictionRoutes: [],
            productRestrictionLocations: [],
            productValidities: [],
            productAttributes: [],
            productRestrictionOrganisations: [],
            seasonCode: null
        };
        return command;
    }
}
