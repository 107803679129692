import { Component, ChangeDetectionStrategy, Output, EventEmitter, Input, OnInit, SimpleChanges, OnChanges, ViewChild, ChangeDetectorRef, ComponentFactoryResolver } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { NgForm } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import Swal from 'sweetalert2'
import { SwalComponent } from '@sweetalert2/ngx-sweetalert2';

import { select2TextType, validDateFromOption, validDateToOption, select2HashtagOption } from '../shared/media-configuration';
import {
    LanguageReferenceModel,
    MediaTypeFileTypeModel,
    MediaTypeReferenceModel,
    MediaUseReferenceModel
} from 'src/app/core/models/reference-model/reference-media-model';

import { Select2Data } from 'src/app/shared/ui/forms/inputs/oops-select2';
import { MediaView } from '../shared/media.view';
import { MediaSearchCommandModel, MediaViewModel } from 'src/app/core/models/media-model';
import { MediaMapperService } from '../shared/media-mapper.service';
import { MediaService } from 'src/app/core/services/media-services';
import { AuthService } from 'src/app/core/authentication/auth.service';
import { NgxDropzoneImagePreviewComponent } from 'ngx-dropzone';
import { MediaFileView } from '../shared/media-file.view';
import { MediaFilePreviewService } from 'src/app/core/services/media-services/media-file-preview-service';
import { MediaLibraryTableComponent } from '../media-library-table/media-library-table.component';

@Component({
    selector: 'op-media-detail',
    templateUrl: './media-detail.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [MediaMapperService]
})
export class MediaDetailComponent implements OnInit, OnChanges {

    @Input() model = new MediaView();
    @Input() languageReference$ = new BehaviorSubject<LanguageReferenceModel[]>(null);
    @Input() mediaTypeReference$ = new BehaviorSubject<MediaTypeReferenceModel[]>(null);
    @Input() mediaUseReference$ = new BehaviorSubject<MediaUseReferenceModel[]>(null);
    @Input() mediaHashTagSelect2Data: Select2Data[];
    @Input() mediaTypeFileType$ = new BehaviorSubject<MediaTypeFileTypeModel[]>(null);
    @Input() singleRecord: boolean = true;
    @Input() textImageUploadProcessing: boolean = false;
    @Input() textVideoUploadProcessing: boolean = false;
    @Input() disabled: boolean = false;

    @Output() onSave = new EventEmitter<MediaView>();
    @Output() onCancel = new EventEmitter();
    @Output() onSaveMediaLibrary = new EventEmitter<MediaView[]>();
    @Output() onClickMediaLibrary = new EventEmitter<MediaSearchCommandModel>();
    @Output() onClickMediaLibraryFromTextPanel = new EventEmitter<MediaSearchCommandModel>();
    @Output() onSaveMultipleFile = new EventEmitter<File[]>();
    @Output() onCancelTextUploadProcessing = new EventEmitter();
    @Output() onSaveToDB = new EventEmitter();

    @ViewChild('mediaForm') mediaForm: NgForm;
    @ViewChild('previewImage') previewImage: NgxDropzoneImagePreviewComponent;
    @ViewChild('swal')
    public readonly swal!: SwalComponent;
    @ViewChild(MediaLibraryTableComponent) mediaLibraryTableComponent: MediaLibraryTableComponent;

    public action =
        {
            submitted: false,
            browsed: false,
            mediaHashTag: false
        };

    readonly DROPZONE_BINDING_DELAY_TIME = 200;
    public textTypeOption: any;
    public validDateFromOption: any;
    public validDateToOption: any;
    public mediaHashTagOption: any;
    public fileChange: boolean = false;
    public browseLibrary: boolean = false;
    public mediaSearchCommand = new MediaSearchCommandModel();
    public mediaTypeReferenceFilter$ = new BehaviorSubject<MediaTypeReferenceModel[]>(null);
    public mediaDetailModel: MediaView;
    public files: MediaFileView[] = [];
    public loadFileExistFinished: boolean = false;
    public maximumFileSize: boolean = false;
    public fileTypeInvalid: boolean = false;
    public canPreviewFile: boolean = false;
    public canDownloadFile: boolean = false;
    public fileNotFound: boolean = false;
    public urlPreviewFile: any;
    public viewerType: string;
    public callFromTextPanel: boolean = false;
    public saveToDBProcess: boolean = false;

    constructor(private mediaService: MediaService,
        private autService: AuthService,
        private mediaMapperService: MediaMapperService,
        private changeDectionRef: ChangeDetectorRef,
        private mediaFilePreviewService: MediaFilePreviewService,
        private domSanitizer: DomSanitizer
    ) {
        this.textTypeOption = select2TextType;
        this.validDateFromOption = validDateFromOption;
        this.validDateToOption = validDateToOption;
        this.mediaHashTagOption = select2HashtagOption;
    }

    ngOnInit(): void {
        this.initialForm();
    }

    ngOnChanges(changes: SimpleChanges): void {
        this.action.submitted = false;
        this.action.mediaHashTag = false;
        this.initialForm();
        this.setupDisabled();
    }

    private initialForm() {
        this.filterMediaType(this.model.mediaUseCode);
        this.mediaMapperService.setDefaultPrimaryLanguage(this.languageReference$, this.model);
        this.getMediaFile();
        this.setToTextMediaProcessForm();
    }

    private setupDisabled() {
        if (this.disabled == true) {
            this.mediaForm?.form?.disable();
            return;
        }
        this.mediaForm?.form?.enable();
    }
    
    get shouldShowSelectFile(): boolean {
        return this.files.length == 0 && (this.action.submitted) && !this.fileTypeInvalid && !this.maximumFileSize;
    }

    public validateForm(): boolean {
        this.action.submitted = true;
        return this._validateForm(this.mediaForm);
    }

    private _validateForm(form: NgForm): boolean {
        if (!form.valid || !this.model.mediaHashTagIds?.length || !this.files?.length) {
            this.changeDectionRef.detectChanges();
            return;
        }
        return true;
    }

    public save() {
        this._save(this.mediaForm);
    }

    private _save(form: NgForm) {
        this.action.submitted = true;
        this.maximumFileSize = false;
        this.fileTypeInvalid = false;
        if (!form.valid || !this.model.mediaHashTagIds?.length || !this.files?.length) {
            this.changeDectionRef.detectChanges();
            return;
        }
        this.model.mediaHashTagName = this.mediaMapperService.getHashTagName(this.model.mediaHashTagIds, this.mediaHashTagSelect2Data);
        this.model.mediaUseName = this.mediaMapperService.getMediaUseName(this.model.mediaUseCode, this.mediaUseReference$);
        this.model.mediaTypeName = this.mediaMapperService.getMediaTypeName(this.model.mediaTypeCode, this.mediaTypeReference$);
        this.model.languageName = this.mediaMapperService.getLanguageName(this.model.languageCode, this.languageReference$);
        this.model.fileChange = this.fileChange;
        this.model.mediaFileBase64 = this.files[0].mediaFileBase64;
        this.model.mediaFile = this.files[0].mediaFile;
        const reader = new FileReader();
        reader.readAsDataURL(this.files[0].mediaFilePreview);
        reader.onload = () => {
            this.model.mediaFilePreview = this.files[0].mediaFilePreview;
            this.model.mediaFilePreviewBase64 = reader.result;
            this.saveInCondition();
        };
    }

    private saveInCondition() {
        if (this.callFromTextPanel) {
            this.saveMediaToDB();
        }
        else {
            this.onSave.emit(this.model);
        }
    }

    public cancel(form: NgForm) {
        this.action.submitted = false;
        this.fileChange = false;

        this.model = new MediaView();
        this.mediaMapperService.setDefaultPrimaryLanguage(this.languageReference$, this.model);
        this.mediaTypeReferenceFilter$ = new BehaviorSubject<MediaTypeReferenceModel[]>(null);
        this.maximumFileSize = false;
        this.fileTypeInvalid = false;
        form.resetForm(this.model);

        if (this.callFromTextPanel) {
            this.textImageUploadProcessing = false;
            this.textImageUploadProcessing = false;
            this.callFromTextPanel = false;
            this.onCancelTextUploadProcessing.emit();
        }
        else {
            this.onCancel.emit();
        }
    }

    public cancelCallFromText() {
        this.action.submitted = false;
        this.fileChange = false;

        this.model = new MediaView();
        this.mediaMapperService.setDefaultPrimaryLanguage(this.languageReference$, this.model);
        this.mediaTypeReferenceFilter$ = new BehaviorSubject<MediaTypeReferenceModel[]>(null);
        this.maximumFileSize = false;
        this.fileTypeInvalid = false;

        if (this.callFromTextPanel) {
            this.textImageUploadProcessing = false;
            this.textImageUploadProcessing = false;
            this.callFromTextPanel = false;
            this.changeDectionRef.detectChanges();
            this.onCancelTextUploadProcessing.emit();
        }
    }

    public isSaveDisable(form: NgForm): boolean {
        if (this.model.selectedFromLibrary || this.callFromTextPanel) {
            return false;
        }
        if (this.fileChange) {
            return false;
        }
        if (!this.formTouched(form)) {
            return true;
        }
        return false;
    }

    public isCancelDisable(form: NgForm): boolean {
        if (this.fileChange || this.callFromTextPanel) {
            return false;
        }
        if (this.singleRecord && !this.formTouched(form)) {
            return true;
        }
        return false;
    }

    private formTouched(form: NgForm): boolean {
        return (form.touched || this.action.mediaHashTag);
    }

    public mediaHashTagSelect() {
        this.action.mediaHashTag = true;
    }

    public validFromChange(value) {
        this.model.validFrom = value;
    }

    public validToChange(value) {
        this.model.validTo = value;
    }

    public browseLibraryClick() {
        this.maximumFileSize = false;
        this.fileTypeInvalid = false;
        if (this.validateClickBrowse()) {
            this.action.browsed = false;
            if (!this.callFromTextPanel) {
                this.mediaSearchCommand = this.mediaMapperService.mediaFileToSearchCommand(this.model);
            }
            this.onClickMediaLibrary.emit(this.mediaSearchCommand);
        }
        else {
            this.action.browsed = true;
        }
    }

    private validateClickBrowse(): boolean {
        return !!(this.model.mediaUseCode);
    }


    public saveMediaBrowse() {
        var mediaLibrarySelected = this.mediaLibraryTableComponent.mediaFileLibrarys.filter(x => x.selected == true);
        var viewMedias = this.mediaMapperService.mediaFileLibraryToMediaTextViews(mediaLibrarySelected);
        this.onSaveMediaLibrary.emit(viewMedias);
    }

    editMode(): boolean {
        if (this.model.mediaId) {
            return true;
        }
        else {
            return false;
        }
    }

    public mediaUseChange(value) {
        this.filterMediaType(value);
    }

    private filterMediaType(mediaUseCode: string) {
        if (this.mediaTypeReference$.value) {
            this.mediaTypeReferenceFilter$ = new BehaviorSubject<MediaTypeReferenceModel[]>(null);
            if (mediaUseCode) {
                this.filterMediaTypeData(mediaUseCode);
            }
            else {
                this.clearMediaType();
            }
        }
    }

    private filterMediaTypeData(mediaUseCode: string) {
        var filter = this.mediaTypeReference$.value.filter(x => x.mediaUseCode == mediaUseCode);
        this.mediaTypeReferenceFilter$.next(filter);
        this.assignToMediaTypeCode(filter)
    }

    private assignToMediaTypeCode(filter: MediaTypeReferenceModel[]) {
        if (filter.length != 0) {
            this.model.mediaTypeCode = filter[0].mediaTypeCode;
        }
        else {
            this.clearMediaType();
        }
    }

    private clearMediaType() {
        this.model.mediaTypeCode = null;
        this.model.mediaTypeName = null;
    }

    onSelect(event) {
        if (event.addedFiles.length > 1) {
            this.multipleFileProcess(event.addedFiles);
        }
        else {
            this.singleFileProcess(event.addedFiles[0]);
        }
    }

    private singleFileProcess(addedFile: File) {
        this.clearFile();
        if (!this.validateFile(addedFile)) {
            return;
        }
        this.model.fileChange = true;
        this.fileChange = true;
        let mediaFile = this.createMediaFileView(addedFile);
        this.displayFilePreview(mediaFile, addedFile.type);
    }

    private multipleFileProcess(addedFiles: File[]) {
        if (!this.validateMultipleFile(addedFiles)) {
            return;
        }
        else {
            this.onSaveMultipleFile.emit(addedFiles);
        }
    }

    private createMediaFileView(fileData: File): MediaFileView {
        let mediaFileView = new MediaFileView();
        mediaFileView.mediaFile = fileData;
        const reader = new FileReader();
        reader.readAsDataURL(fileData);
        reader.onload = () => {
            mediaFileView.mediaFileBase64 = reader.result;
        };
        return mediaFileView;
    }

    private displayFilePreview(mediaFile: MediaFileView, contentType: string) {
        this.canDownloadFile = true;
        if (this.mediaMapperService.isImage(contentType)) {
            this.canPreviewFile = true;
            mediaFile.mediaFilePreview = mediaFile.mediaFile;
            this.addFileToDropZone(mediaFile);
            this.refreshPage();
            this.touchedPage();
        }
        else if (this.mediaMapperService.isVideo(contentType) || this.mediaMapperService.isPdf(contentType)) {
            this.canPreviewFile = true;
            this.getPicFilePreview(mediaFile, contentType);
        }
        else if (this.mediaMapperService.isDoc(contentType)) {
            this.canPreviewFile = (this.checkCanPreviewFile() && !this.model.fileChange);
            this.getPicFilePreview(mediaFile, contentType);
        } else {
            this.getPicFilePreview(mediaFile, contentType);
        }
    }

    private getPicFilePreview(mediaFile: MediaFileView, contentType: string) {
        var fileName = this.mediaMapperService.getPicPreview(contentType)
        this.mediaFilePreviewService.getMediaFilePreview(fileName)
            .subscribe(
                (response) => {
                    if (response.status == 200) {
                        mediaFile.mediaFilePreview = new File([response.body], fileName, { type: "image/jpeg" });
                        this.addFileToDropZone(mediaFile);
                        this.refreshPage();
                    }
                }
            );
    }

    private addFileToDropZone(mediaFile: MediaFileView) {
        this.files = [];
        this.files.push(mediaFile);
        try {
            this.changeDectionRef.detectChanges();
        }
        catch{
        }
    }

    onRemove(event) {
        this.files.splice(this.files.indexOf(event), 1);
        this.fileChange = true;
        this.clearFile();
    }

    public displayPreviewFileSize() {
        if (this.files?.length) {
            let totalBytes = this.files[0].mediaFile.size;
            var sizeDisplay = this.mediaMapperService.formatBytes(totalBytes);
            return sizeDisplay;
        }
    }

    public displayPreviewFileType() {
        if (this.files?.length) {
            let fileTypeDisplay = this.mediaMapperService.getFileExt(this.files[0].mediaFile.name);
            return fileTypeDisplay.toUpperCase();
        }
    }

    private getMediaFile() {
        if (this.model.mediaFile) {
            let mediaFile = this.createMediaFileView(this.model.mediaFile);
            this.displayFilePreview(mediaFile, mediaFile.mediaFile.type);
        } else if (this.model.mediaContentId && !this.model.mediaFileBase64) {
            this.loadFileExistFinished = false;
            this.mediaService.getMediaFile(this.model.mediaContentId)
                .subscribe(
                    (response) => {
                        this.clearFile();
                        if (response.status == 200) {
                            var contenType = response.headers.get("Content-Type");
                            var fileName = this.mediaMapperService.getfileName(this.autService.getSelectedOrganisation(), this.model.mediaContentId, contenType)
                            var file = new File([response.body], fileName, { type: contenType });
                            let mediaFile = this.createMediaFileView(file);
                            this.displayFilePreview(mediaFile, contenType);
                        }
                        else {
                            this.fileNotFound = true;
                            this.refreshPage();
                        }
                    }
                );
        }
        else {
            if (!this.model.mediaFileBase64) {
                this.clearFile();
            }
        }
    }

    private clearFile() {
        this.files = [];
        this.loadFileExistFinished = true;
        this.maximumFileSize = false;
        this.fileTypeInvalid = false;
        this.canPreviewFile = false;
        this.canDownloadFile = false;
        this.fileNotFound = false;
    }

    public loadFileFished(): boolean {
        if (this.model.mediaContentId && !this.model.mediaFileBase64) {
            return this.loadFileExistFinished;
        } else {
            return true;
        }
    }

    public downloadFile() {
        if (this.files?.length) {
            const blob = this.files[0].mediaFile;
            const url = window.URL.createObjectURL(blob);
            var anchor = document.createElement("a");
            anchor.download = this.files[0].mediaFile.name;
            anchor.href = url;
            anchor.click();
        }
    }

    public previewFile() {
        if (this.files?.length) {
            if (this.mediaMapperService.isImage(this.files[0].mediaFile.type)) {
                this.previewImageFile();
            }
            else {
                this.previewOtherFile();
            }
        }
    }

    private previewImageFile() {
        const blob = this.files[0].mediaFile;
        const url = window.URL.createObjectURL(blob);
        Swal.fire({
            html: '<div class="col-12"><img src="' + url + '" style="max-width:700px;" ></div>',
            width: '800px',
            showCloseButton: true,
            showConfirmButton: false
        });
    }

    private previewOtherFile() {
        const blob = this.files[0].mediaFile;
        if (this.mediaMapperService.isVideo(blob.type) ||
            this.mediaMapperService.isPdf(blob.type)) {
            this.viewerType = "url";
            this.urlPreviewFile = window.URL.createObjectURL(blob);
        }
        else if (this.mediaMapperService.isXLS(blob.type)) {
            this.viewerType = "google";
            this.urlPreviewFile = this.mediaFilePreviewService.getURLDocAzureStorage(this.model.mediaContentId);
        }
        else {
            this.viewerType = "office";
            this.urlPreviewFile = this.mediaFilePreviewService.getURLDocAzureStorage(this.model.mediaContentId);
        }
        this.swal.fire(
        );
    }

    private validateMultipleFile(addedFiles: File[]): Boolean {
        for (let f of addedFiles) {
            if (!this.validateFile(f)) {
                return false;
            }
        }
        return true;
    }

    private validateFile(f: File): Boolean {
        if (!this.validateFileType(f)) {
            this.fileTypeInvalid = true;
            return false;
        } else if (!this.validateFileSize(f)) {
            this.maximumFileSize = true;
            return false;
        }
        this.fileTypeInvalid = false;
        this.maximumFileSize = false;
        return true;
    }

    private validateFileType(f: File): Boolean {
        if (!this.mediaTypeFileType$.value) {
            return false;
        }
        var fileExt = this.mediaMapperService.getFileExt(f.name);
        var filterFileTypeValid = this.mediaTypeFileType$.value.filter(x => x.fileTypeCode.toLowerCase() == fileExt.toLowerCase());
        if (filterFileTypeValid?.length) {
            return this.checkFileTypeValid(filterFileTypeValid);
        }
        else {
            return false;
        }
    }

    private validateFileSize(f: File): Boolean {
        if (this.mediaTypeReference$.value) {
            var filter = this.mediaTypeReference$.value.filter(x => x.mediaTypeCode == this.model.mediaTypeCode);
            if (filter.length > 0) {
                if (filter[0].maximumSize) {
                    if (f.size <= filter[0].maximumSize) {
                        return true;
                    }
                }
            }
        }
        return false;
    }

    private checkFileTypeValid(fileTypeValid: MediaTypeFileTypeModel[]): boolean {
        if (this.model.mediaTypeCode) {
            var filter = fileTypeValid.filter(x => x.mediaTypeCode == this.model.mediaTypeCode);
            if (filter.length > 0) {
                return true;
            }
            else {
                return false;
            }
        }
        else {
            this.assignFileTypeToModel(fileTypeValid);
            return true;
        }
    }

    private assignFileTypeToModel(fileTypeValid: MediaTypeFileTypeModel[]) {
        if (!this.mediaTypeReference$.value) {
            return;
        }
        var mediaTypeCode = fileTypeValid[0].mediaTypeCode;
        var filter = this.mediaTypeReference$.value.filter(x => x.mediaTypeCode == mediaTypeCode);
        if (filter.length > 0) {
            this.model.mediaUseCode = filter[0].mediaUseCode;
            this.filterMediaType(this.model.mediaUseCode);
            this.model.mediaTypeCode = mediaTypeCode;
        }
    }

    private refreshPage() {
        setTimeout(() => {
            try {
                this.changeDectionRef.detectChanges();
            }
            catch (e) {
                e = null;
            }
        }, this.DROPZONE_BINDING_DELAY_TIME);
    }

    private touchedPage() {
        if (!this.singleRecord) {
            this.mediaForm.form.markAsTouched();
        }
    }

    private checkCanPreviewFile(): boolean {
        if (this.fileChange) {
            return false;
        }
        else {
            return true;
        }
    }

    public checkCanSelectMultiple(): boolean {
        if (this.files.length == 0) {
            return true;
        }
        else {
            return false;
        }
    }

    public setToTextMediaProcessForm() {
        if (this.textImageUploadProcessing || this.textVideoUploadProcessing) {
            this.callFromTextPanel = true;
            if (this.textImageUploadProcessing) {
                this.model.mediaUseCode = "PRODUCTGALL";
                this.model.textImage = true;
            }
            else {
                this.model.mediaUseCode = "PRODUCTVIDEO";
                this.model.textVideo = true;
            }
            this.filterMediaType(this.model.mediaUseCode);
            if (this.mediaForm) {
                this.mediaForm.form.markAsTouched();
            }
            this.changeDectionRef.detectChanges();
        }
    }

    private saveMediaToDB() {
        var mediaSave = this.mediaMapperService.mediaViewToMediaLibraryCommand(this.model);
        this.saveToDBProcess = true;
        this.changeDectionRef.detectChanges();
        this.onSaveToDB.emit();
        this.mediaService.save(mediaSave)
            .subscribe(
                (mediaReturn: MediaViewModel) => {
                    this.saveDBProcessCompleted();
                    this.model = this.mediaMapperService.mediaViewModelToMediaView(mediaReturn, this.model, this.languageReference$);
                    this.onSave.emit(this.model);
                }
            ),
            error => {
                this.saveDBProcessCompleted();
            }
    }

    private saveDBProcessCompleted(){
        this.saveToDBProcess = false;
        this.changeDectionRef.detectChanges();
    }

    get mediaHashTagIds(): string | string[] {
        return this.model.mediaHashTagIds;
    }

    set mediaHashTagIds(value: string | string[]) {
        this.model.mediaHashTagIds = <string[]>value;
    }

    get mediaUseCodes(): string | string[] {
        return this.model.mediaUseCode;
    }

    set mediaUseCodes(value: string | string[]) {
        this.model.mediaUseCode = <string>value;
    }

    get mediaTypeCodes(): string | string[] {
        return this.model.mediaTypeCode;
    }

    set mediaTypeCodes(value: string | string[]) {
        this.model.mediaTypeCode = <string>value;
    }
    
    get languageCodes(): string | string[] {
        return this.model.languageCode;
    }

    set languageCodes(value: string | string[]) {
        this.model.languageCode = <string>value;
    }
}
