import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, Output, SimpleChanges, ViewChild } from '@angular/core';
import { cloneDeep } from "lodash";
import { DxTreeListComponent } from 'devextreme-angular';
import { SpecialServiceInventoryViewModel } from 'src/app/core/models/product-model/specialservice-model/specialservice-inventory-view.model';
import { InventoryView } from './views/inventory.view';
import { InventoryMapperService } from './shared/inventory-mapper.service';
import { FocusingDirective } from 'src/app/shared/ui/forms/inputs/focusing.directive';
import { NumberHelperService } from 'src/app/core/utils/number-helper.service';

@Component({
    selector: 'op-special-service-inventory',
    templateUrl: './inventory.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [InventoryMapperService]
})
export class InventoryComponent implements OnChanges {

    private readonly rootLevel = 0;
    private readonly groupLevel = 1;

    @Input() id: string;
    @Input() invGroupControl: boolean = true;
    @Input() fullRow: boolean = true;
    @Output() inventoryResults = new EventEmitter<InventoryView[]>();

    @ViewChild(DxTreeListComponent) treeList: DxTreeListComponent;
    @ViewChild(FocusingDirective) focusingDirective: FocusingDirective;
    public productInventories: SpecialServiceInventoryViewModel[] = new Array();
    public ssrInventoryView: InventoryView[] = new Array();
    public disabledSave: boolean = true;
    public noInvData: boolean = true;
    private copyItem: InventoryView;
    private rootKey: string;
    private requestCapacityRoot = 0;
    private sellCapacityRoot = 0;
    private selectedItem: InventoryView;
    public focusing: boolean = false;
    public suppressZero: any;

    constructor(private changeDetectorRef: ChangeDetectorRef,
        private mapperService: InventoryMapperService,
        private numberHelperService: NumberHelperService,) {
        this.suppressZero = this.numberHelperService.suppressZero.bind(this);
    }

    ngOnChanges(changes: SimpleChanges) {
        this.updateRootData();
    }

    public displayData(productInventories: SpecialServiceInventoryViewModel[], invGroupControl: boolean) {
        this.invGroupControl = invGroupControl;
        this.productInventories = productInventories;
        this.ssrInventoryView = this.mapperService.inventoryToViews(productInventories);
        this.storeRootData();
        this.checkNoInvData();
        this.expandAll();
        this.updateRootData();
        this.disabledSave = true;
        this.changeDetectorRef.detectChanges();
    }

    private expandAll() {
        for (let key of this.productInventories) {
            if (!this.treeList.instance.isRowExpanded(key.productId)) {
                this.treeList.instance.expandRow(key.productId);
            }
        }
    }

    copyClick() {
        if (this.checkEditable(this.selectedItem) && this.selectedItem != null) {
            this.copyItem = cloneDeep(this.selectedItem);
        } else {
            this.copyItem = null
        }
    }

    pasteClick() {
        if (this.copyItem != null && this.checkEditable(this.selectedItem)) {
            this.selectedItem.requestCapacity = this.copyItem.requestCapacity
            this.selectedItem.sellCapacity = this.copyItem.sellCapacity;
        }
    }

    deleteClick() {
        this.selectedItem.requestCapacity = 0;
        this.selectedItem.sellCapacity = 0;
    }

    checkEditable(item: InventoryView) {
        if ((item.productId == this.rootKey || item.parentProductId == this.rootKey) && !this.invGroupControl) {
            return false;
        }
        return true;
    }

    private checkNoInvData() {
        if (this.productInventories?.length) {
            this.noInvData = false;
        }
        else {
            this.noInvData = true;
        }
    }

    public save() {
        this.inventoryResults.emit(this.ssrInventoryView);
    }

    public cancel() {
        this.disabledSave = true;
        this.ssrInventoryView = this.mapperService.inventoryToViews(this.productInventories);
        this.inventoryResults.emit(this.ssrInventoryView);
        this.changeDetectorRef.detectChanges();
    }

    public onEditorPreparing(e) {
        if ((e.dataField == "sellCapacity" || e.dataField == "requestCapacity") && e.parentType === "dataRow") {
            this.disabledSave = false;
            e.editorOptions.min = 0;
        }
    }

    public onRowPrepared(e) {
        if (e.rowType != 'header') {
            if (!this.invGroupControl  && (e.level == this.rootLevel || e.level == this.groupLevel)) {
                e.cells[1].cellElement?.addClass("dx-cell-disabled");
                e.cells[2].cellElement?.addClass("dx-cell-disabled");
            } else {
                e.cells[1].cellElement?.addClass("dx-cell-editable");
                e.cells[2].cellElement?.addClass("dx-cell-editable");
            }
        } 
    }

    public updateRootData() {
        if (this.ssrInventoryView) {
            var ssrInventoryViewClone = cloneDeep(this.ssrInventoryView);
            this.ssrInventoryView = [];
            let filter = ssrInventoryViewClone.filter(x => x.productId == x.productId);
            if (filter?.length) {
                if (this.invGroupControl) {
                    filter[0].requestCapacity = this.requestCapacityRoot;
                    filter[0].sellCapacity = this.sellCapacityRoot;
                } else {
                    filter[0].requestCapacity = null;
                    filter[0].sellCapacity = null;
                }
                this.disabledSave = false;
            }
            this.ssrInventoryView = ssrInventoryViewClone;
        }
    }

    private storeRootData() {
        if (this.productInventories) {
            let filter = this.productInventories.filter(x => x.productId == x.productId);
            if (filter?.length) {
                this.requestCapacityRoot = filter[0].requestCapacity;
                this.sellCapacityRoot = filter[0].sellCapacity;
                this.rootKey = filter[0].productId;
            }
            else {
                this.clearRootData();
            }
        }
    }

    onEditingStart(e) {
        if ((e.key == this.rootKey || e.data.parentProductId  == this.rootKey)  && !this.invGroupControl) {
            e.cancel = true;
        }
    }

    private clearRootData() {
        this.requestCapacityRoot = null;
        this.sellCapacityRoot = null;
        this.rootKey = "";
    }

    public rowClick(e) {
        this.selectedItem = e.data;
    }

    onFocusedCellChanging(e) {
        e.isHighlighted = true;
    }   

}
