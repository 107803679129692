import * as moment from 'moment';

export const select2TextType = {
    placeholder: "",
    allowClear: false,
    minimumResultsForSearch: -1
}

export const validDateFromOption = {
    singleDatePicker: true,
    showDropdowns: true,
    autoApply: true,
    autoUpdateInput: false,
    startDate: moment(),
    endDate: moment(),
    minDate: null,
    maxDate: null,
    locale: {
        cancelLabel: 'Clear',
        format: 'YYYY-MM-DD'
    }
};

export const validDateToOption = {
    singleDatePicker: true,
    showDropdowns: true,
    autoApply: true,
    autoUpdateInput: false,
    startDate: moment(),
    endDate: moment(),
    minDate: null,
    maxDate: null,
    locale: {
        cancelLabel: 'Clear',
        format: 'YYYY-MM-DD'
    }
};

export const select2HashtagOption = {
    placeholder: "<Hashtag>",
    allowClear: true,
    multiple: "multiple",
    theme: "select2-builder-multiple-gray",
    width: 'auto',
    dropdownAutoWidth: true
}