import { ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from "@angular/core";
import { RouteDataService } from "../shared/route-data-service";
import { DocumentDistributionRuleView } from "../shared/document-distribution-rule.view";
import { OrganisationModel } from "src/app/core/models/organisation-model";
import { calendarDateOption, select2Location, select2Provider } from "../shared/select2-configuration";
import { NgForm } from "@angular/forms";
import { cloneDeep } from 'lodash';
import { Select2Data } from "src/app/shared/ui/forms/inputs/oops-select2";
import { FocusingDirective } from "src/app/shared/ui/forms/inputs/focusing.directive";

declare var $: any;

@Component({
    selector: 'op-document-distribution-rule-detail',
    templateUrl: './rule-detail.component.html',
    providers: [RouteDataService]   
})
export class DocumentDistributionRuleDetailComponent implements OnInit, OnChanges {
    public processing: boolean = false;

    public locationOption: any;
    public providerOption: any;
    public serviceDateFromOption: any;
    public serviceDateToOption: any;

    @Input() rule = new DocumentDistributionRuleView();
    @Input() providerReferences: OrganisationModel[];  
    @Output() onUpdateFormStatus = new EventEmitter<boolean>();

    @ViewChild("originLocationSelect2") originLocationSelect2: ElementRef;
    @ViewChild("destinationLocationSelect2") destinationLocationSelect2: ElementRef;
    @ViewChild('ruleDetailForm') ruleDetailForm: NgForm;
    @ViewChild(FocusingDirective) focusingDirective: FocusingDirective;
    
    get calenDarOption() {
        return this.serviceDateToOption;
    }

    constructor(private routeDataService: RouteDataService,
        private changeDetectionRef: ChangeDetectorRef) {
        this.setOptionControl();
    }

    private setOptionControl() {
        this.serviceDateFromOption = cloneDeep(calendarDateOption);
        this.serviceDateToOption = cloneDeep(calendarDateOption);
        this.serviceDateFromOption.minDate = new Date();
        this.serviceDateToOption.minDate = new Date();
        this.providerOption = select2Provider;
        this.locationOption = select2Location;
    }

    ngOnChanges(changes: SimpleChanges): void {
    }

    ngOnInit(): void {
        this.locationOption.ajax = this.routeDataService.select2AjaxSearchByLocationCode();
    }

    public onValueChange(value: any, fieldName: string) {
        this.rule[fieldName] = value;

        if (fieldName == 'providerId') {
            this.rule.providerName = this.providerReferences.find(x => x.organisationId == value).organisationCallName;
        }
    }

    public onProviderChange(value: any) {
        this.rule.providerId = value;
        this.rule.providerName = this.providerReferences.filter(it => it.organisationId == value)[0]?.organisationCallName ?? null;
    }

    public originLocationChange(value: any) {
        var data = $(this.originLocationSelect2.nativeElement).select2('data');
        this.rule.originId = value;
        this.rule.originName = data[0]?.text;
        this.rule.originDataSave = this.getLocationSave(this.rule.originId, this.rule.originName);
    }

    public destinationLocationChange(value: any) {
        var data = $(this.destinationLocationSelect2.nativeElement).select2('data');
        this.rule.destinationId = value;
        this.rule.destinationName = data[0]?.text;
        this.rule.destinationDataSave = this.getLocationSave(this.rule.destinationId, this.rule.destinationName);
    }

    public onServiceDateFromValueChange(value: any) {
        this.serviceDateToOption = cloneDeep(calendarDateOption);
        this.serviceDateToOption.minDate = value ? new Date(value) : new Date();
    }    

    public getDocumentDistributionRule() : DocumentDistributionRuleView {
        if (!this.isValidForm() || this.emptyForm()) {
            return null;
        }
        return this.rule
    }

    public isValidForm() : boolean {
        this.processing = true;
        if(!this.ruleDetailForm.valid && this.ruleDetailForm.disabled != true) {
            this.onUpdateFormStatus.emit(false);
            return false;
        }

        this.processing = false; 
        this.onUpdateFormStatus.emit(true);
        return true;
    }

    setProcessing(flag: boolean) {
        this.processing = flag;
        this.changeDetectionRef.detectChanges();
    }

    public getLocationSave(locationId: string, locationName: string) : Select2Data[] {
        let valueReturen:Select2Data[] = new Array();
        if (locationId) {
            let data = new Select2Data(locationId, locationName);
            valueReturen.push(data);
            return valueReturen;
        }

        return valueReturen;
    }

    private emptyForm() : boolean {
         return (!this.rule.providerId && 
                 !this.rule.serviceDateFrom &&  
                 !this.rule.serviceDateTo && 
                 !this.rule.productNumberFrom &&
                 !this.rule.productNumberTo && 
                 !this.rule.originId &&
                 !this.rule.destinationId);
    }
}