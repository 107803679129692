import { Injectable } from '@angular/core';

import { ApiServiceBase } from '../../base/api-service-base';
import { HttpClient, HttpParams } from '@angular/common/http';
import { AuthService } from '../../authentication/auth.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { 
    OrderCategories,
} from '../../models/order-model';
import { OrderJsonModel } from '../../models/order-model/order-json/order-json.model';
import { ProductPointViewModel } from '../../models/product-model/transport-model';
import { ProductTransportSeatMapCommand } from '../../models/product-model/operating-product-model/product-transport-seat-map-command.model';
import { ProductTransportModel } from '../../models/product-model/operating-product-model';
import { ProductOptionModel } from '../../models/order-model/price-rule-product-option-shopping';
import { OrderSearchCriteria, OrderSearchView } from 'src/app/modules/order/shared';
import { NdcReshop } from '../../models/ndc/ndc-reshop';
import { NdcChange } from '../../models/ndc/ndc-change';

@Injectable({
    providedIn: 'root'
})
export class OrderServices extends ApiServiceBase {

    private readonly PATH: string = "/order"
    private readonly ORDER_PATH = "/order/producttransportseat"
    private readonly NDC_PATH = "/order/ndcorders"
    constructor(public httpClient: HttpClient,
        public authService: AuthService) {
        super(httpClient, authService);
    }

    public searchOrders(criteria: OrderSearchCriteria): Observable<OrderSearchView[]> {
            const queryParams = new HttpParams({ fromObject: <any>criteria })
            return this.httpGet<OrderSearchView[]>(this.PATH + "/orders/search", queryParams)
            .pipe(
                map(response => {
                    return response.body;
                })
            );
    }

    public getOrderCategories(): Observable<OrderCategories> {
        
        return this.httpGet<OrderCategories>(this.PATH + "/orders/categories", null)
            .pipe(
                map(response => {
                    return response.body;
                })
            );
    }
    
    public getOrder(orderId: string): Observable<OrderJsonModel> {
        if (!orderId) {
            return null;
        }
        return this.httpGet<OrderJsonModel>(this.PATH + "/orders/" + orderId, null)
            .pipe(
                map(response => {
                    return response.body;
                })
            );
    }

    public getAllProductPoint(): Observable<ProductPointViewModel[]> {
        return this.httpGet<ProductPointViewModel[]>(this.PATH + "/orders/all-product-point", null)
            .pipe(
                map(res => res.body)
            )
    }

    public getProductTransportSeatMap(command: ProductTransportSeatMapCommand): Observable<ProductTransportModel> {
        return this.httpPost<ProductTransportModel>(this.ORDER_PATH, command)
            .pipe(
                map(res => res.body)
            )
    }

    public getPriceRuleProductOptionShopping(priceRuleId?: string, productId?: string, languageCode?: string): Observable<ProductOptionModel[]> {
        let params = new HttpParams()
            .append('priceRuleId', priceRuleId ?? '')
            .append('productId', productId ?? '')
            .append('languageCode', languageCode ?? '')
        return this.httpGet<ProductOptionModel[]>(`${this.PATH}/priceRuleProductOptionShoppings`, params)
            .pipe(
                map(res => res.body)
            )   
    }

    public reshopOrder(reshop: NdcReshop): Observable<any> {
        return this.httpPost<any>(`${this.NDC_PATH}/reshop`, reshop)
            .pipe(map(res => res.body))
    }

    public changeOrder(change: NdcChange): Observable<any> {
        return this.httpPost<any>(`${this.NDC_PATH}/change`, change)
            .pipe(map(res => res.body))
    }
}