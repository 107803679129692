import { PriceRuleProductView } from ".";

export class PriceRuleAttributeView {
    public priceRuleAttributeId: string;
    public priceRuleId: string;
    public attributeTypeCode: string;
    public attributeChoiceId: string;
    public conditionCode: string = "="
    public attributeValue: string = "";
    public products: PriceRuleProductView[];
}