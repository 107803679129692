import { Injectable } from '@angular/core';

import { Select2Data } from 'src/app/shared/ui/forms/inputs/oops-select2';

import {
    ProductAttributeViewModel
} from 'src/app/core/models/product-model/product-base-model/product-attribute';

import {
    ProductInventoryDimensionCommandModel,
    ProductInventoryDimensionViewModel
} from 'src/app/core/models/product-model/product-base-model/product-inventory-dimension';
import { ProductInventoryAttributeTypeCommandModel, ProductInventoryAttributeTypeViewModel } from 'src/app/core/models/product-model/product-base-model/product-inventory-attribute-type';

@Injectable()
export class InventoryAttributeMapperService {

    private readonly SERVCAT_CODE: string = "SERVICECATEGORY";

    public productInventoryDimensionToModels(valueArray: string[], productInventoryDimensions: ProductInventoryDimensionViewModel[], dimensionTypeCode: string): ProductInventoryDimensionCommandModel[] {
        let models : ProductInventoryDimensionCommandModel[] = new Array();
        if (valueArray) {
            let i = 0;
            for (let value of valueArray) {
                ++i;
                models.push(this.productInventoryDimensionToModel(i, value, dimensionTypeCode, productInventoryDimensions));
            }
        }
        return models;
    }

    public transportInventoryDimensionToModels(valueArray: string[], dimensionTypeCode: string): ProductInventoryDimensionCommandModel[] {
        let models: ProductInventoryDimensionCommandModel[] = new Array();
        if (valueArray) {
            let valueArraySrvCategory = valueArray.filter(x => x == this.SERVCAT_CODE);
            let i = 0;
            for (let value of valueArraySrvCategory) {
                ++i;
                models.push(this.productInventoryDimensionToModel(i, value, dimensionTypeCode, null));
            }
        } 
        return models;
    }

    private productInventoryDimensionToModel(index: number, value: string, dimensionTypeCode: string, productInventoryAttributeTypes: ProductInventoryDimensionViewModel[]): ProductInventoryDimensionCommandModel {
        let model = {} as ProductInventoryDimensionCommandModel;
        model.attributeTypeCode = value;
        model.displaySequence = index;
        model.productInventoryDimensionTypeCode = dimensionTypeCode
        if (productInventoryAttributeTypes?.length) {
            var filter = productInventoryAttributeTypes.filter(x => x.attributeTypeCode == value);
            if (filter?.length) {
                model.productInventoryDimensionId = filter[0].productInventoryDimensionId;
            }
        }
        return model;
    }

    public productInventoryDimensionModelToAttributeOption(productInventoryDimensions: ProductInventoryDimensionViewModel[]): Select2Data[] {
        let views: Select2Data[] = new Array();
        for (let productInventoryAttributeType of productInventoryDimensions) {
            var view = new Select2Data();
            view.id = productInventoryAttributeType.attributeTypeCode;
            view.text = productInventoryAttributeType.attributeTypeName;
            views.push(view);
        }
        return views;
    }

    public productInventoryDimensionModelToAttributeValue(productInventoryAttributeTypes: ProductInventoryDimensionViewModel[]): string[] {
        let values: string[] = new Array();
        for (let productInventoryAttributeType of productInventoryAttributeTypes) {
            values.push(productInventoryAttributeType.attributeTypeCode);
        }
        return values;
    }

    public productInventoryAttributeModelToAttributeTypeOption(productAttributes: ProductAttributeViewModel[]): Select2Data[] {
        var filter = productAttributes.filter(x => x.inheritAttribute == false && x.dimensionFlag == true);
        let views: Select2Data[] = new Array();
        for (let productAttribute of filter) {
            var exist = views.filter(x => x.id == productAttribute.attributeTypeCode);
            if (exist.length == 0) {
                var view = new Select2Data();
                view.id = productAttribute.attributeTypeCode;
                view.text = productAttribute.attributeTypeName;
                views.push(view);
            }
        }
        return views;
    }

    public transportInventoryAttributeTypeToModels(valueArray: string[]): ProductInventoryAttributeTypeCommandModel[] {
        let models: ProductInventoryAttributeTypeCommandModel[] = new Array();
        if (valueArray) {
            let valueArraySrvCategory = valueArray.filter(x => x == this.SERVCAT_CODE);
            let i = 0;
            for (let value of valueArraySrvCategory) {
                ++i;
                models.push(this.productInventoryAttributeTypeToModel(i, value, null));
            }
        }
        return models;
    }

    private productInventoryAttributeTypeToModel(index: number, value: string, productInventoryAttributeTypes: ProductInventoryAttributeTypeViewModel[]): ProductInventoryAttributeTypeCommandModel {
        let model = new ProductInventoryAttributeTypeCommandModel();
        model.productAttributeTypeCode = value;
        model.displaySequence = index;
        if (productInventoryAttributeTypes?.length) {
            var filter = productInventoryAttributeTypes.filter(x => x.productAttributeTypeCode == value);
            if (filter?.length) {
                model.productInventoryAttributeTypeId = filter[0].productInventoryAttributeTypeId;
            }
        }
        return model;
    }

    public productInventoryAttributeTypeModelToAttributeOption(productInventoryAttributeTypes: ProductInventoryAttributeTypeViewModel[]): Select2Data[] {
        let views: Select2Data[] = new Array();
        for (let productInventoryAttributeType of productInventoryAttributeTypes) {
            var view = new Select2Data();
            view.id = productInventoryAttributeType.productAttributeTypeCode;
            view.text = productInventoryAttributeType.productAttributeTypeName;
            views.push(view);
        }
        return views;
    }

    public productInventoryAttributeTypeModelToAttributeValue(productInventoryAttributeTypes: ProductInventoryAttributeTypeViewModel[]): string[] {
        let values: string[] = new Array();
        for (let productInventoryAttributeType of productInventoryAttributeTypes) {
            values.push(productInventoryAttributeType.productAttributeTypeCode);
        }
        return values;
    }
}
